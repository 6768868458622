/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
/*eslint-disable no-unused-vars*/
import d3 from 'd3';
/*eslint-enable no-unused-vars*/
import GraphTransformStore from '../stores/GraphTransformStore';
import MapPageStore from '../stores/MapPageStore';

export default {
  duration: 750, //remove the transition duration for zoom to fit
  linkWidth: 4,
  arrowSize: 16,
  opacity: 0.8,
  nodepolicySize: (30 + 14) / Math.sqrt(2), //picked up from Node.js
  nodepolicyCorner: 4,
  rolepolicySize: (42 + 14) / Math.sqrt(2), //picked up from Role.js.
  rolepolicyCorner: 7,
  hoverPadding: 4,
  defaultDistance: 200,
  showTokenSize: 40,
  overviewSize: 8,
  grayDotScale: 0.08, //to match semantic zoom mechanisms of cluster.

  enterLink(selection) {
    const data = selection.datum();
    const scale = GraphTransformStore.getTransform().scale;
    const realDistance = this.defaultDistance * scale;
    const sourceOverviewScale = data.source.displayType !== 'full' && !data.source.isLegend;
    const targetOverviewScale = data.target.displayType !== 'full' && !data.target.isLegend;
    const isSourceOverviewScale = sourceOverviewScale && realDistance < this.showTokenSize;
    const isTargetOverviewScale = targetOverviewScale && realDistance < this.showTokenSize;

    selection
      .attr('transform', d => {
        let x1 = this.transform(d)[0];
        let y1 = this.transform(d)[1];

        x1 = isNaN(x1) ? 0 : x1.toFixed(2);
        y1 = isNaN(y1) ? 0 : y1.toFixed(2);

        return `translate(${x1} ${y1})`;
      })
      .attr('d', d => {
        if ((d.source.type === 'group' && d.target.type === 'group') || d.linkType === 'appSupergroupLink') {
          return this.line(d, isSourceOverviewScale, isTargetOverviewScale).link;
        }

        return this.rotate(d).link;
      })
      .each(function (d) {
        d.totalLength = this.getTotalLength();
      })
      .attr('stroke-dasharray', d => `${d.totalLength} ${d.totalLength}`)
      .attr('stroke-dashoffset', d => d.totalLength)
      .attr('stroke-linecap', 'round')
      .attr('stroke-linejoin', 'round')
      .attr(
        'stroke-width',
        d => (d.isLegend ? this.linkWidth : this.linkWidth / Math.pow(GraphTransformStore.getTransform().scale)),
        0.7,
      );
  },

  updateLink(truncated, preventGraphElementAnimation, selection) {
    const data = selection.datum();
    const scale = GraphTransformStore.getTransform().scale;
    const realDistance = this.defaultDistance * scale;
    const sourceOverviewScale = data.source.displayType !== 'full' && !data.source.isLegend;
    const targetOverviewScale = data.target.displayType !== 'full' && !data.target.isLegend;
    const isSourceOverviewScale = sourceOverviewScale && realDistance < this.showTokenSize;
    const isTargetOverviewScale = targetOverviewScale && realDistance < this.showTokenSize;

    selection.attr('stroke-width', d => {
      const lineWidth = d.source.type !== 'group' && d.target.type !== 'group' ? this.linkWidth : 3.75;

      return d.isLegend ? lineWidth : lineWidth / Math.pow(GraphTransformStore.getTransform().scale, 0.7);
    });

    this.selectAndHoverLink(selection, truncated);
    this.positionLink(selection, preventGraphElementAnimation, isSourceOverviewScale, isTargetOverviewScale);
  },

  line(d, isSourceOverviewScale, isTargetOverviewScale) {
    // In group level, the line's start and end position need do semantic scaling
    // In the workload level, the straight line do not have semantic scaling.

    let scale = GraphTransformStore.getTransform().scale;
    const mapLevel = MapPageStore.getMapLevel();

    scale = mapLevel === 'group' ? scale : 1;
    // At workload level, scale is 1.

    const [x1, y1, x2, y2] = this.transform(d, scale);

    const dx = x2 - x1;
    const dy = y2 - y1;

    const dist = Math.sqrt(dx * dx + dy * dy);
    let angle = Math.atan(dy / dx) * (180 / Math.PI);
    const linkAngle = Math.atan(dx / dy);

    const targetHeight = d.target.height;
    const targetWidth = d.target.width - targetHeight;
    const targetDiagonalAngle = Math.atan(targetWidth / targetHeight);

    const sourceHeight = d.source.height;
    const sourceWidth = d.source.width - sourceHeight;
    const sourceDiagonalAngle = Math.atan(sourceWidth / sourceHeight);

    let targetDistOffset = scale < this.grayDotScale ? this.overviewSize / this.grayDotScale : this.overviewSize;
    let sourceDistOffset = scale < this.grayDotScale ? this.overviewSize / this.grayDotScale : this.overviewSize;

    if (x1 > x2) {
      angle = 180 + angle;
    }

    const rTarget = d.target.height / scale / 2;
    const wTarget = d.target.width / scale / 2;
    const rSource = d.source.height / scale / 2;
    const wSource = d.source.width / scale / 2;

    // Target Offset Calculation
    if (
      !isTargetOverviewScale &&
      (d.target.displayType === 'full' || d.target.displayType === 'summary' || d.target.displayType === 'token')
    ) {
      // Target Offset Calculation
      if (Math.abs(linkAngle * (180 / Math.PI)) === 90) {
        targetDistOffset = wTarget;
      } else if (linkAngle < -targetDiagonalAngle || linkAngle > targetDiagonalAngle) {
        // These are for the curved cluster sides. Use The Law of Sines to derive this.
        const absLinkAngle = Math.abs(linkAngle);
        const phi = Math.asin(((wTarget - rTarget) * Math.cos(absLinkAngle)) / rTarget);

        targetDistOffset = (rTarget * Math.cos(phi - absLinkAngle)) / Math.cos(absLinkAngle);
      } else {
        // These are for the flat top and bottom surfaces.
        targetDistOffset = Math.abs(rTarget / Math.cos(linkAngle));
      }
    }

    // Source Offset Calculation
    if (
      !isSourceOverviewScale &&
      (d.source.displayType === 'full' || d.source.displayType === 'summary' || d.source.displayType === 'token')
    ) {
      // Source Offset Calculation
      if (Math.abs(linkAngle * (180 / Math.PI)) === 90) {
        sourceDistOffset = wSource;
      } else if (linkAngle < -sourceDiagonalAngle || linkAngle > sourceDiagonalAngle) {
        // These are for the curved cluster sides. Use The Law of Sines to derive this.
        const absLinkAngle = Math.abs(linkAngle);
        const phi = Math.asin(((wSource - rSource) * Math.cos(absLinkAngle)) / rSource);

        sourceDistOffset = (rSource * Math.cos(phi - absLinkAngle)) / Math.cos(absLinkAngle);
      } else {
        // These are for the flat top and bottom surfaces.
        sourceDistOffset = Math.abs(rSource / Math.cos(linkAngle));
      }
    }

    if (d.linkType === 'appSupergroupLink' && d.target.type === 'location') {
      targetDistOffset = d.target.r + 6 / scale; // 6 is to push the link a little behind to avoid overlap with stroke.
    }

    const lineArrowPoints = this.getPathCoordinates(x1, x2, angle, dist, sourceDistOffset, targetDistOffset, d);

    return {
      link: `M ${lineArrowPoints.start[0]} ${lineArrowPoints.start[1]} 
             L ${lineArrowPoints.end[0]} ${lineArrowPoints.end[1]}
             M ${lineArrowPoints.arrowTip[0]} ${lineArrowPoints.arrowTip[1]} 
             L ${lineArrowPoints.arrowLeft[0]} ${lineArrowPoints.arrowLeft[1]} 
             L ${lineArrowPoints.arrowRight[0]} ${lineArrowPoints.arrowRight[1]} 
             Z`,
    };
  },

  getPathCoordinates(x1, x2, angle, dist, sourceDistOffset, targetdistOffset, d) {
    let startX;
    let startY;
    let endY;
    let endX;
    // Coordinates of arrow head.
    let arrowLeft;
    let arrowRight;
    let arrowTip;

    const scale = Math.pow(GraphTransformStore.getTransform().scale, 0.7);

    const scaling16 = 16 / scale;
    const scaling6 = 6 / scale;

    if (d.linkType === 'appSupergroupLink') {
      startX = 0;
      startY = 0;
      endY = 0;
      endX = dist - targetdistOffset;
      arrowLeft = this.project(endX - scaling16, endY - scaling6, 0, 0, angle);
      arrowRight = this.project(endX - scaling16, endY + scaling6, 0, 0, angle);
      arrowTip = this.project(endX, endY, 0, 0, angle);
    } else {
      const theta = Math.atan(5 / dist);

      startX = sourceDistOffset;
      startY = -7 / scale;
      endY = 0;
      endX = (dist - targetdistOffset) / Math.cos(theta) - 6 / scale;
      arrowLeft = this.project(endX - scaling16, endY - scaling6, 0, 0, angle);
      arrowRight = this.project(endX - scaling16, endY + scaling6, 0, 0, angle);
      arrowTip = this.project(endX, endY, 0, 0, angle);
    }

    // the ending point for the group link.
    const end = this.project(endX - scaling16, endY, 0, 0, angle);
    const start = this.project(startX, startY, 0, 0, angle);

    return {
      start,
      endX,
      endY,
      arrowLeft,
      arrowRight,
      arrowTip,
      end,
    };
  },

  project(x, y, xm, ym, a) {
    const cos = Math.cos;
    const sin = Math.sin;

    a = (a * Math.PI) / 180;

    const xr = (x - xm) * cos(a) - (y - ym) * sin(a) + xm;
    const yr = (x - xm) * sin(a) + (y - ym) * cos(a) + ym;

    return [xr, yr];
  },

  rotate(d) {
    const scale = Math.pow(GraphTransformStore.getTransform().scale, 0.7);
    const targetWorkloadsIsReadable = d.target.caps && d.target.caps.workloads.includes('read');
    const sourceWorkloadsIsReadable = d.source.caps && d.source.caps.workloads.includes('read');

    // If policy state is enforced or testing, the source and target size must be the size of the outer policyrect.
    const targetPolicyState =
      targetWorkloadsIsReadable && (d.target.policyState === 'enforced' || d.target.policyState === 'selective');
    const sourcePolicyState =
      sourceWorkloadsIsReadable && (d.source.policyState === 'enforced' || d.source.policyState === 'selective');
    const internetOrIpSourceSize = d.source.type === 'internet' || d.source.type === 'fqdn' ? 25 : 30;
    const internetOrIpTargetSize = d.target.type === 'internet' || d.target.type === 'fqdn' ? 25 : 30;
    let sourceSize = sourcePolicyState
      ? d.source.type === 'role'
        ? this.rolepolicySize
        : this.nodepolicySize
      : (d.source && d.source.size) || internetOrIpSourceSize;
    const targetSize = targetPolicyState
      ? d.target.type === 'role'
        ? this.rolepolicySize
        : this.nodepolicySize
      : d.target.type === 'role'
      ? ((d.target && d.target.size) || internetOrIpTargetSize) / Math.sqrt(2)
      : (d.target && d.target.size) || internetOrIpTargetSize;

    // If source is the container let the link start from the origin(top point of heaxgon).
    if (d.source.subType === 'container') {
      sourceSize = 0;
    }

    // Padding outside Source and Target To Draw Links.
    const sourcePadding = 0;
    const targetPadding = 4 / scale;
    const selfArrowTip = sourceSize / 2 + sourcePadding;
    const scalingA = 12 / (1.2 * scale);
    const scalingB = 6 / (1.2 * scale);
    const curvedA = sourceSize + 50;
    const curvedB = sourceSize + 30;

    // Role Links that point back to itself
    if (d.source.href === d.target.href && !d.isLegend) {
      return {
        link: `M${selfArrowTip},${0}C${curvedA},${curvedB} ${-curvedA},${curvedB} ${-selfArrowTip},${0}L${-selfArrowTip},${0}C${-curvedA},${curvedB} ${curvedA},${curvedB} ${selfArrowTip},${0}M${-selfArrowTip},${0}L${
          -selfArrowTip - scalingB
        },${scalingA}L${-selfArrowTip - scalingA},${scalingB}Z`,
      };
    }

    const [x1, y1, x2, y2] = this.transform(d);

    // Distance and Angle Calculation Between Source and Target.
    const dx = x2 - x1;
    const dy = y2 - y1;
    const dist = Math.sqrt(dx * dx + dy * dy);
    let angle = Math.atan(dy / dx) * (180 / Math.PI);

    if (x1 > x2) {
      angle = 180 + angle;
    }

    // Start and End Positions of the Links
    let startX;
    let startY;
    const endX = dist - targetSize / 2 - targetPadding;
    const endY = -targetSize / 2 - targetPadding;

    if (y1 <= y2) {
      if (x1 < x2) {
        // Quadrant 1
        startX = sourceSize / 2 + sourcePadding;
        startY = 0;
      } else {
        // Quadrant 2
        if (d.source.type === 'ipList') {
          sourceSize = 20;
        }

        startX = 0;
        startY = sourceSize / 2 + sourcePadding;
      }
    } else if (y2 < y1) {
      if (x2 < x1) {
        // Quadrant 3
        startX = -sourceSize / 2 - sourcePadding;
        startY = 0;
      } else {
        // Quadrant 4
        startX = 0;
        startY = -sourceSize / 2 - sourcePadding;
      }
    }

    // the curve height follows sqrt(n), in which n = length of start-end segment
    const c1x = endX / 4;
    const cy = endX - startX < 400 ? -Math.sqrt(Math.abs(endX - startX)) * 4 : (startX - endX) * 0.2;
    const c2x = Math.max(endX - c1x);

    const firstCurve = this.project(c1x, cy, 0, 0, angle);
    const secondCurve = this.project(c2x, cy, 0, 0, angle);

    const scaling8 = 8 / (1.2 * scale);
    const scaling6 = 6 / (1.2 * scale);
    const scaling4 = 4 / (1.2 * scale);
    const scaling3 = 3 / (1.2 * scale);
    const scaling1 = 1 / (1.2 * scale);

    // have the bezier curve end a bit early to accommodate arrowhead
    const start = [startX, startY];
    const end = this.project(endX - scaling6, endY - scaling3, 0, 0, angle);
    const arrowRight = this.project(endX - scaling8, endY + scaling1, 0, 0, angle);
    const arrowLeft = this.project(endX - scaling4, endY - scaling6, 0, 0, angle);
    const arrowTip = this.project(endX + scaling4, endY + scaling4, 0, 0, angle);

    if (d && d.method === 'legend') {
      start[0] = 30;
      start[1] = -7;
      end[0] = 71;
      end[1] = -10;
      firstCurve[0] = 34;
      firstCurve[1] = -23;
      secondCurve[0] = 67;
      secondCurve[1] = -23;

      //M 78 -6 L 73 -16 L 68 -4 Z
      arrowTip[0] = 78;
      arrowTip[1] = -6;
      arrowLeft[0] = 75;
      arrowLeft[1] = -16;
      arrowRight[0] = 68;
      arrowRight[1] = -6;
    }

    //if (start[0])
    // Generates the path of the curve
    return {
      link: `M ${start[0]} ${start[1]} 
             C ${firstCurve[0]} ${firstCurve[1]} 
             ${secondCurve[0]} ${secondCurve[1]} 
             ${end[0]} ${end[1]} 
             L ${end[0]} ${end[1]}
             C ${secondCurve[0]} ${secondCurve[1]} 
             ${firstCurve[0]} ${firstCurve[1]}
             ${start[0]} ${start[1]} 
             M ${arrowTip[0]} ${arrowTip[1]} 
             L ${arrowLeft[0]} ${arrowLeft[1]} 
             L ${arrowRight[0]} ${arrowRight[1]} Z`,
    };
  },

  transform(d, scale) {
    let x1 = d.source.x;
    let y1 = d.source.y;
    let x2 = d.target.x;
    let y2 = d.target.y;

    if ((d.source.cluster || d.target.cluster) && d.source.cluster !== d.target.cluster) {
      // If interapp link, then take into account cluster's positions
      scale ||= 1;

      x1 += d.source.cluster ? d.source.cluster.x / scale : 0;
      y1 += d.source.cluster ? d.source.cluster.y / scale : 0;
      x2 += d.target.cluster ? d.target.cluster.x / scale : 0;
      y2 += d.target.cluster ? d.target.cluster.y / scale : 0;
    } else if (d.source.node) {
      // This is an internet link for an unconnected node
      x2 = 0;
      y2 = 0;
    } else if (d.target.node) {
      x1 = 0;
      y1 = 0;
    }

    return [x1, y1, x2, y2];
  },

  positionLink(selection, preventGraphElementAnimation, isSourceOverviewScale, isTargetOverviewScale) {
    selection
      .transition()
      .duration(d => (d.drag || preventGraphElementAnimation ? 0 : this.duration))
      .attr('transform', d => {
        let x1 = this.transform(d)[0];
        let y1 = this.transform(d)[1];

        x1 = isNaN(x1) ? 0 : x1.toFixed(2);
        y1 = isNaN(y1) ? 0 : y1.toFixed(2);

        return `translate(${x1} ${y1})`;
      })
      .attr('d', d => {
        if ((d.source.type === 'group' && d.target.type === 'group') || d.linkType === 'appSupergroupLink') {
          return this.line(d, isSourceOverviewScale, isTargetOverviewScale).link;
        }

        return this.rotate(d, 'link').link;
      })
      .attr('stroke-dashoffset', 0)
      .each('end', () => {
        selection.attr('stroke-dasharray', d => {
          if (d.type === 'discovered' || d.exposureTraffic) {
            const scaledDasharray = `${1 / GraphTransformStore.getTransform().scale}, ${
              (1 / GraphTransformStore.getTransform().scale) * 7
            }`;

            return d.isLegend ? '1, 7' : scaledDasharray;
          }

          return 'none';
        });
      });
  },

  selectAndHoverLink(selection, truncated) {
    const mapLevel = MapPageStore.getMapLevel();

    selection.style('opacity', d =>
      this.getOpacity(d.selected, truncated, d.hovered, mapLevel, d.source.type, d.target.type),
    );
  },

  highlightLink(selection) {
    selection.style('opacity', 1);
  },

  getOpacity(selected, truncated, hovered, mapLevel, sourceType, targetType) {
    if (truncated) {
      return 0;
    }

    if (hovered === 'unhovered') {
      return 0.2;
    }

    if (hovered === 'hovered') {
      return 1;
    }

    if (selected) {
      return 1;
    }

    if (mapLevel === 'workload' && sourceType === 'group' && targetType === 'group') {
      return 0.4;
    }

    return this.opacity;
  },
};
