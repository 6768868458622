/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import Constants from '../constants';
import {createApiStore} from '../lib/store';

const settings = {};
const isLoaded = {};

const labelKeys = ['role', 'app', 'env', 'loc'];
const allStrings = {
  role: intl('Common.AllRoles'),
  app: intl('Common.AllApplications'),
  loc: intl('Common.AllLocations'),
  env: intl('Common.AllEnvironments'),
};

// Add missing "All *" Labels to scopes and Sort them in RAEL order
function formatAndSortScopes(data) {
  data.static_policy_scopes = data.static_policy_scopes.map(scope =>
    scope
      .reduce(
        (result, item, idx) => {
          const isLast = idx === scope.length - 1;

          result.items.push(item);
          result.keys.push((item.label || item.label_group).key);

          // If last item, add missing 'All' labels for visual representation
          if (isLast) {
            labelKeys.forEach(key => {
              if (!result.keys.includes(key)) {
                result.items.push({
                  label: {
                    value: allStrings[key],
                    type: 'all',
                    key,
                  },
                });
              }
            });
          }

          return result;
        },
        {keys: [], items: []},
      )
      .items.sort(
        (a, b) =>
          // Sort labels and label groups by RAEL
          // keys earlier in the labelKeys array, should appear earlier
          labelKeys.indexOf((a.label || a.label_group).key) - labelKeys.indexOf((b.label || b.label_group).key),
      ),
  );

  return data;
}

function formatFirewallCoexistence(data) {
  if (!data.firewall_coexistence || data.firewall_coexistence.isFormatted) {
    return data;
  }

  data.firewall_coexistence = data.firewall_coexistence.map(scope => {
    const processed = scope.scope
      ? scope.scope.reduce(
          (result, item) => {
            result.items.push({label: item});
            result.keys.push(item.key);

            return result;
          },
          {keys: [], items: []},
        )
      : {keys: [], items: []};

    labelKeys.forEach(key => {
      if (!processed.keys.includes(key)) {
        processed.items.push({
          label: {
            value: allStrings[key],
            type: 'all',
            key,
          },
        });
      }
    });

    processed.items.sort(
      (a, b) =>
        // Sort labels and label groups by RAEL
        // keys earlier in the labelKeys array, should appear earlier
        labelKeys.indexOf((a.label || a.label_group).key) - labelKeys.indexOf((b.label || b.label_group).key),
    );

    processed.items.policyState = scope.workload_mode;
    processed.items.primaryFirewall = scope.illumio_primary;

    return processed.items;
  });

  data.firewall_coexistence.isFormatted = true;

  return data;
}

function setSettings(data, version) {
  settings[version] = formatAndSortScopes(data);
  formatFirewallCoexistence(data);
}

export default createApiStore(['FIREWALL_SETTINGS_'], {
  dispatchHandler(action) {
    let version = 'draft';

    if (action.options && action.options.params) {
      version = action.options.params.pversion || 'draft';
    }

    switch (action.type) {
      case Constants.FIREWALL_SETTINGS_GET_SUCCESS:
        setSettings(action.data, version);
        isLoaded[version] = true;
        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getSpecified(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return settings[version] || [];
  },

  isLoaded(version) {
    if (_.isUndefined(version)) {
      version = 'draft';
    }

    return isLoaded[version] || false;
  },

  hasValidSettings() {
    return isLoaded.draft ? settings.draft.network_detection_mode !== null : false;
  },
});
