/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import Icon from './Icon.jsx';
import React, {PropTypes} from 'react';
import ComponentUtils from '../utils/ComponentUtils';

const defaultTid = 'comp-button';
const stylesByText = () => ({
  [intl('Common.Back')]: {icon: 'back'},
  [intl('Common.Prev')]: {icon: 'back'},
  [intl('Common.Next')]: {icon: 'next', iconAfter: true},
  [intl('Common.Remove')]: {icon: 'remove'},
  [intl('Common.Delete')]: {icon: 'remove'},
  [intl('Common.Save')]: {icon: 'save'},
  [intl('Common.Edit')]: {icon: 'edit'},
  [intl('Common.Add')]: {icon: 'add'},
  [intl('Common.Close')]: {icon: 'close'},
  [intl('Common.Illuminate')]: {icon: 'illuminate'},
  [intl('Common.Enforce')]: {icon: 'enforce'},
  [intl('Common.EnforcementStop')]: {icon: 'cancel'},
  [intl('PairingProfiles.Pair')]: {icon: 'add'},
  [intl('Common.Unpair')]: {icon: 'remove'},
  [intl('Common.AssignRole')]: {icon: 'role'},
  [intl('Common.AssignRoles')]: {icon: 'role'},
  [intl('Common.EntityState')]: {icon: 'enforcement'},
  [intl('Role.Set')]: {icon: 'role'},
  [intl('Workloads.SetEnforcement')]: {icon: 'enforcement'},
  [intl('Common.Filter')]: {icon: 'filter'},
  [intl('Common.Refresh')]: {icon: 'refresh'},
  [intl('Common.ConfigurationWizard')]: {icon: 'settings'},
  [intl('Common.Provision')]: {icon: 'provision'},
  [intl('Provision.All')]: {icon: 'provision'},
  [intl('Provision.Now')]: {icon: 'provision'},
  [intl('Provision.Confirm')]: {icon: 'confirm'},
  [intl('Provision.Revert.All')]: {icon: 'revert'},
  [intl('Provision.Revert.Now')]: {icon: 'revert'},
  [intl('Common.Revert')]: {icon: 'revert'},
  [intl('Common.Confirm')]: {icon: 'confirm'},
  [intl('Common.OK')]: {icon: 'confirm'},
  [intl('Workloads.Clear')]: {icon: 'confirm'},
  [intl('Common.Finish')]: {icon: 'confirm'},
  [intl('Common.Change')]: {icon: 'confirm'},
  [intl('Common.ConfigurationWizard')]: {icon: 'settings'},
  [intl('Users.Invite.Reinvite')]: {icon: 'email'},
  [intl('Common.Invite')]: {icon: 'email'},
  [intl('Common.Export')]: {icon: 'export'},
  [intl('Common.GenerateKey')]: {icon: 'key'},
  [intl('PairingProfiles.Start')]: {icon: 'play'},
  [intl('PairingProfiles.Resume')]: {icon: 'play'},
  [intl('PairingProfiles.Stop')]: {icon: 'pause'},
  [intl('PairingProfiles.Pause')]: {icon: 'pause'},
  [intl('Common.Download')]: {icon: 'download'},
  [intl('Common.Unmanage')]: {icon: 'remove'},
});

export default React.createClass({
  propTypes: {
    counter: PropTypes.number,
    customClass: PropTypes.string,
    disabled: PropTypes.bool,
    dual: PropTypes.bool,
    autoFocus: PropTypes.bool,
    icon: PropTypes.string,
    iconAfter: PropTypes.string,
    iconTitle: PropTypes.string,
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    text: PropTypes.any,
    size: PropTypes.oneOf(['small', 'medium', 'large', 'huge']),
    type: PropTypes.oneOf(['primary', 'secondary', 'reversed', 'strong', 'nofill', 'linky']),
    content: PropTypes.oneOf(['icon-only', 'text-only', 'both']),
    tid: PropTypes.string,
    tabIndex: PropTypes.string,
  },

  getDefaultProps() {
    return {
      counter: null,
      tabIndex: '0',
      autoFocus: false,
      disabled: false,
      onClick: _.noop,
      onMouseDown: _.noop,
      onMouseUp: _.noop,
      onMouseEnter: _.noop,
      onMouseLeave: _.noop,
      onBlur: _.noop,
      icon: null,
      iconAfter: null,
      text: '',
      size: 'medium',
      type: 'primary',
      content: 'both',
      onFocus: _.noop,
    };
  },

  render() {
    const {text, content, size, type, disabled, iconTitle, counter} = this.props;
    let {icon, iconAfter} = this.props;

    const style = stylesByText()[text];

    if (style) {
      if (style.iconAfter) {
        iconAfter ||= style.icon;
      } else {
        icon ||= style.icon;
      }
    }

    const classesObj = {
      'Button--icon-only': content === 'icon-only',
      'Button--disabled': disabled,
      'Button--small': size === 'small',
      'Button--large': size === 'large',
      'Button--huge': size === 'huge',
      'Button--primary': type === 'primary',
      'Button--secondary': type === 'secondary',
      'Button--nofill': type === 'nofill',
      'Button--reversed': type === 'reversed',
      'Button--strong': type === 'strong',
      'Button--linky': type === 'linky',
    };

    if (this.props.customClass) {
      classesObj[this.props.customClass] = true;
    }

    const classes = cx('Button', classesObj);
    const tids = ComponentUtils.tid(defaultTid, this.props.tid);
    const buttonProps = {
      'type': 'button',
      'tabIndex': this.props.tabIndex,
      'autoFocus': this.props.autoFocus,
      'ref': 'button',
      'onClick': this.props.onClick,
      'onFocus': this.props.onFocus,
      'onBlur': this.props.onBlur,
      'className': classes,
      disabled,
      'onMouseDown': this.props.onMouseDown,
      'onMouseUp': this.props.onMouseUp,
      'onMouseEnter': this.props.onMouseEnter,
      'onMouseLeave': this.props.onMouseLeave,
      'data-tid': ComponentUtils.tidString(tids),
      'title': this.props.title,
    };
    const buttonChildren = this.props.children || [
      icon && content !== 'text-only' ? (
        <Icon
          styleClass="button-dual-border"
          key="before-icon"
          name={icon}
          helpText={iconTitle}
          position={content === 'icon-only' ? null : 'before'}
          size={size}
        />
      ) : null,
      content === 'icon-only' ? null : <span className="textcontent">{text}</span>,
      iconAfter && content !== 'text-only' ? (
        <Icon key="after-icon" name={iconAfter} position="after" size={size} />
      ) : null,
    ];

    if (counter) {
      buttonChildren.push(<div className="Button-counter">{counter}</div>);
    }

    let elementType = 'button';

    if (this.props.dual) {
      elementType = 'div';
      delete buttonProps.type;
    }

    if (disabled) {
      buttonProps['aria-disabled'] = true;
    }

    return React.createElement(elementType, buttonProps, buttonChildren);
  },
});
