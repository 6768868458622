/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {RestApiUtils} from '../utils';

export default React.createClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    allowPartial: PropTypes.bool,
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
      containers: [],
    };
  },

  componentDidMount() {
    _.defer(() => {
      RestApiUtils.containerWorkloads.getCollection().then(response => {
        this.setState({
          dropdownValues: {
            'container-': {
              matches: response.body,
              num_matches: response.body.length,
            },
          },
          containers: response.body,
        });
      });

      this.getFacetValues();
    });
  },

  getFacetValues(facet, query = '') {
    if (this.state.dropdownValues[`container-${query}`]) {
      return;
    }

    const dropdownValues = _.cloneDeep(this.state.dropdownValues);
    let matchedContainers = this.state.containers;

    if (query) {
      matchedContainers = matchedContainers.filter(container =>
        container.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()),
      );
    }

    dropdownValues[`container-${query}`] = {
      matches: matchedContainers,
      num_matches: matchedContainers.length,
    };
    this.setState({dropdownValues});
  },

  addItem(item, value) {
    const items = _.cloneDeep(this.state.items);

    items[item] = value;
    this.props.onChange(value);
    this.setState({items});
  },

  removeItem(item) {
    const items = _.cloneDeep(this.state.items);

    this.props.onChange(undefined, items[item]);
    delete items[item];
    this.setState({items});
  },

  removeMulti(item, singleItem) {
    const items = _.cloneDeep(this.state.items);

    if (singleItem) {
      this.props.onChange(undefined, singleItem);

      const deletedIndex = items[item].findIndex(item => singleItem.href === item.href);

      items[item].splice(deletedIndex, 1);
    } else if (items[item]) {
      this.props.onChange(undefined, items[item].pop());
    }

    if (items[item] && !items[item].length) {
      delete items[item];
    }

    this.setState({items});
  },

  returnValue(value) {
    if (typeof value === 'string') {
      return value;
    }

    return value.name;
  },

  render() {
    let placeholder = this.props.placeholder || 'Select Containers';

    if (this.state.items.Container) {
      placeholder = '';
    }

    const {addItem, getFacetValues, removeItem, removeMulti, returnValue} = this;
    const props = {
      dropdownValues: this.state.dropdownValues,
      items: this.state.items,
      facetMap: {
        [intl('Common.Container')]: 'container',
      },
      initialValues: [intl('Common.Container')],
      onChange: _.noop,
      singleSelect: intl('Common.Container'),
      addItem,
      getFacetValues,
      placeholder,
      removeItem,
      removeMulti,
      returnValue,
    };

    if (this.props.allowPartial) {
      props.partialItems = [intl('Common.Container')];
      props.partialOnZeroMatches = true;
    }

    props.allowOne = true;

    return <ObjectSelector {...props} />;
  },
});
