/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';

export default {
  size: 38, // internet or ipList font size
  duration: 750, //remove the transition duration for zoom to fit

  enter(selection) {
    this.position(selection, 0);
    this.addTitle(selection);
  },

  update(selection) {
    selection.call(this.selectInternet);
    selection.call(this.hoverInternet);

    const datum = selection.datum();

    if (datum.type === 'ipList') {
      selection
        .select('.Icon-bg')
        .attr('width', this.size - 8)
        .attr('height', this.size - 8)
        .attr('x', -(this.size - 8) / 2)
        .attr('y', -(this.size - 8) / 2)
        .attr('fill', '#fff');
    } else if (datum.type === 'internet') {
      selection
        .select('.Icon-bg')
        .attr('font-family', 'illumio-icons')
        .attr('text-anchor', 'middle')
        .attr('dy', '.46em')
        .style('font-size', `${parseInt(this.size + 4, 10)}px`)
        .attr('fill', '#fff')
        .text('\uE620');
    } else if (datum.type === 'fqdn') {
      selection
        .select('.Icon-bg')
        .attr('font-family', 'illumio-icons')
        .attr('text-anchor', 'middle')
        .attr('dy', '.46em')
        .style('font-size', `${parseInt(this.size + 4, 10)}px`)
        .attr('fill', '#fff')
        .text('\uE624');
    }

    selection
      .select('.Icon-text')
      .attr('font-family', 'illumio-icons')
      .attr('text-anchor', 'middle')
      .attr('dy', '.46em')
      .attr('fill', '#3b4881') // @blue
      .style('font-size', `${parseInt(this.size, 10)}px`)
      .text(d => {
        if (d.type === 'internet') {
          return '\uE620';
        }

        if (d.type === 'ipList') {
          return '\uE622';
        }

        if (d.type === 'fqdn') {
          return '\uE624';
        }
      });

    this.position(selection, this.duration);
    this.addTitle(selection);
  },

  position(selection, duration) {
    if (duration) {
      selection = selection.transition().duration(duration);
    }

    selection.attr('transform', d => {
      const x = d.x;
      const y = d.y;

      return `translate(${x},${y})`;
    });
  },

  addTitle(selection) {
    selection
      .select('.il-internet-title')
      .text(d => (d.type === 'ipList' ? intl('Common.IPLists') : _.capitalize(d.type)));
  },

  selectInternet(selection) {
    selection.select('.Icon-bg').attr('filter', d => (d.selected ? 'url(#selectFilter)' : 'none'));
  },

  hoverInternet(selection) {
    selection.select('.Icon-bg').attr('filter', d => {
      if (d.selected || d.selfHovered) {
        return 'url(#selectFilter)';
      }

      return 'none';
    });

    selection.select('.Icon-text').style('fill-opacity', d => (d.hovered === 'unhovered' ? 0.2 : 1));
  },
};
