/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import {createApiStore} from '../lib/store';
import Constants from '../constants';
import ServiceUtils from '../utils/ServiceUtils';
import intl from 'intl';

let dropdownValues = {};
let isLoaded = false;

function setDropdownValues(facet, query, data) {
  let allIdx;

  if (facet === 'port') {
    allIdx = data.matches.indexOf(-1);

    if (allIdx > -1) {
      data.matches.splice(allIdx, 1);
      data.matches.push(intl('Common.AllPorts'));
    }

    // Make sure all values in 'data.matches' array are of type string
    data.matches = data.matches.map(String);
  }

  if (facet === 'protocol') {
    data.matches = data.matches
      .filter(protocol => protocol.toString() !== '-1')
      .map(protocol => ServiceUtils.lookupProtocol(protocol));
  }

  dropdownValues[`${facet}-${query}`] = data;
}

export default createApiStore('SERVICES_FACETS_', {
  dispatchHandler(action) {
    const query = action.options && action.options.query;

    switch (action.type) {
      case Constants.SERVICES_FACETS_SUCCESS:
        setDropdownValues(query.facet, query.query, action.data);
        isLoaded = true;
        break;

      case Constants.RESET_FACET_VALUES:
        if (action.data === 'serviceList') {
          dropdownValues = {};
        }

        break;

      default:
        return true;
    }

    this.emitChange();

    return true;
  },

  getDropdownValues() {
    return dropdownValues;
  },

  isLoaded() {
    return isLoaded;
  },
});
