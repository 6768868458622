/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {State} from 'react-router';
import SessionStore from '../stores/SessionStore';
import MapPageStore from '../stores/MapPageStore';
import actionCreators from '../actions/actionCreators';
import LegendPanelComponent from './Map/LegendPanel.jsx';
import GraphComponent from '../components/graph/Graph.jsx';
import GraphSpinner from '../components/graph/GraphSpinner.jsx';
import CommandPanelComponent from '../components/CommandPanel/CommandPanel.jsx';
import PolicyVersionPanelComponent from './Map/PolicyVersionPanel.jsx';
import TrafficFilterComponent from './Map/TrafficFilter.jsx';
import {MapNotification, MapNotificationContainer} from './Map/MapNotification.jsx';
import Navbar from '../components/Navbar.jsx';
import SearchBar from './Map/SearchBar.jsx';
import RouterMixin from '../mixins/RouterMixin';
import Tooltip from './Map/Tooltip.jsx';
import RightClickMenu from './Map/RightClickMenu.jsx';
import {LabelStore} from '../stores';

export default Object.assign(
  React.createClass({
    mixins: [State, RouterMixin],

    getInitialState() {
      return {
        mapLevel: null,
        mapRoute: null,
        mapRouteName: null,
        mapPolicyVersion: MapPageStore.getPolicyVersion(),
        showMT4LWarning: false,
      };
    },

    componentWillMount() {
      if (!SessionStore.isIlluminationApiEnabled()) {
        this.replaceWith('landing');
      }

      if (!SessionStore.isIlluminationMapEnabled()) {
        this.replaceWith('appGroups');
      }
    },

    componentDidMount() {
      this.renderGraph = true;
      MapPageStore.addChangeListener(this.handleChange);
      actionCreators.updateGraphCalculated();
      actionCreators.updateMapType('loc');
      actionCreators.updateMapRoute(this.getParams());

      if (SessionStore.isSuperclusterMember()) {
        actionCreators.setMapNotification('open');
      }

      const labels = LabelStore.getLabelSettingsList();

      if (LabelStore.getLabelSettingsList()) {
        const hideMT4LWarningMap = localStorage.getItem('hideMT4LWarning-map');

        if (hideMT4LWarningMap) {
          this.setState({showMT4LWarning: false});
        } else {
          this.setState({showMT4LWarning: labels.length > 4});
        }
      }
    },

    componentWillReceiveProps() {
      if (!_.isEqual(this.getParams(), this.state.mapRoute)) {
        _.defer(() => {
          actionCreators.updateGraphCalculated();
          actionCreators.updateZoomToFit();
          actionCreators.updateMapRoute(this.getParams());
        });
      }
    },

    componentWillUpdate(nextProps, nextState) {
      // Do not re-render the graph on a route change (wait for graph to be calculated)
      if (!_.isEqual(this.state.mapRoute, nextState.mapRoute)) {
        this.renderGraph = false;
      }
    },

    componentDidUpdate() {
      // Clear the route changed flag
      this.renderGraph = true;
    },

    componentWillUnmount() {
      actionCreators.forceListPageRefresh('rulesetList', true);
      MapPageStore.removeChangeListener(this.handleChange);
    },

    handleChange() {
      this.setState({
        mapLevel: MapPageStore.getMapLevel(),
        mapRoute: MapPageStore.getMapRoute(),
        mapRouteName: MapPageStore.getMapRouteName(),
        mapPolicyVersion: MapPageStore.getPolicyVersion(),
        mapNotification: MapPageStore.getMapNotification(),
      });
    },

    delayMapLevel(nextMapLevel) {
      const currentLevel = MapPageStore.getMapLevel();
      const mapLevel = nextMapLevel;

      actionCreators.updateGraphCalculated();

      if (_.isEmpty(mapLevel)) {
        // if map level is empty, we must want to transition back to /map
        actionCreators.updateMapRoute({});
        this.transitionTo('map');
      } else if (
        _.isEmpty(this.getParams()) ||
        (currentLevel === 'group' && mapLevel.type === 'location') ||
        mapLevel.type === 'full' ||
        (currentLevel === 'workload' && mapLevel.type === 'location')
      ) {
        // if we're currently at top level and about to dig in, or
        // going from one location to another, or
        // going to full map for discovered or no_location clusters
        // or click unexpand location circle in workload level will transition to group level
        actionCreators.updateMapRoute(mapLevel);
        this.transitionTo('mapLevel', mapLevel);
      } else {
        mapLevel.prevtype = currentLevel === 'workload' ? this.getParams().prevtype : this.getParams().type;
        mapLevel.previd = currentLevel === 'workload' ? this.getParams().previd : this.getParams().id;

        // Prevent an intermittent issue where the route is being updated with an emptyp prevtype
        if (mapLevel.prevtype && mapLevel.previd) {
          actionCreators.updateMapRoute(mapLevel);
          this.transitionTo('prevMapLevel', mapLevel);
        }
      }

      actionCreators.updateZoomToFit();
    },

    delayNavBack() {
      actionCreators.unselectComponent();
      actionCreators.updateGraphCalculated();

      let prevLevel = {};

      if (_.isEmpty(this.state.mapRoute.prevtype)) {
        actionCreators.updateMapRoute(prevLevel);
        this.transitionTo('map', prevLevel);
      } else {
        prevLevel = {
          type: this.state.mapRoute.prevtype,
          id: this.state.mapRoute.previd,
        };
        actionCreators.updateMapRoute(prevLevel);
        this.transitionTo('mapLevel', prevLevel);
      }

      actionCreators.updateZoomToFit();
    },

    handleBack() {
      actionCreators.startSpinner();
      _.defer(() => {
        // Allow the spinner to start before transitioning the route.
        this.delayNavBack();
      });
    },

    updateMapLevel(nextMapLevel) {
      actionCreators.startSpinner();
      _.defer(() => {
        // Allow the spinner to start before transitioning the route.
        this.delayMapLevel(nextMapLevel);
      });
    },

    handleCloseNotification() {
      actionCreators.setMapNotification('close');
    },

    handleCloseMT4LNotification(event) {
      event.stopPropagation();
      this.setState({showMT4LWarning: false});
      localStorage.setItem('hideMT4LWarning-map', 'true');
    },

    render() {
      const {mapPolicyVersion, mapLevel, mapNotification, showMT4LWarning} = this.state;
      const classes = cx('MapPage', {'Graph-draft': mapPolicyVersion === 'draft'});
      const title = mapPolicyVersion === 'draft' ? intl('Map.DraftView') : intl('Map.ReportedView');

      return (
        <div className={classes} data-tid="location-map">
          <Navbar
            search={true}
            noRoleSearch={mapLevel && mapLevel !== 'full'}
            type={_.isEmpty(this.getParams()) ? 'main' : 'detail'}
            onUp={this.handleBack}
            title={title}
          />
          <img className="Map-logo" width="72" src="images/illumio_logo.svg" alt="Illumio" />
          <SearchBar type={mapLevel === 'full' ? 'full' : 'loc'} policyVersion={mapPolicyVersion} />
          <GraphComponent
            mapType="loc"
            updateMapLevel={this.updateMapLevel}
            policyVersion={mapPolicyVersion}
            render={this.renderGraph}
          />
          <PolicyVersionPanelComponent policyVersion={mapPolicyVersion} />
          <CommandPanelComponent updateMapLevel={this.updateMapLevel} />
          <TrafficFilterComponent mapLevel={mapLevel} policyVersion={mapPolicyVersion} mapType="loc" />
          <LegendPanelComponent mapLevel={mapLevel} policyVersion={mapPolicyVersion} mapType="loc" />
          <GraphSpinner policyVersion={mapPolicyVersion} />
          <MapNotificationContainer>
            {mapNotification === 'open' && (
              <MapNotification
                type="warning"
                message={intl('Common.SuperClusterPartialDataWarning')}
                onClose={this.handleCloseNotification}
              />
            )}
            {showMT4LWarning && (
              <MapNotification
                type="warning"
                message={intl('IlluminationMap.MapWarning')}
                onClose={this.handleCloseMT4LNotification}
              />
            )}
          </MapNotificationContainer>
          <Tooltip />
          <RightClickMenu policyVersion={mapPolicyVersion} updateMapLevel={this.updateMapLevel} mapType="loc" />
        </div>
      );
    },
  }),
  {
    viewName: () => intl('Common.IlluminationMap'),
    forRoute: 'app.map',
    isAvailable: () => SessionStore.isIlluminationMapEnabled() && !SessionStore.isEdge(),
  },
);
