/**
 * Copyright 2023 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import React from 'react';
import Tooltip from './Tooltip.jsx';
import * as webStorageUtils from '../../../app/utils/webStorage';

export default React.createClass({
  getInitialState() {
    return {
      switchImage: '../../images/Switch-Off.svg',
    };
  },

  async handleToggleNewUI() {
    await new Promise(resolve => {
      this.setState({switchImage: '../../images/Switch-On.svg'}, () => resolve(this.state));
    });

    webStorageUtils.setItem('theme', 'lightning');
    window.location.reload(true);
  },

  render() {
    return (
      <Tooltip location="bottomleft" width={300} content={intl('Common.NewUITooltipOn')}>
        <div className="Navbar-switch" data-tid="switch switch-newUI" onClick={this.handleToggleNewUI}>
          <img className="toggle" width="72" src={this.state.switchImage} alt="Illumio" />
        </div>
      </Tooltip>
    );
  },
});
