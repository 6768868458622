/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import ObjectSelector from './ObjectSelector.jsx';
import {OrgStore, FilterStore} from '../stores';
import StoreMixin from '../mixins/StoreMixin';
import {RestApiUtils} from '../utils';
import actionCreators from '../actions/actionCreators';

function getStateFromStores() {
  const dropdownValues = OrgStore.getAuthSecPrincipalsDropDownValues(this.props.type);

  if (this.props.selectedPrincipals) {
    Object.keys(dropdownValues).forEach(ddKey => {
      const newMatches = dropdownValues[ddKey].matches.filter(
        match => !this.props.selectedPrincipals.some(principal => match === principal.name),
      );

      dropdownValues[ddKey].matches = newMatches;
      dropdownValues[ddKey].num_matches = newMatches.length;
    });
  }

  return {
    items: FilterStore.getSelectorFilters('rbacPages'),
    dropdownValues,
  };
}

export default React.createClass({
  propTypes: {
    type: PropTypes.string.isRequired,
    allowPartial: PropTypes.bool,
    placeholder: PropTypes.string.isRequired,
    directAdd: PropTypes.bool,
    onDirectAdd: PropTypes.func,
    selectedPrincipals: PropTypes.array,
    defaultSelected: PropTypes.string.isRequired,
    onChange: PropTypes.func,
  },

  mixins: [StoreMixin([OrgStore, FilterStore], getStateFromStores)],

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
    this.getFacetValues('name');
  },

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  },

  getFacetValues(facet = 'name', query = '') {
    if (!facet) {
      return;
    }

    RestApiUtils.authSecPrincipals.getCollection({name: query});
  },

  removeItem(filter) {
    actionCreators.removeApiFilter({page: 'rbacPages', filter});

    if (this.props.onChange) {
      this.props.onChange();
    }
  },

  handleClick(evt) {
    if (findDOMNode(this).contains(evt.target)) {
      return;
    }

    actionCreators.resetFacetValues('rbacPages');
  },

  addItem(filter, value) {
    // Direct add is used on the AccessWizard page
    // It adds a selected item to the principals table directly instead of displaying it in the selector
    if (this.props.directAdd) {
      this.props.onDirectAdd(value);
    } else {
      actionCreators.addApiFilter({page: 'rbacPages', filter, value});
    }

    if (typeof value === 'string') {
      RestApiUtils.authSecPrincipals.getCollection({name: value});
    }

    if (this.props.onChange) {
      this.props.onChange();
    }
  },

  render() {
    const facetMap = {};

    facetMap[this.props.defaultSelected] = 'name';

    const props = {
      addItem: this.addItem,
      dropdownValues: this.state.dropdownValues,
      facetMap,
      allowOne: true,
      footerValues: [
        {
          text: `${intl('ObjectSelector.TypeToShowMore')} ${this.props.defaultSelected}`,
          className: 'ObjectSelector-dd-values-item--hint',
        },
      ],
      singleSelect: this.props.defaultSelected,
      getFacetValues: this.getFacetValues,
      hasTitle: [intl('Common.Description')],
      initialValues: [],
      partialItems: this.props.allowPartial && [this.props.defaultSelected],
      items: this.state.items,
      placeholder: this.props.placeholder,
      removeItem: this.removeItem,
      onChange: this.props.onChange,
      returnValue: value => value,
    };

    return <ObjectSelector {...props} />;
  },
});
