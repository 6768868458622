/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import _ from 'lodash';
import RightClickAction from '../../components/RightClickAction';
import actionCreators from '../../actions/actionCreators';
import {RenderUtils, RightClickActionUtils, RestApiUtils} from '../../utils';
import {StoreMixin, UserMixin} from '../../mixins';
import {MapMenuStore, GraphTransformStore, TrafficStore} from '../../stores';
import {getId} from '../../utils/GeneralUtils';
import SessionStore from '../../stores/SessionStore';

function getStateFromStores() {
  return {...MapMenuStore.getMenu()};
}

let prevInteractiontype = GraphTransformStore.getInteractionType();

export default React.createClass({
  mixins: [StoreMixin([MapMenuStore], getStateFromStores), UserMixin],

  componentDidMount() {
    window.addEventListener('contextmenu', this.interactionUpdate);
    window.addEventListener('click', this.handleClose);
  },

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClose);
  },

  componentDidUpdate(prevProps, prevState) {
    const {type, location} = this.state;

    this.getRingFenceRules();

    if (GraphTransformStore.getInteractionType() !== 'rightClick') {
      prevInteractiontype = GraphTransformStore.getInteractionType();
    }

    const RightClickMenuBox = this.refs.RightClickMenuRef;

    if (RightClickMenuBox) {
      const RightClickMenuBoundingRect = RightClickMenuBox.getBoundingClientRect();
      const RightClickMenuWidth = RightClickMenuBoundingRect.width;
      const RightClickMenuHeight = RightClickMenuBoundingRect.height;

      const style = RenderUtils.tooltipOrMenuPosition(
        location,
        type,
        RightClickMenuWidth,
        RightClickMenuHeight,
        'menu',
      ).style;

      if (!_.isEqual(prevState.style, style)) {
        this.setState({style});
      }
    }
  },

  getRingFenceRules() {
    const {data} = this.state;
    let labelsObject;
    let href;

    if (data?.type === 'group' || data?.type === 'appGroup') {
      labelsObject = {labels: Object.values(data.labels).map(label => ({href: label.href}))};
      href = data.href;
    } else if (data?.type === 'location') {
      labelsObject = {labels: [{href: data.href}]};
      href = getId(data.href);
    } else {
      return;
    }

    if (TrafficStore.getRingFenceRules(href)) {
      return;
    }

    _.defer(() =>
      RestApiUtils.ruleCoverage.get(
        [
          {
            source: labelsObject,
            destination: labelsObject,
            services: [{port: -1, protocol: -1}],
            resolve_labels_as: {source: ['workloads'], destination: ['workloads']},
          },
        ],
        labelsObject.labels,
        'ringfence',
        href,
        'draft',
        true,
      ),
    );
  },

  interactionUpdate() {
    actionCreators.updateInteractionType('rightClick');
  },

  handleClose(evt) {
    // To avoid ContextMenu and Click Confusion in Firefox
    if (evt.button === 0) {
      actionCreators.hideMapMenu();

      if (GraphTransformStore.getInteractionType() === 'rightClick') {
        actionCreators.updateInteractionType(prevInteractiontype);
      }
    }
  },

  render() {
    const {type, data, location, style, collapse} = this.state;
    const isUserReadOnly = this.isUserReadOnly();
    const isNewUI = SessionStore.isNewUI();

    let content;

    // Illumination Map
    if (this.props.mapType === 'loc') {
      switch (type) {
        case 'location-map-bg':
          content = <RightClickAction data={{actions: RightClickActionUtils.calculateBackgroundActions(data)}} />;
          break;
        case 'location':
          content = (
            <RightClickAction
              data={{
                actions: RightClickActionUtils.calculateLocationActions(
                  data,
                  this.props.policyVersion,
                  isUserReadOnly,
                  isNewUI,
                ),
              }}
              type={type}
            />
          );
          break;
        case 'cluster':
          content = (
            <RightClickAction
              data={{
                actions: RightClickActionUtils.calculateClusterActions(
                  data,
                  this.props.policyVersion,
                  this.props.mapType,
                  isUserReadOnly,
                  isNewUI,
                ),
              }}
              type={type}
              updateMapLevel={this.props.updateMapLevel}
            />
          );
          break;
        case 'link':
          content = (
            <RightClickAction
              data={{
                actions: RightClickActionUtils.calculateLinkActions(
                  data,
                  this.props.policyVersion,
                  collapse,
                  isUserReadOnly,
                  isNewUI,
                ),
              }}
              type={type}
            />
          );
          break;
        case 'role':
          content = <RightClickAction data={{actions: RightClickActionUtils.calculateRoleActions(data)}} type={type} />;
          break;
        case 'node':
          content = (
            <RightClickAction
              data={{actions: RightClickActionUtils.calculateNodeActions(data, this.props.policyVersion, isNewUI)}}
              type={type}
            />
          );
          break;
      }
    } else if (this.props.mapType === 'app') {
      switch (type) {
        case 'cluster':
          content = (
            <RightClickAction
              data={{
                actions: RightClickActionUtils.calculateClusterActions(
                  data,
                  this.props.policyVersion,
                  this.props.mapType,
                  isUserReadOnly,
                  isNewUI,
                ),
              }}
              type={type}
            />
          );
          break;
        case 'role':
          content = (
            <RightClickAction data={{actions: RightClickActionUtils.calculateRoleActions(data, isNewUI)}} type={type} />
          );
          break;
        case 'link':
          content = (
            <RightClickAction
              data={{
                actions: RightClickActionUtils.calculateLinkActions(
                  data,
                  this.props.policyVersion,
                  collapse,
                  isUserReadOnly,
                  isNewUI,
                ),
              }}
              type={type}
            />
          );
          break;
        case 'node':
          content = (
            <RightClickAction
              data={{actions: RightClickActionUtils.calculateNodeActions(data, this.props.policyVersion, isNewUI)}}
              type={type}
            />
          );
          break;
      }
    }

    if (!location) {
      return <div />;
    }

    const actions = content.props.data.actions;
    const notRenderCondition =
      actions.primaryAction.length === 0 && actions.secondaryAction.length === 0 && actions.tertiaryAction.length === 0;

    if (notRenderCondition) {
      return null;
    }

    return (
      <div className="Map-RightClickMenu" data-tid="menu" style={style} ref="RightClickMenuRef">
        {content}
      </div>
    );
  },
});
