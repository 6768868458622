/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import MapPageStore from '../stores/MapPageStore';
import GraphTransformStore from '../stores/GraphTransformStore';

export default {
  duration: 250,
  showLocationTextRadius: 12, // the radius at which we show location text
  appSupergroupTextSize: 24,

  update(selection) {
    // if location need to be truncated
    selection.call(this.truncatedLocation);

    // Draw the rectangles
    const textPadding = selection.datum().r / 10;
    const textElementPadding = selection.datum().r / 20;
    // Slide all the items up by 20%
    const verticalOffset = selection.datum().r / 8;
    // the radius at which we show location details
    const showDetailsRadius = selection.datum().containerWorkloadsNum
      ? 110
      : selection.datum().virtualServicesNum || selection.datum().virtualServersNum
      ? 80
      : 60;
    const linePadding = (selection.datum().r * 2) / 3;
    const dy = 20;

    const scale = GraphTransformStore.getTransform().scale;
    const realRadius = selection.datum().r * scale;
    const labelFontScale = d3.scale.linear().domain([0, 150]).range([10, 25]);
    const detailFontScale = d3.scale.linear().domain([0, 150]).range([5, 15]);
    const locationTextSize = labelFontScale(realRadius) / scale;
    // Make the numbers slightly larger than the text
    const numTextSize = (detailFontScale(realRadius) * 1.1) / scale;
    const detailsTextSize = detailFontScale(realRadius) / scale;
    const mapType = MapPageStore.getMapType();

    selection
      .select('.il-location-rec')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      // -20 px adds 40 px of padding between circles (20 per circle)
      .attr('r', d => d.r)
      .attr('cx', d => d.x)
      .attr('cy', d => d.y)
      .attr('stroke-width', 2 / scale);

    // color the rectangle based selected or not
    selection.call(this.colorLocationRect);

    // display the rectangles text
    selection
      .select('.il-location-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'middle')
      .attr('font-size', () => (mapType === 'loc' ? locationTextSize : this.appSupergroupTextSize))
      .attr('x', d => d.x)
      .attr('y', d => {
        if (d.focused || d.expanded) {
          return d.y + d.r + locationTextSize;
        }

        return d.y - textPadding - verticalOffset;
      });

    selection
      .select('.il-appgroup-type')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'middle')
      .attr('font-size', this.appSupergroupTextSize)
      .attr('x', d => d.x)
      .attr('y', d => d.y - 5);

    selection
      .select('.il-appgroup-label')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'middle')
      .attr('font-size', this.appSupergroupTextSize)
      .attr('x', d => d.x)
      .attr('y', d => d.y + 20);

    selection
      .select('.il-appgroup-text')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'middle')
      .attr('font-size', 18)
      .attr('x', d => d.x)
      .attr('y', d => d.y + 6 * textPadding)
      .style('fill', '#547281');

    selection
      .select('.il-appgroup-text-prev')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'left')
      .attr('font-size', 18)
      .attr('x', d => d.x - 45)
      .attr('y', d => d.y + 5.5 * textPadding)
      .style('fill', '#277cb2');

    selection
      .select('.il-appgroup-text-next')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'right')
      .attr('font-size', 18)
      .attr('x', d => d.x + 10)
      .attr('y', d => d.y + 5.5 * textPadding)
      .style('fill', '#277cb2');

    selection
      .select('.il-appgroup-arrow-prev')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'left')
      .attr('font-size', 18)
      .attr('x', d => d.x - 60)
      .attr('y', d => d.y + 5.28 * textPadding)
      .style('fill', '#277cb2');

    selection
      .select('.il-appgroup-arrow-next')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('text-anchor', 'right')
      .attr('font-size', 18)
      .attr('x', d => d.x + 52)
      .attr('y', d => d.y + 5.28 * textPadding)
      .style('fill', '#277cb2');

    selection
      .select('.il-appgroup-num')
      .transition()
      .duration(this.duration)
      .attr('x', d => d.x - 10)
      .attr('y', d => d.y - 40)
      .attr('font-size', 24)
      .style('fill', '#277cb2');

    selection
      .select('.il-appgroup-rec-prev')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('x', d => d.x - 60)
      .attr('y', d => d.y + 4 * textPadding)
      .attr('height', 18)
      .attr('width', 55)
      .style('fill', 'transparent');

    selection
      .select('.il-appgroup-rec-next')
      .transition()
      .duration(this.duration)
      .attr('display', () => (realRadius < this.showLocationTextRadius ? 'none' : 'block'))
      .attr('x', d => d.x + 10)
      .attr('y', d => d.y + 4 * textPadding)
      .attr('height', 18)
      .attr('width', 55)
      .style('fill', 'transparent');

    selection.call(this.truncateLabel, scale, realRadius, locationTextSize);

    // display the line in the middle of the location circle
    selection
      .select('.il-location-line')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('x1', d => d.x - linePadding)
      .attr('y1', d => d.y - verticalOffset)
      .attr('x2', d => d.x + linePadding)
      .attr('y2', d => d.y - verticalOffset);

    selection
      .select('.il-clusters-num')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', numTextSize)
      .attr('text-anchor', 'end')
      .attr('y', textPadding + dy)
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-clusters-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', detailsTextSize)
      .attr('text-anchor', 'start')
      .attr('x', textElementPadding)
      .attr('y', textPadding + dy)
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-workloads-num')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', numTextSize)
      .attr('text-anchor', 'end')
      .attr('y', textPadding + 2 * dy + detailsTextSize)
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-workloads-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', detailsTextSize)
      .attr('text-anchor', 'start')
      .attr('x', textElementPadding)
      .attr('y', textPadding + 2 * dy + detailsTextSize)
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-virtualServers-num')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', numTextSize)
      .attr('text-anchor', 'end')
      .attr('y', d => textPadding + dy + (dy + detailsTextSize) * (2 + d.row.virtualServers))
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-virtualServers-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', detailsTextSize)
      .attr('text-anchor', 'start')
      .attr('x', textElementPadding)
      .attr('y', d => textPadding + dy + (dy + detailsTextSize) * (2 + d.row.virtualServers))
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-virtualServices-num')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', numTextSize)
      .attr('text-anchor', 'end')
      .attr('y', d => textPadding + dy + (dy + detailsTextSize) * (2 + d.row.virtualServices))
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-virtualServices-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', detailsTextSize)
      .attr('text-anchor', 'start')
      .attr('x', textElementPadding)
      .attr('y', d => textPadding + dy + (dy + detailsTextSize) * (2 + d.row.virtualServices))
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-containerWorkloads-num')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', numTextSize)
      .attr('text-anchor', 'end')
      .attr('y', textPadding + dy + (dy + detailsTextSize) * 2)
      .attr('dy', dy - verticalOffset);

    selection
      .select('.il-containerWorkloads-text')
      .transition()
      .duration(d => (d.drag ? 0 : this.duration))
      .attr('display', d => (d.focused || d.expanded || realRadius < showDetailsRadius ? 'none' : 'block'))
      .attr('font-size', detailsTextSize)
      .attr('text-anchor', 'start')
      .attr('x', textElementPadding)
      .attr('y', textPadding + dy + (dy + detailsTextSize) * 2)
      .attr('dy', dy - verticalOffset);

    selection.select('.il-location-elements').attr('transform', d => {
      const paddingX = d.containerWorkloadsNum
        ? d.r / 3
        : d.virtualServicesNum || d.virtualServersNum
        ? d.r / 4
        : d.r / 5;

      return `translate(${d.x - paddingX},${d.y})`;
    });

    selection
      .select('.il-location-title')
      .text(d =>
        [d.name, `${intl('Common.Groups')}: ${d.clustersNum}`, `${intl('Common.Workloads')}: ${d.workloadsNum}`].join(
          '\n',
        ),
      );
  },

  truncateLabel(selection, scale, realRadius, locationTextSize) {
    const mapType = MapPageStore.getMapType();
    const locationText = selection.select('.il-location-text');

    let name =
      mapType === 'loc'
        ? locationText.datum().name
        : `${locationText.datum().ruleCoverageTruncated ? '< ' : ''}${locationText.datum().appGroupsNum}`;

    locationText.text(name);

    const width = realRadius * 1.8;
    let textLength = locationTextSize * scale * name.length;

    while (textLength > width && name.length > 1) {
      name = name.slice(0, -1);
      locationText.text(`${name}...`);
      textLength = locationTextSize * scale * name.length;
    }
  },

  truncatedLocation(selection) {
    const datum = selection.datum();

    if (datum.truncated) {
      const scale = GraphTransformStore.getTransform().scale;

      selection
        .select('.il-truncated-location-msg')
        .selectAll('.il-truncated-text')
        .attr('text-anchor', 'middle')
        .attr('font-size', 15 / scale)
        .attr('x', datum.x)
        .attr('y', (d, i) => datum.y + ((i - 1) * 30) / scale);
    }
  },

  colorLocationRect(selection) {
    // don't need transition
    const location = selection.datum();
    const mapRoute = MapPageStore.getMapRoute();

    if (location.selected) {
      selection
        .select('.il-location-rec')
        .attr('fill', '#fafefe')
        .attr('filter', 'url(#selectFilter)')
        .attr('fill-opacity', 1);
    } else {
      selection
        .select('.il-location-rec')
        .attr('fill', d => (d.appGroupType ? '#f0f3f5' : '#f0f3f5'))
        .attr('stroke', '#b6c5cc')
        .attr('filter', 'none')
        .attr('fill-opacity', d => (d.appGroupType ? 1 : 0.8));
    }

    if (mapRoute.type === location.appGroupType && location.action === 'next') {
      selection.select('.il-location-rec').attr('stroke', '#3490cc');
    }
  },

  hoverLocation(selection) {
    const location = selection.datum();

    if (location.selected) {
      return;
    }

    selection
      .select('.il-location-rec')
      .attr('fill', '#fafefe')
      .attr('filter', 'url(#selectFilter)')
      .attr('fill-opacity', 1);
  },

  unhoverLocation(selection) {
    const location = selection.datum();

    if (location.selected) {
      return;
    }

    this.colorLocationRect(selection);
  },

  dragLocation(selection, dragMove, dragEnd, selectLocation) {
    // I HATE ALL THE CHANGES HERE!!!
    let moveDistance = 5;
    let isClicked = false;
    let originX;
    let originY;

    const drag = d3.behavior
      .drag()
      .on('dragstart', () => {
        const mapLevel = MapPageStore.getMapLevel();

        if (mapLevel === 'location') {
          d3.event.sourceEvent.stopPropagation();
          document.dispatchEvent(new Event('mousedown')); // for triggering menu-drop down close
        }
      })
      .on('drag', () => {
        const mapLevel = MapPageStore.getMapLevel();

        if (mapLevel === 'location') {
          // reason about getting scale here is to make sure gets the exact current scale
          const scale = GraphTransformStore.getTransform().scale;

          // don't use d.x and d.y. They are different from d3.event.x and d3.event.y
          originX = _.isNumber(originX) ? originX : d3.event.x;
          originY = _.isNumber(originY) ? originY : d3.event.y;
          moveDistance = Math.sqrt(Math.pow(d3.event.x - originX, 2) + Math.pow(d3.event.y - originY, 2));
          moveDistance *= scale;
          isClicked = moveDistance < 4;
          dragMove(d3.event.dx, d3.event.dy, isClicked);
        }
      })
      .on('dragend', () => {
        const mapLevel = MapPageStore.getMapLevel();

        if (mapLevel === 'location') {
          d3.event.sourceEvent.stopPropagation();
          originX = null;
          originY = null;

          if (isClicked) {
            selectLocation(d3.event);
          } else {
            dragEnd();
          }
        }
      });

    selection.call(drag);
  },
};
