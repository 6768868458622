/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import ExplorerActions from '../../actions/ExplorerActions';
import {GraphDataUtils} from '../../utils';
import {Navigation} from 'react-router';
import {PolicyGeneratorMixin} from '../../mixins';
import Dialog from '../Dialog.jsx';
import Icon from '../Icon.jsx';
import ToggleDropdown from '../ToggleDropdown.jsx';
import ClearTrafficModal from './ClearTrafficModal.jsx';

export default React.createClass({
  mixins: [Navigation, PolicyGeneratorMixin],

  handleClick(action) {
    if (action.serverAction) {
      action.serverAction();
    }

    if (action.navAction) {
      const nav = action.navAction;

      if (nav.newMap) {
        actionCreators.unselectComponent();
      }

      if (nav.to.toLowerCase().includes('map') && !nav.newMap) {
        this.props.updateMapLevel(nav.params);
      } else {
        if (action.appGroup) {
          this.setAppGroup(action.appGroup);

          if (action.text === intl('Map.Vulnerability')) {
            actionCreators.setAppMapVersion('vulnerability');
          } else if (action.text === intl('Common.AppGroupMap')) {
            actionCreators.setAppMapVersion('policy');
          }
        }

        if (action.connectedAppGroup) {
          this.setSelectedConnectedAppGroups(action.connectedAppGroup);
        }

        this.transitionTo(nav.to, nav.params, nav.query);
      }
    }

    if (action.text === intl('Map.Vulnerability')) {
      actionCreators.setAppMapVersion('vulnerability');
    } else if (action.text === intl('Map.Policy')) {
      actionCreators.setAppMapVersion('policy');
    }

    if (action.modalAction) {
      const modal = action.modalAction;
      const actions = [
        {
          text: intl('Common.Cancel'),
          tid: 'cancel',
          type: 'nofill',
          onClick: () => {
            actionCreators.closeDialog();
          },
        },
        {
          text: intl('Common.OK'),
          type: 'primary',
          tid: 'ok',
          ref: 'confirmButton',
          onClick: () => {
            modal.onConfirm();
            actionCreators.closeDialog();
          },
        },
      ];

      actionCreators.openDialog(
        <Dialog type="detail" className="IncreaseVenUpdate" title={modal.title} actions={actions}>
          {modal.message}
        </Dialog>,
      );
    }

    if (action.clearTrafficAction) {
      actionCreators.openDialog(<ClearTrafficModal data={action.clearTrafficAction} />);
    }

    if (action.workloadListAction) {
      const labelsAndLabelGroups = [];

      action.workloadListAction.labels.forEach(label => {
        if (label.key) {
          labelsAndLabelGroups.push(label);
        }
      });

      const filter = {labelsAndLabelGroups};
      const params = {
        workloadlist: JSON.stringify({filter}),
      };

      this.transitionTo('workloads.list', null, params);
    }

    if (action.explorerAction) {
      ExplorerActions.setFilters(action.explorerAction.filters);
      this.transitionTo('explorer', null, {go: true});
    }

    if (action.rulesetListAction) {
      this.transitionTo('rulesets.list', null, {
        rulesetlist: JSON.stringify({filter: {labelsAndLabelGroups: action.rulesetListAction.labels}}),
      });
    }

    if (action.expandRoleAction) {
      actionCreators.updateGraphCalculated();
      actionCreators.expandRole(action.expandRoleAction.data);
    }

    if (action.collapseRoleAction) {
      actionCreators.updateGraphCalculated();
      actionCreators.collapseRole(action.collapseRoleAction.data);
    }

    if (action.actionType) {
      actionCreators.clickActionItem({
        type: action.actionType,
        entityType: this.props.data.type,
      });
    }

    if (action.loadConnectedGroupAction) {
      sessionStorage.setItem('loadConnectedGroups', action.loadConnectedGroupAction);
      GraphDataUtils.loadTraffic();
      actionCreators.expandCollapseConnectedGroup();
    }

    if (action.select) {
      actionCreators.selectComponent([action.select]);
    }
  },

  renderListItem(action) {
    return (
      <div
        key={action.text}
        className={action.disabled ? 'MapActionBar-Actions-List-Item-Disabled' : 'MapActionBar-Actions-List-Item'}
        onClick={!action.disabled && _.partial(this.handleClick, action)}
        data-tid={`action-item-${_.snakeCase(action.text).replaceAll(/_/gi, '-')}`}
      >
        {action.text}
      </div>
    );
  },

  render() {
    const {title, msg, primaryAction, secondaryActions, tertiaryActions} = this.props.data.actions;

    if (!primaryAction && (!secondaryActions || !secondaryActions.length)) {
      return null;
    }

    let size;
    let actionIcon;

    if (primaryAction) {
      size =
        primaryAction?.text === intl('Role.Collapse') || primaryAction.text === intl('Role.Expand')
          ? 'large'
          : 'xlarge';
      actionIcon = primaryAction.icon ? <Icon name={primaryAction.icon} size={size} position="after" /> : null;
    }

    const secondaryActionsItems = secondaryActions && secondaryActions.map(action => this.renderListItem(action));

    const tertiaryActionItems = tertiaryActions && tertiaryActions.map(action => this.renderListItem(action));

    if (!_.isEmpty(tertiaryActionItems)) {
      secondaryActionsItems.push(
        <ToggleDropdown
          after
          data={{title: _.startCase(intl('Common.More')), content: tertiaryActionItems}}
          tid="more-actions"
          key="toggleDropdown"
        />,
      );
    }

    return (
      <div className="MapActionBar">
        {title || msg ? (
          <div className="MapActionBar-Msg-Box">
            <div className="MapActionBar-Title">{title}</div>
            <div className="MapActionBar-Msg">{msg}</div>
          </div>
        ) : null}
        <div className="MapActionBar-Actions">
          <div
            className={`MapActionBar-Actions-List${_.isEmpty(primaryAction) ? ' MapActionBar-Actions-NoBorder' : ''}`}
          >
            {secondaryActionsItems}
          </div>
          {_.isEmpty(primaryAction) ? null : (
            <div
              className="MapActionBar-Actions-Primary"
              onClick={_.partial(this.handleClick, primaryAction)}
              data-tid={`action-item-${_.snakeCase(primaryAction.text).replaceAll(/_/gi, '-')}`}
            >
              {primaryAction.text} <span className="MapActionBar-Actions-Primary-Items">{actionIcon}</span>
            </div>
          )}
        </div>
      </div>
    );
  },
});
