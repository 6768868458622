/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import fileSaver from 'file-saver';
import {GeneralStore, DNSStore, SessionStore, GraphStore, ExplorerStore} from '../../stores';
import {EditLabelsDialog, ModeAlert} from '../../modals';
import {StoreMixin, UserMixin} from '../../mixins';
import {RestApiUtils, GridDataUtils, GraphDataUtils, ServiceUtils} from '../../utils';
import actionCreators from '../../actions/actionCreators';
import ExplorerActions from '../../actions/ExplorerActions';
import {ExportUtils} from '../../utils/Explorer';
import {ToolBar, ToolGroup} from '../ToolBar';
import {Button, Pagination, Grid, SpinnerOverlay, Spinner, HoverMenu, ExpandableGridDataList} from '..';
import ExplorerAddressesDialog from '../../modals/ExplorerAddressesDialog';
import PolicyGeneratorAddressesDialog from '../../modals/PolicyGeneratorAddressesDialog';

const MAX_RESULTS_PER_PAGE = 50;

const consumerAndProviderMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeConsumers'), type: 'consumerInclude', tid: 'includeConsumers'},
  {enTranslation: intl('Explorer.ExcludeConsumers'), type: 'consumerExclude', tid: 'excludeConsumers'},
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const consumerOrProviderMenuItems = () => [
  {
    enTranslation: intl('Explorer.IncludeConsumersOrProviders'),
    type: 'consumerOrProviderInclude',
    tid: 'includeConsumersOrProviders',
  },
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

const transmissionAndFQDNMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeProviders'), type: 'providerInclude', tid: 'includeProviders'},
  {enTranslation: intl('Explorer.ExcludeProviders'), type: 'providerExclude', tid: 'excludeProviders'},
];

const portProtocolMenuItems = () => [
  {enTranslation: intl('Explorer.IncludeServices'), type: 'portsInclude', tid: 'includeServices'},
  {enTranslation: intl('Explorer.ExcludeServices'), type: 'portsExclude', tid: 'excludeServices'},
];

const transmissionOrMenuItem = () => [
  {
    enTranslation: intl('Explorer.ExcludeConsumersOrProviders'),
    type: 'consumerOrProviderExclude',
    tid: 'excludeConsumersOrProviders',
  },
];

function getStateFromStores() {
  const orValue =
    (JSON.parse(localStorage.getItem('tx_filters')) && JSON.parse(localStorage.getItem('tx_filters')).Or) ||
    (_.cloneDeep(ExplorerStore.getFilters()) && _.cloneDeep(ExplorerStore.getFilters()).Or) ||
    false;

  return {
    dnsAddresses: DNSStore.getAllIPAddresses(),
    addresses: this.getUnresolvedDnsAddresses(this.props.ipTraffic, DNSStore.getAllIPAddresses()),
    andOrValue: orValue ? 'or' : 'and',
  };
}

export default React.createClass({
  mixins: [UserMixin, StoreMixin([DNSStore], getStateFromStores)],

  getInitialState() {
    const type = ExplorerStore.getExplorerType();
    const selection = GeneralStore.getSelection(`unmanagedIpList${type}`);
    const sorting = GeneralStore.getSorting(`unmanagedIpList${type}`);

    return {
      selection: selection || [],
      sorting: sorting || [{key: 'address', direction: false}],
      currentPage: 1,
      explorerType: type,
      dnsResolveInProcess: false,
      dnsResolved: false,
      partialResolve: false,
      workloadEdit: SessionStore.isWorkloadEditEnabled(),
    };
  },

  componentWillMount() {
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'hide_message');
  },

  componentDidMount() {
    ExplorerStore.addAndOrActionListener(this.handleAndOrSelection);
  },

  componentWillReceiveProps(nextProps) {
    const type = ExplorerStore.getExplorerType();

    if (type !== this.state.explorerType) {
      const selection = GeneralStore.getSelection(`unmanagedIpList${type}`);
      const sorting = GeneralStore.getSorting(`unmanagedIpList${type}`);

      return {
        selection: selection || [],
        sorting: sorting || [{key: 'address', direction: false}],
        currentPage: 1,
        explorerType: type,
        dnsResolveInProcess: false,
        dnsResolved: false,
        partialResolve: false,
        workloadEdit: SessionStore.isWorkloadEditEnabled(),
      };
    }

    if (!_.isEqual(nextProps.ipTraffic, this.props.ipTraffic)) {
      this.setState({
        currentPage: 1,
        dnsResolved: false,
        addresses: this.getUnresolvedDnsAddresses(nextProps.ipTraffic),
      });

      if (this.state.dnsResolveInProcess) {
        _.defer(() => {
          ExplorerActions.interruptDnsLookup({value: true});
        });
      }
    }
  },

  componentWillUnmount() {
    ExplorerStore.removeAndOrActionListener(this.handleAndOrSelection);
  },

  handleAndOrSelection() {
    this.setState({andOrValue: ExplorerStore.getAndOrValue()});
  },

  getUnresolvedDnsAddresses(links, dnsAddresses = this.state.dnsAddresses) {
    return (links || []).reduce((addresses, link) => {
      if (!link.domain && !dnsAddresses.hasOwnProperty(link.address)) {
        addresses.push(link.address);
      }

      return addresses;
    }, []);
  },

  async getDnsValues(links) {
    // we will only look up dns names on demand for all links (instead of only visible links of current page)
    const visibleLinks = links;

    const addresses = this.getUnresolvedDnsAddresses(visibleLinks);

    if (addresses.length) {
      for (const addressChunk of _.chunk([...new Set(addresses)], 25)) {
        try {
          await RestApiUtils.dns.dnsReverseLookup({ips: addressChunk});
        } catch {
          return;
        }
      }

      this.setState({dnsResolveInProcess: false});

      if (!ExplorerStore.getDnsLookupInterrupted()) {
        this.setState({dnsResolved: true});
      }

      _.defer(() => {
        ExplorerActions.interruptDnsLookup({value: false});
      });
    } else {
      this.setState({dnsResolveInProcess: false});

      if (!ExplorerStore.getDnsLookupInterrupted()) {
        this.setState({dnsResolved: true});
      }

      _.defer(() => {
        ExplorerActions.interruptDnsLookup({value: false});
      });
    }
  },

  getVisibleLinks(links, page, sorting) {
    const sort = (sorting || this.state.sorting)[0];
    const offset = ((page || this.state.currentPage) - 1) * MAX_RESULTS_PER_PAGE;

    let visibleLinks = [];

    if (links) {
      visibleLinks = _.sortBy(links, link => {
        switch (sort.key) {
          case 'ports':
            return link[sort.key].join(', ');
          case 'workloads':
            return link[sort.key].length;
          default:
            return link[sort.key];
        }
      });

      if (sort.direction) {
        visibleLinks.reverse();
      }

      visibleLinks = visibleLinks.slice(offset, offset + MAX_RESULTS_PER_PAGE);
    }

    return visibleLinks;
  },

  createWorkload(node, labels) {
    return {
      name: node.domain || this.state.dnsAddresses[node.address] || node.address,
      hostname: node.domain || this.state.dnsAddresses[node.address] || node.address,
      ip: node.address,
      labels,
      interfaces: [
        {
          name: 'eth0',
          address: node.address,
        },
      ],
    };
  },

  handleSelectToggle(selection, lastSelected = selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('unmanagedIpList', newSelection);
    this.setState({selection: newSelection, lastSelected});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('unmanagedIpList', sorting);
    this.setState({sorting});
  },

  handlePageChange(page) {
    this.setState({
      currentPage: page,
      selection: [],
    });
  },

  handleSave() {
    // Find the selected traffic, and convert it into workloads
    const workloads = this.props.ipTraffic
      .filter(traffic => this.state.selection.includes(traffic.id))
      .map(traffic => this.createWorkload(traffic, []));
    // Unique the IPs
    const workloadsObject = workloads.reduce((result, workload) => {
      result[workload.ip] = workload;

      return result;
    }, {});

    // Allow the user to select labels before saving
    actionCreators.openDialog(
      <EditLabelsDialog
        AssignForUnmanagedWorkloads={true}
        workloads={Object.values(workloadsObject)}
        onComplete={this.handleSaveWorkloads}
        returnWorkloads
      />,
    );
  },

  handleSaveWorkloads(workloads, newLabels) {
    // Apply the newLabels to the workloads and Save
    this.saveWorkloads(workloads.map((wl, idx) => ({...wl, labels: newLabels[idx].labels.map(l => ({href: l.href}))})));

    actionCreators.updateGeneralSelection('unmanagedIpList', []);
    this.setState({selection: []});
  },

  handleResolveDomains() {
    let ipTraffic = this.props.ipTraffic;

    if (this.state.selection.length) {
      ipTraffic = this.state.selection.map(traffic => ({address: traffic.split(',')[0]}));
      this.setState({partialResolve: true});
    } else {
      this.setState({partialResolve: false});
    }

    this.setState({dnsResolveInProcess: true});
    this.getDnsValues(ipTraffic);

    if (!GraphStore.getHideMessage().hideResolveDomainsAlert) {
      actionCreators.openDialog(
        <ModeAlert
          message="resolveDomains"
          onConfirm={() => {
            this.ignoreChanges = true;
          }}
        />,
      );
    }
  },

  async saveWorkloads(workloads) {
    const workloadChunks = _.chunk(workloads, 10);
    const finalWorkloads = [];
    const existingAddresses = [];

    this.setState({spinner: true});

    for (const workloadChunk of workloadChunks) {
      const responses = await Promise.all(
        workloadChunk.map(wl => RestApiUtils.workloads.getCollection({ip_address: wl.ip}, true)),
      );

      if (!this.isMounted()) {
        return;
      }

      responses.forEach((response, index) => {
        if (response.body?.length && response.body.every(res => res.public_ip === workloadChunk[index].ip)) {
          existingAddresses.push(workloadChunk[index].name);
        } else {
          finalWorkloads.push(workloadChunk[index]);
        }
      });
    }

    await RestApiUtils.workloads.bulkCreate(finalWorkloads.map(wl => _.omit(wl, ['ip'])));
    this.setState({spinner: false});
    actionCreators.openDialog(
      <ExplorerAddressesDialog
        created={finalWorkloads.length}
        existing={existingAddresses}
        onClose={() => {
          if (this.props.onSave) {
            this.props.onSave();
          }
        }}
      />,
    );

    await Promise.all([
      GraphDataUtils.getTraffic('location', {route: 'groups'}),
      GraphDataUtils.getTraffic('appGroup', {route: 'groups'}),
    ]);
  },

  handleDownload() {
    const dateFormat = {
      month: 'numeric',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    const dateTime = intl.date(Date.now(), dateFormat);
    const fileName = `UnmanagedIPData ${dateTime}.csv`;
    const links = ExportUtils.getUnmanagedIpCSVData(this.state.dnsAddresses, this.props.ipTraffic);
    const blob = new Blob([links], {type: 'text/plain;charset=utf-8'});

    fileSaver.saveAs(blob, fileName);
  },

  handleOpenPortProtocolDialog(addresses) {
    actionCreators.openDialog(
      <PolicyGeneratorAddressesDialog
        addresses={addresses}
        title={intl('PolicyGenerator.Grid.PortsProtocolsProcesses')}
      />,
    );
  },

  handleExpandCollapse(href) {
    if (this.state.expanded === href) {
      this.setState({expanded: false});
    } else {
      this.setState({expanded: href});
    }
  },

  handleExpandablePortValues(values, row, type) {
    const {disableHover} = this.props;
    const expanded = this.state.expanded === [row.id, type].join(',');
    const data = Object.values(row.portObject).map(value => {
      const portProtocol = `${ServiceUtils.getPort(value) || ''} ${ServiceUtils.lookupProtocol(value.proto)}`;

      return (
        <span className="hover-menu-expandable-ports">
          <HoverMenu
            menuItems={portProtocolMenuItems()}
            noLabel
            disabled={disableHover}
            labelActionData={{key: 'portProtocol', value: portProtocol, href: portProtocol}}
            labelProps={{text: portProtocol}}
            type="portProtocol"
          />
          {value.process_name ? (
            <HoverMenu
              menuItems={portProtocolMenuItems()}
              noLabel
              disabled={disableHover}
              labelActionData={{key: 'processName', value: value.process_name, href: value.process_name}}
              labelProps={{text: value.process_name}}
              type="processName"
            />
          ) : null}
          {value.windows_service_name ? (
            <HoverMenu
              menuItems={portProtocolMenuItems()}
              noLabel
              disabled={disableHover}
              labelActionData={{
                key: 'windowsService',
                value: value.windows_service_name,
                href: value.windows_service_name,
              }}
              labelProps={{text: value.windows_service_name}}
              type="windowsService"
            />
          ) : null}
        </span>
      );
    });

    return (
      <div>
        <span className="hover-menu-expandable">
          <ExpandableGridDataList
            data={data}
            expanded={expanded}
            href={[row.id, type].join(',')}
            maxDisplay={4}
            onExpandCollapse={this.handleExpandCollapse}
            expandMessage={intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: data.length - 4})}
            collapseMessage={intl('Common.ShowLess')}
          />
        </span>
      </div>
    );
  },

  handlePortProcessValues(value) {
    if (value.length < 5) {
      return value.join(', ');
    }

    const firstFiveValues = value.slice(0, 5);
    const moreValues = value.slice(5, value.length);

    //If there are more than four services, display a link for '+ X More' which leads to a dialog with all the services
    const portProtocolModalLink = value.length > 5 && (
      <div className="PolicyGeneratorGrid-port-protocol-link">
        <a onClick={_.partial(this.handleOpenPortProtocolDialog, moreValues)} data-tid="elem-link-version">
          {intl('PolicyGenerator.Grid.MorePortProtocol', {numPortsProtocols: value.length - 5})}
        </a>
      </div>
    );

    return (
      <div>
        <div>{firstFiveValues.join(', ')}</div>
        <div> {portProtocolModalLink}</div>
      </div>
    );
  },

  render() {
    const {ipTraffic, disableHover, withFilter, banner, showData} = this.props;
    const {dnsAddresses, dnsResolveInProcess, dnsResolved, partialResolve, workloadEdit, addresses} = this.state;

    const columns = [
      {
        key: 'address',
        label: intl('Common.IPAddress'),
        sortable: true,
        format: value => (
          <span className="hover-menu-ipmargin">
            <HoverMenu
              menuItems={
                this.state.andOrValue === 'and' ? consumerAndProviderMenuItems() : consumerOrProviderMenuItems()
              }
              noLabel
              disabled={disableHover}
              labelActionData={{key: 'ipaddress', value, href: value}}
              labelProps={{text: value}}
              type="ipaddress"
            />
          </span>
        ),
      },
      {
        key: 'domain',
        style: 'hostname',
        label: intl('PCE.FQDN'),
        sortable: true,
        format: (value, row) => (
          <span className="hover-menu-ipmargin">
            <HoverMenu
              menuItems={
                this.state.andOrValue === 'and' ? transmissionAndFQDNMenuItems() : consumerOrProviderMenuItems()
              }
              noLabel
              disabled={disableHover}
              labelActionData={{
                key: 'fqdn',
                value: value || dnsAddresses[row.address],
                href: value || dnsAddresses[row.address],
              }}
              labelProps={{text: value || dnsAddresses[row.address]}}
              type="fqdn"
            />
          </span>
        ),
        sortValue: value => (value || '\uFFFF').toLocaleLowerCase().split('.').reverse().join(''),
      },
      {
        key: 'transmission',
        label: intl('Components.Transmission'),
        sortable: true,
        format: value => (
          <span className="hover-menu-ipmargin">
            <HoverMenu
              menuItems={this.state.andOrValue === 'and' ? transmissionAndFQDNMenuItems() : transmissionOrMenuItem()}
              noLabel
              disabled={disableHover}
              labelActionData={{key: 'transmission', value, href: value.toLowerCase()}}
              labelProps={{text: value}}
              type="transmission"
            />
          </span>
        ),
      },
      {
        key: 'ports',
        label: intl('Port.PortProcess'),
        format: (value, row) => this.handleExpandablePortValues(value, row, 'ports'),
        sortable: true,
      },
      {
        key: 'direction',
        label: intl('TrafficEvents.Direction'),
        sortable: true,
      },
      {
        key: 'workloads',
        style: 'unmanaged-count',
        label: intl('Common.Workloads'),
        format: value => value.length,
        sortValue: value => value.length,
        sortable: true,
      },
      {
        key: 'flows',
        style: 'unmanaged-count',
        label: intl('Common.Flows'),
        sortable: true,
      },
    ];

    return (
      <div className={`UnmanagedIps${withFilter ? '' : ' ExplorerTable-Header-small'}`} data-tid="unmanaged-ips">
        {this.state.spinner ? <SpinnerOverlay /> : null}
        <ToolBar>
          <ToolGroup>
            <Button
              text={intl('Explorer.CreateWorkloads')}
              onClick={this.handleSave}
              disabled={!this.state.selection.length || !workloadEdit}
              tid="saveworkloads"
            />
            <Button
              text={intl('Explorer.ResolveUnknownFqdns')}
              onClick={this.handleResolveDomains}
              autoFocus={true}
              disabled={!addresses.length || (dnsResolved && !partialResolve) || dnsResolveInProcess}
              type="secondary"
              tid="resolveUnknownDomains"
            />
            {dnsResolveInProcess ? <Spinner size="twenty" color="dark" /> : null}
            <Button
              text={intl('Common.Export')}
              onClick={this.handleDownload}
              autoFocus={true}
              disabled={!ipTraffic.length}
              type="secondary"
              tid="export"
            />
            <div className="ListPage-Count">
              {this.state.selection.length > 0 && (
                <span className="ListPage-Count-Selection" data-tid="elem-count-selection">
                  {intl(
                    'Common.SelectedCount',
                    {count: this.state.selection.length, className: 'selectedCountNumber'},
                    {html: true},
                  )}
                </span>
              )}
            </div>
          </ToolGroup>
        </ToolBar>
        {Boolean(ipTraffic.length) && (!banner || showData) && (
          <ToolBar>
            <ToolGroup />,
            <ToolGroup>
              <Pagination
                page={this.state.currentPage}
                totalRows={ipTraffic.length}
                count={ipTraffic.length}
                pageLength={MAX_RESULTS_PER_PAGE}
                onPageChange={this.handlePageChange}
              />
            </ToolGroup>
          </ToolBar>
        )}
        {banner}
        {!banner || showData ? (
          <Grid
            columns={columns}
            idField="id"
            data={ipTraffic}
            sorting={this.state.sorting}
            selection={this.state.selection}
            sortable={true}
            selectable={!SessionStore.isSuperclusterMember() && workloadEdit}
            onSort={this.handleSort}
            onRowSelectToggle={this.handleSelectToggle}
            resultsPerPage={MAX_RESULTS_PER_PAGE}
            totalRows={ipTraffic.length}
            currentPage={this.state.currentPage}
            emptyContent={null}
          />
        ) : null}
        <div className="UnmanagedIps-Position-Right">
          <ToolBar>
            {Boolean(ipTraffic.length) && (
              <ToolGroup>
                <Pagination
                  page={this.state.currentPage}
                  totalRows={ipTraffic.length}
                  count={ipTraffic.length}
                  pageLength={MAX_RESULTS_PER_PAGE}
                  onPageChange={this.handlePageChange}
                />
              </ToolGroup>
            )}
          </ToolBar>
        </div>
      </div>
    );
  },
});
