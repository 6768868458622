/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import cx from 'classnames';
import _ from 'lodash';
import intl from 'intl';
import {ComponentUtils} from '../../utils';
import {ResultList} from '.';
import {Icon} from '..';

const defaultTid = 'comp-select';

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    autoFocus: PropTypes.bool,
    tabIndex: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    formatSelection: PropTypes.func,
    formatResult: PropTypes.func,
  },

  getDefaultProps() {
    return {
      options: [],
      tabIndex: '0',
      onFocus: _.noop,
      onBlur: _.noop,
      onChange: _.noop,
    };
  },

  getInitialState() {
    return {
      focused: false,
      focusedOption: null,
      showOptions: false,
      searchString: '',
    };
  },

  componentDidMount() {
    if (this.props.autoFocus) {
      this.focusInput();
    }
  },

  handleMouseDown(evt) {
    if (this.props.disabled) {
      evt.preventDefault();
    }
  },

  handleClick() {
    if (this.props.disabled) {
      return;
    }

    this.setState({
      showOptions: true,
    });
  },

  handleFocus(evt) {
    if (this.props.disabled) {
      return;
    }

    this.props.onFocus(evt);
    this.setState(() => ({focused: true}));
  },

  handleBlur(evt) {
    if (this.props.disabled) {
      return;
    }

    if (this.disableBlur) {
      evt.preventDefault();
      this.focusInput();

      return;
    }

    this.props.onBlur(evt);
    this.setState({
      focused: false,
      focusedOption: null,
      showOptions: false,
    });
  },

  handleChange(option) {
    if (this.props.disabled) {
      return;
    }

    if (option) {
      this.props.onChange(option.value);
    }

    this.setState({
      focusedOption: null,
      showOptions: false,
    });
  },

  handleKeyDown(evt) {
    if (this.props.disabled) {
      return;
    }

    const focusedOption = this.state.focusedOption;
    const numOptions = this.props.options.length;
    const newState = {};

    switch (evt.key) {
      case 'Tab':
        if (this.state.showOptions) {
          evt.preventDefault();
        }

        break;
      case 'ArrowDown':
        evt.preventDefault();
        newState.showOptions = true;

        if (this.state.showOptions) {
          if (focusedOption === null || focusedOption === numOptions - 1) {
            newState.focusedOption = 0;
          } else {
            newState.focusedOption = focusedOption + 1;
          }
        } else {
          newState.focusedOption = 0;
        }

        break;
      case 'ArrowUp':
        evt.preventDefault();
        newState.showOptions = true;

        if (this.state.showOptions) {
          if (focusedOption === null || focusedOption === 0) {
            newState.focusedOption = numOptions - 1;
          } else {
            newState.focusedOption = focusedOption - 1;
          }
        } else {
          newState.focusedOption = 0;
        }

        break;
      case ' ':
        //prevent scrolling with prevent default, but then onKyPress isn't fired, so trigger it
        evt.preventDefault();
        this.handleKeyPress(evt);

        //if a timeout is active, treat this as a space in the search string, otherwise treat as same as pressing enter
        if (this.keyPressTimeout) {
          return;
        }
      //falls through
      case 'Enter':
        if (this.state.showOptions) {
          if (this.state.focusedOption !== null) {
            this.handleChange(this.props.options[this.state.focusedOption]);

            return;
          }

          newState.showOptions = false;
        } else {
          newState.showOptions = true;
        }
      //no default
    }

    this.setState(newState);
  },

  handleKeyPress(evt) {
    if (this.props.disabled) {
      return;
    }

    const character = String.fromCodePoint(evt.which);

    if (evt.key !== 'Enter') {
      this.setState(previousState => {
        const searchString = previousState.searchString + character;
        const searchStringLower = searchString.toLowerCase();
        const matchFound = _.findIndex(this.props.options, option => {
          const optionString = option.label || option.value;

          if (typeof optionString === 'string') {
            return optionString.toLowerCase().indexOf(searchStringLower) === 0;
          }
        });
        let focusedOption = this.keyPressTimeout ? previousState.focusedOption : 0;

        if (matchFound !== -1) {
          focusedOption = matchFound;
        }

        this.handleKeyPressTimeout();

        if (!previousState.showOptions) {
          this.handleChange(this.props.options[focusedOption]);
        }

        return {
          searchString,
          focusedOption,
        };
      });
    }
  },

  handleResultsMouseDown() {
    this.disableBlur = true;
  },

  handleResultsMouseUp() {
    this.disableBlur = false;
  },

  handleResultsClick(evt) {
    evt.stopPropagation();
  },

  handleChevronClick(evt) {
    if (this.props.disabled) {
      return;
    }

    if (!this.disableChevron) {
      evt.stopPropagation();
      this.setState({
        showOptions: !this.state.showOptions,
      });
    }

    this.disableChevron = false;
  },

  handleChevronMouseDown() {
    if (this.props.disabled) {
      return;
    }

    if (!this.state.focused) {
      this.disableChevron = true;
    }
  },

  keyPressTimeout: null,
  handleKeyPressTimeout() {
    if (this.keyPressTimeout) {
      clearTimeout(this.keyPressTimeout);
    }

    this.keyPressTimeout = setTimeout(() => {
      this.setState({
        searchString: '',
      });
      this.keyPressTimeout = null;
    }, 1000);
  },

  disableChevron: false,
  disableBlur: false,

  focusInput() {
    if (this.refs.input) {
      this.refs.input.focus();
    }
  },

  render() {
    const classes = cx({
      'ReForm-Select': true,
      'ReForm-Select--disabled': this.props.disabled,
      'ReForm-Select--error': this.props.error,
      'ReForm-Select--placeholder': this.props.value === undefined,
      'ReForm-Select--active': this.state.showOptions,
    });

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    let selectedIndex = -1;

    if (this.props.value !== undefined) {
      selectedIndex = _.findIndex(this.props.options, option => option.value === this.props.value);
    }

    let value;

    if (selectedIndex === -1) {
      value = this.props.placeholder || `- ${intl('Common.NoneSelected')} -`;
    } else {
      const selectedOption = this.props.options[selectedIndex];

      if (this.props.formatSelection) {
        value = this.props.formatSelection(selectedOption);
      } else {
        value = selectedOption.label || selectedOption.value;
      }
    }

    return (
      <div
        className={classes}
        data-tid={ComponentUtils.tidString(tids)}
        tabIndex={this.props.tabIndex}
        ref="input"
        onClick={this.handleClick}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        onMouseDown={this.handleMouseDown}
        onKeyDown={this.handleKeyDown}
        onKeyPress={this.handleKeyPress}
      >
        <div className="ReForm-Select-Inner">
          <div className="ReForm-Select-Value" data-tid="comp-select-value">
            {value}
          </div>
          <div onClick={this.handleChevronClick} onMouseDown={this.handleChevronMouseDown}>
            {this.state.showOptions ? (
              <Icon name="caret-up" styleClass="ObjectSelector-down-arrow" size="medium" />
            ) : (
              <Icon name="caret-down" styleClass="ObjectSelector-down-arrow" size="medium" />
            )}
          </div>
        </div>
        {this.state.showOptions ? (
          <div
            className="ReForm-Select-Dropdown"
            onMouseDown={this.handleResultsMouseDown}
            onMouseUp={this.handleResultsMouseUp}
            onClick={this.handleResultsClick}
            data-tid="comp-select-dropdown"
          >
            <ResultList
              options={this.props.options}
              selected={this.props.value}
              onSelect={this.handleChange}
              focusedResult={this.state.focusedOption}
              formatResult={this.props.formatResult}
            />
          </div>
        ) : null}
      </div>
    );
  },
});
