/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import intl from 'intl';
import {State, Navigation} from 'react-router';
import {TabTo} from '../../components';
import MapPageStore from '../../stores/MapPageStore';
import {SessionStore} from '../../stores';

export default React.createClass({
  mixins: [State, Navigation],
  propTypes: {
    type: PropTypes.oneOf(['appgroups', 'groups']),
    active: PropTypes.oneOf([
      'workloads',
      'containerWorkloads',
      'virtualServices',
      'rules',
      'pairingProfiles',
      'vulnerabilities',
      'map',
    ]),
  },

  defaultProps: {
    type: 'appGroups',
    active: 'workloads',
  },

  componentWillMount() {
    if (!SessionStore.isIlluminationApiEnabled()) {
      this.replaceWith('landing');
    }
  },

  handleAppMapSelect() {
    const mapRoute = MapPageStore.getMapRoute('app');

    if (_.isEmpty(mapRoute)) {
      this.transitionTo(mapRoute.route, mapRoute.params);
    } else if (!_.isEmpty(mapRoute.prevtype)) {
      this.transitionTo('prevAppMapLevel', mapRoute);
    } else if (!_.isEmpty(mapRoute.type)) {
      this.transitionTo('appMapLevel', mapRoute);
    }
  },

  render() {
    if (SessionStore.isNewUI()) {
      return null;
    }

    const {active, tabs, group} = this.props;

    return (
      <div className="NavMenu" data-tid="comp-navmenu">
        <div
          className={`NavMenu-item ${active === 'workloads' ? 'NavMenu-item--active' : ''}`}
          data-tid="comp-navmenuitem-workloads"
        >
          <TabTo to="groupWorkloads" params={{id: this.getParams().id}}>
            {intl('Common.Workloads')}
          </TabTo>
        </div>
        {tabs.includes('containerWorkloads') ? (
          <div
            className={`NavMenu-item ${active === 'containerWorkloads' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-container-workloads"
          >
            <TabTo to="groupContainerWorkloads" params={{id: this.getParams().id}}>
              {intl('Common.ContainerWorkloads')}
            </TabTo>
          </div>
        ) : null}
        {tabs.includes('virtualServices') ? (
          <div
            className={`NavMenu-item ${active === 'virtualServices' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-virtual-services"
          >
            <TabTo to="groupVirtualServices" params={{id: this.getParams().id}}>
              {intl('Common.VirtualServices')}
            </TabTo>
          </div>
        ) : null}
        <div
          className={`NavMenu-item ${active === 'rules' ? 'NavMenu-item--active' : ''}`}
          data-tid="comp-navmenuitem-rules"
        >
          <TabTo to="groupRules" params={{id: this.getParams().id}}>
            {intl('Common.Rules')}
          </TabTo>
        </div>
        {group?.caps?.workloads.includes('write') ? (
          <div
            className={`NavMenu-item ${active === 'pairingProfiles' ? 'NavMenu-item--active' : ''}`}
            data-tid="comp-navmenuitem-pairingprofiles"
          >
            <TabTo to="groupPairingProfiles" params={{id: this.getParams().id}}>
              {intl('PairingProfiles.Profiles')}
            </TabTo>
          </div>
        ) : null}
      </div>
    );
  },
});
