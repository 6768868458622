/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import Icon from './Icon.jsx';
import Banner from './Banner.jsx';
import {GridRow, NotificationGroup} from '.';
import React, {PropTypes, isValidElement} from 'react';
import GridUtils from '../utils/GridDataUtils';
import ComponentUtils from '../utils/ComponentUtils';
import Selector from './Selector.jsx';

const defaultTid = 'comp-grid';

const formatStyles = (style, baseClass) => {
  let classes = '';

  if (style) {
    if (Array.isArray(style)) {
      _.forEach(style, styleClass => {
        classes += ` ${baseClass}${styleClass}`;
      });
    } else if (_.isString(style)) {
      classes += ` ${baseClass}${style}`;
    }
  }

  return classes;
};

export default React.createClass({
  propTypes: {
    sortable: PropTypes.bool,
    selectable: PropTypes.bool,
    editable: PropTypes.bool,
    columns: PropTypes.array,
    data: PropTypes.array,
    sorting: PropTypes.array,
    sortDirection: PropTypes.bool,
    selection: PropTypes.array,
    idField: PropTypes.string,
    rowClass: PropTypes.func,
    rowSelectable: PropTypes.func,
    rowClickable: PropTypes.func,
    emptyContent: PropTypes.any,
    onRowSelectToggle: PropTypes.func,
    onSort: PropTypes.func,
    onRowClick: PropTypes.func,
    renderHeader: PropTypes.bool,
    renderAddBar: PropTypes.bool,
    addErrors: PropTypes.array,
    editingId: PropTypes.any,
    editingNotifications: PropTypes.any,
    tid: PropTypes.string,
    resultsPerPage: PropTypes.number,
    currentPage: PropTypes.number,
    // FIXME: Make the prop type check more strict
    notifications: PropTypes.any,
    notificationsStyle: PropTypes.oneOf(['above', 'below']),
    moveRow: PropTypes.func,
    allowReorder: PropTypes.bool,
    filteredSelect: PropTypes.array,
    onFilteredSelect: PropTypes.func,
    totalRows: PropTypes.number,
  },

  getDefaultProps() {
    return {
      data: [],
      columns: [],
      sorting: [],
      selection: [],
      sortDirection: true,
      onSort: _.noop,
      onRowClick: undefined,
      onRowSelectToggle: _.noop,
      rowClass: null,
      rowSelectable: () => true,
      renderHeader: true,
      renderAddBar: false,
      emptyContent: <Banner type="notice" header={intl('Common.NoData')} />,
      allowReorder: false,
    };
  },

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyUp);
    document.addEventListener('keydown', this.handleKeyDown);
  },

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyUp);
    document.removeEventListener('keydown', this.handleKeyDown);
  },

  handleRowClick(evt, data) {
    let target = evt.target;

    if (target.className.includes('Note')) {
      // Prevents issues in pages like Rule Search, Connectivity Check
      return false;
    }

    if (!target.classList) {
      return false;
    }

    if (target.tagName === 'A') {
      return false;
    }

    while (target.classList && !target.classList.contains('Grid-cell')) {
      target = target.parentElement;
    }

    if (target.classList && !target.classList.contains('Grid-cell-checkbox')) {
      if (_.isFunction(this.props.onRowClick)) {
        this.props.onRowClick(data);
      }
    }
  },

  handleKeyDown(evt) {
    if (evt.key === 'Shift') {
      this.shiftPressed = true;
    }
  },

  handleKeyUp(evt) {
    if (evt.key === 'Shift') {
      this.shiftPressed = false;
    }
  },

  handleRowSelectToggle(evt, data) {
    const tableIds = this.tableData.map(row => row[this.props.idField]);

    if (
      this.shiftPressed &&
      this.props.allowShiftSelect &&
      this.props.lastSelected !== undefined &&
      tableIds.includes(this.props.lastSelected)
    ) {
      const newIndex = tableIds.indexOf(data);
      const anchorIndex = tableIds.indexOf(this.props.lastSelected);
      const selectionStart = newIndex > anchorIndex ? anchorIndex : newIndex;
      const selectionEnd = newIndex > anchorIndex ? newIndex + 1 : anchorIndex + 1;
      const selection = this.tableData.slice(selectionStart, selectionEnd).reduce((result, row) => {
        if (this.props.rowSelectable(row)) {
          result.push(row[this.props.idField]);
        }

        return result;
      }, []);

      this.props.onRowSelectToggle(selection, data);
    } else {
      this.props.onRowSelectToggle(data);
    }
  },

  render() {
    let tableData = this.props.data.slice(0);
    let gridAddBar = null;
    const gridRows = [];
    const gridHeaderColumns = [];

    // Sort data, if sorting enabled
    if (this.props.sortable && this.props.sorting.length && this.props.sorting[0]) {
      const column = _.find(this.props.columns, column => column.key === this.props.sorting[0].key);
      const direction = this.props.sorting[0].direction;

      // Currently only support sorting by columns in grid, but can put an else on this to handle other prop sorting
      if (column) {
        let sortFunction = _.noop;

        if (_.isFunction(column.sortValue)) {
          sortFunction = row => {
            const value = GridUtils.getRowValue(row, column.key);

            return column.sortValue(value, row, column);
          };
        } else {
          switch (column.type) {
            case 'string':
              sortFunction = row => {
                let value = GridUtils.getRowValue(row, column.key);

                if (_.isFunction(column.format)) {
                  value = column.format(value, row, column);
                }

                return GridUtils.sortStringValue(value);
              };
              break;
            case 'boolean':
              sortFunction = row => Boolean(GridUtils.getRowValue(row, column.key));
              break;
            case 'date':
            case 'numeric':
            default:
              sortFunction = row => GridUtils.getRowValue(row, column.key);
              break;
          }
        }

        if (_.isFunction(column.sortFunction)) {
          tableData.sort((a, b) => column.sortFunction(a, b));
        } else {
          tableData = _.sortBy(tableData, sortFunction);
        }

        if (direction) {
          tableData.reverse();
        }
      }
    }

    this.tableData = tableData; // used for multiselect

    // Generate add bar (so add controls line up with grid columns)
    if (this.props.renderAddBar) {
      const addColumns = [];

      if (this.props.selectable) {
        addColumns.push(
          <th className="Grid-cell" key="add-select">
            <div />
          </th>,
        );
      }

      this.props.columns.forEach((column, columnIndex) => {
        let classes = 'Grid-cell';

        if (column.style) {
          classes += formatStyles(column.style, 'Grid-cell-add-');
        }

        let addComponent = column.addComponent || null;

        if (_.isFunction(addComponent)) {
          addComponent = addComponent();
        }

        addColumns.push(
          <th key={`add-${column.key}-${columnIndex}`} className={classes} data-tid="comp-grid-add-column">
            {addComponent}
          </th>,
        );
      });

      gridAddBar = (
        <tr className="Grid-addBar" data-tid="comp-grid-add">
          {addColumns}
        </tr>
      );
    }

    // Generate header and header selection column
    if (this.props.renderHeader) {
      if (this.props.selectable) {
        const isAnyCheckable =
          tableData.length &&
          _.find(tableData, row => {
            if (
              (_.isFunction(this.props.rowSelectable) && this.props.rowSelectable(row)) ||
              !_.isFunction(this.props.rowSelectable)
            ) {
              return true;
            }

            return false;
          });
        let allCheckbox = null;

        if (isAnyCheckable) {
          const {totalRows, resultsPerPage, selection, currentPage} = this.props;

          // Added logic when totalRows prop is present and table has pagination otherwise it will do previous logic for other legacy pages
          let allChecked;

          if (totalRows) {
            allChecked =
              resultsPerPage === selection.length ||
              totalRows - (currentPage - 1) * resultsPerPage === selection.length;
          } else {
            allChecked =
              tableData.length &&
              !_.some(tableData, row => {
                if (
                  (_.isFunction(this.props.rowSelectable) && this.props.rowSelectable(row)) ||
                  !_.isFunction(this.props.rowSelectable)
                ) {
                  return !this.props.selection.includes(row[this.props.idField]);
                }

                return false;
              });
          }

          if (this.props.filteredSelect) {
            allCheckbox = (
              <span className="Grid-sorting-selector">
                <Selector
                  trigger={<Icon name="multiple-select" styleClass="Mult-Select" size="medium" />}
                  caret={true}
                  options={this.props.filteredSelect}
                  onSelect={this.props.onFilteredSelect}
                />
              </span>
            );
          } else {
            allCheckbox = (
              <input
                tabIndex="0"
                type="checkbox"
                onChange={evt => {
                  let selectionData = tableData;

                  if (_.isFunction(this.props.rowSelectable)) {
                    selectionData = _.filter(tableData, this.props.rowSelectable);
                  }

                  this.handleRowSelectToggle(evt, _.map(selectionData, this.props.idField));
                }}
                data-tid="elem-checkbox"
                checked={allChecked}
              />
            );
          }
        }

        const classes = cx({
          'Grid-cell': true,
          'Grid-cell-checkbox': !this.props.filteredSelect,
          'Grid-cell-filtered-select': this.props.filteredSelect,
        });

        gridHeaderColumns.push(
          <th key="grid-select" className={classes} data-tid="comp-grid-header-column">
            {allCheckbox}
          </th>,
        );
      }

      // Generate header prop columns
      this.props.columns.forEach((column, columnIndex) => {
        let cellValue;
        let caret;

        // Column text
        if (_.isFunction(column.formatHeader)) {
          cellValue = column.formatHeader(column);
        } else if (column.label) {
          cellValue = column.label;
        } else {
          cellValue = '';
        }

        // Sorting click
        let onClick = null;
        let onSort = null;

        if (column.sortable) {
          onSort = key => {
            if (this.props.sorting.length) {
              if (this.props.sorting[0].key === column.key) {
                this.props.onSort(key || column.key, !this.props.sorting[0].direction);
              } else if (this.props.sorting[0].key !== column.key) {
                this.props.onSort(key || column.key, this.props.sortDirection);
              }
            } else {
              this.props.onSort(key || column.key, this.props.sortDirection);
            }
          };
          onClick = () => onSort();
        }

        // Generate sorting carets
        if (this.props.sorting.length) {
          if (this.props.sorting[0].key === column.key) {
            if (this.props.sorting[0].direction) {
              caret = <Icon name="sort-down" tid="down" styleClass="Sorting" />;
            } else {
              caret = <Icon name="sort-up" tid="up" styleClass="Sorting" />;
            }
          }
        }

        // Add header cell
        let classes = cx({
          'Grid-cell': true,
          'Grid-cell--sortable': column.sortable,
        });

        if (column.style) {
          classes += formatStyles(column.style, 'Grid-cell-header-');
        }

        if (Array.isArray(cellValue)) {
          cellValue = cellValue.map((val, idx) => <span key={idx}>{val}</span>);
        }

        const headerValue = (
          <div className="Grid-cell-header">
            {caret ? <div className="Grid-cell-header-caret">{caret}</div> : null}
            <span data-tid="elem-text">{cellValue}</span>
          </div>
        );

        // Generate sorting menu
        let sortingSelector = null;
        const {sortingItems} = column;

        if (sortingItems && sortingItems.length) {
          const onClick = key => onSort(key);

          sortingSelector = (
            <span className="Grid-sorting-selector">
              <Selector trigger={headerValue} options={sortingItems} onSelect={onClick} />
            </span>
          );
        }

        gridHeaderColumns.push(
          <th
            key={`header-${column.key}-${columnIndex}`}
            onClick={onClick}
            className={classes}
            data-tid="comp-grid-header-column"
          >
            {sortingSelector || headerValue}
          </th>,
        );
      });
    }

    // Slice array for current page before generating rows
    if (this.props.resultsPerPage && this.props.currentPage) {
      const offset = (this.props.currentPage - 1) * this.props.resultsPerPage;

      tableData = tableData.slice(offset, offset + this.props.resultsPerPage);
    }

    // Generate rows
    tableData.forEach((row, index) => {
      const columns = [];
      let checked = false;

      // Generate selection column
      if (this.props.selectable) {
        checked = this.props.selection.includes(row[this.props.idField]);

        let checkbox = null;

        if ((_.isFunction(this.props.rowSelectable) && this.props.rowSelectable(row)) || !this.props.rowSelectable) {
          checkbox = (
            <input
              tabIndex="0"
              type="checkbox"
              data-tid="elem-input"
              onChange={evt => {
                this.handleRowSelectToggle(evt, row[this.props.idField]);
              }}
              checked={checked}
            />
          );
        }

        columns.push(
          <td key={`checkbox-cell-${index}`} className="Grid-cell Grid-cell-checkbox" data-tid="comp-grid-column">
            {checkbox}
          </td>,
        );
      }

      const rowInEditMode = this.props.editable && this.props.editingId === row[this.props.idField];

      // Generate props columns
      this.props.columns.forEach(column => {
        if (!column.key) {
          throw new Error('Grid: All grid columns required a valid key!');
        }

        const value = GridUtils.getRowValue(row, column.key);
        let cellValue;
        let cellEditing = false;

        if (rowInEditMode && column.editComponent) {
          cellValue = column.editComponent;

          if (_.isFunction(cellValue)) {
            cellValue = cellValue(value, row, column);
          }

          cellEditing = true;
        } else if (_.isFunction(column.format)) {
          cellValue = column.format(value, row, column);
        } else {
          switch (column.type) {
            case 'numeric':
              cellValue = GridUtils.formatNumeric(value);
              break;

            case 'date':
              cellValue = GridUtils.formatDate(value);
              break;

            case 'string':
              cellValue = GridUtils.formatString(value);
              break;

            case 'boolean':
              cellValue = GridUtils.formatBoolean(value);
              break;

            default:
              cellValue = value;
          }
        }

        let classes = 'Grid-cell';

        if (cellEditing) {
          classes += ' Grid-cell--editing';
        }

        if (column.style) {
          classes += formatStyles(column.style, 'Grid-cell-');
        }

        if (Array.isArray(cellValue)) {
          cellValue = cellValue.map((val, idx) => <span key={idx}>{val}</span>);
        }

        // Add column
        columns.push(
          <td
            title={column.type === 'string' && _.isString(cellValue) ? cellValue : null}
            key={`${column.key}-${index}`}
            style={column.inlineStyle || {}}
            className={classes}
            data-tid="comp-grid-column"
          >
            <div>{cellValue}</div>
          </td>,
        );
      });

      const rowIsClickable = this.props.rowClickable ? this.props.rowClickable(row) : Boolean(this.props.onRowClick);
      // Generate row
      const classes = {
        'Grid-row': true,
        'Grid-row--checked': checked,
        'Grid-row--clickable': rowIsClickable,
        'Grid-row--editing': rowInEditMode,
      };

      if (_.isFunction(this.props.rowClass)) {
        const cls = this.props.rowClass(row);

        if (cls) {
          classes[cls] = true;
        }
      }

      let rowTid = 'comp-grid-row';

      if (rowInEditMode) {
        rowTid += ' comp-grid-edit';
      }

      //If reordering is allowed, the <tr> has to be wrapped in a GridRow component for the react drag and drop library to recognize it as draggable
      const rowItem = this.props.allowReorder ? (
        <GridRow
          moveRow={this.props.moveRow}
          id={row[this.props.idField]}
          index={index}
          key={row[this.props.idField]}
          className={cx(classes)}
          {...(rowIsClickable && {onClick: evt => this.handleRowClick(evt, row)})}
          columns={columns}
          data-tid={rowTid}
        />
      ) : (
        <tr
          key={`row-${index}`}
          className={cx(classes)}
          {...(rowIsClickable && {onClick: evt => this.handleRowClick(evt, row)})}
          data-tid={rowTid}
        >
          {columns}
        </tr>
      );

      // Both Edit Notifications and Row Notifications can appear simultaneously
      let editNotifications;
      let rowNotifications;

      if (this.props.editingNotifications && rowInEditMode) {
        editNotifications = this.props.editingNotifications;
      }

      if (this.props.notifications && this.props.notifications[row.href]) {
        rowNotifications = isValidElement(this.props.notifications[row.href]) ? (
          this.props.notifications[row.href]
        ) : (
          <NotificationGroup notifications={this.props.notifications[row.href]} />
        );
      }

      if (editNotifications || rowNotifications) {
        // The Row Notifications always appear above Edit Notifications
        const notification = (
          <tr key={`row-notification-${index}`} className="Grid-row Grid-row-notifications" data-tid="comp-grid-errors">
            <td
              className="Grid-cell Grid-cell-notifications"
              colSpan={this.props.columns.length + (this.props.selectable ? 1 : 0)}
            >
              {rowNotifications}
              {editNotifications}
            </td>
          </tr>
        );

        if (this.props.notificationsStyle === 'above') {
          gridRows.push(notification, rowItem);
        } else {
          gridRows.push(rowItem, notification);
        }
      } else {
        gridRows.push(rowItem);
      }
    });

    if (gridRows.length === 0) {
      gridRows.push(
        <tr key="empty-grid-row" className="Grid-row--empty" data-tid="comp-grid-row-empty">
          <td colSpan={gridHeaderColumns.length}>{this.props.emptyContent}</td>
        </tr>,
      );
    }

    const tids = ComponentUtils.tid(defaultTid, this.props.tid);

    // Render table
    return (
      <table className="Grid" data-tid={ComponentUtils.tidString(tids)}>
        <thead className="Grid-header" data-tid="comp-grid-header">
          {gridAddBar}
          <tr className="Grid-row">{gridHeaderColumns}</tr>
        </thead>
        <tbody className="Grid-body" data-tid="comp-grid-body">
          {gridRows}
        </tbody>
      </table>
    );
  },
});
