/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import {minmaxFor} from './GeneralUtils';
import {getPolicyIntlByState} from 'intl/dynamic';
import JoinedVirtualServerStore from '../stores/JoinedVirtualServerStore';
import TrafficStore from '../stores/TrafficStore';
import MapPageStore from '../stores/MapPageStore';
import TrafficFilterStore from '../stores/TrafficFilterStore';
import UserStore from '../stores/UserStore';
import RenderUtils from './RenderUtils';

export function calculateGraph(nodes, links, clusters, prevClusters, selections, mapRoute) {
  const mapLevel = MapPageStore.getMapLevel();

  parseNodesForRender(nodes);
  parseNodesLinksForRender(nodes, links);
  parseClustersForRender(clusters, 'full', mapRoute.id);
  fillNonDiscoveryClusters(nodes, links, clusters, 'full', mapLevel, mapRoute);
  calculateDiscovery(nodes, links, clusters);
  calculateClustersId(clusters, prevClusters);
  calculateUnconnectedNodes(nodes, links);
}

// Parse nodes and links for render
const roleFill = d3.scale
  .ordinal()
  .range([
    '#1F77B4',
    '#9467BD',
    '#5252A6',
    '#96644C',
    '#00818A',
    '#38397A',
    '#A55194',
    '#6E3A67',
    '#0086B8',
    '#6B6BD2',
    '#6F6B4A',
    '#005D7E',
  ]);

const vulnerabilityRoleFill = d3.scale
  .ordinal()
  .domain(['none', 'low', 'medium', 'high', 'critical', 'unknown'])
  .range(['#BDC3C7', '#81CB47', '#F17D00', '#E80000', '#9F1009', '#FFD67F']);

/** Take the filtered array of nodes, and fill them with data for render
 * @param {Array} nodes Array of objects, where the object contains a workload href
 */
export function parseNodesForRender(nodes, scope) {
  _.forOwn(nodes, node => {
    // First find workload to fill node information
    if (node.type === 'workload') {
      parseWorkloadForRender(node);
    } else if (node.type === 'virtualService') {
      parseVirtualServiceForRender(node);
    } else if (node.type === 'role') {
      parseRoleForRender(node, scope);
    }
  });
}

export function parseWorkloadForRender(node) {
  node.type = 'workload';
  node.identifier = node.href;
  node.unmanaged = node.data.unmanaged;
  node.subType = node.data.subType;
  node.policyState = RenderUtils.getPolicyState(node.data, node.type);
  node.labels = RenderUtils.getLabels(node.data.labels);

  if (node.labels.role) {
    node.name = node.labels.role.value;
    // If the hostname is a FQDN, it should be truncated to the "leaf" domain.
    // E.g., if the hostname is "a2nwgdhdc101.prod.iad2.secureserver.net", it should be truncated to just "a2nwgdhdc101"
    node.secondaryName = node.data.name || (node.data.hostname && node.data.hostname.split('.')[0]);
  } else {
    node.name = node.data.name || node.data.hostname;
  }

  const vulnerability = TrafficStore.getNodeVulnerabilityByHref(node.href);

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    if (vulnerability) {
      node.vulnerabilitySeverity = RenderUtils.getVulnerabilityForRender(
        TrafficFilterStore.getAll().exposedVulnerabilities
          ? vulnerability.aggregatedValues.maxExpSeverity
          : vulnerability.aggregatedValues.maxSeverity,
        'severity',
      );
      node.vulnerability = {
        ...vulnerability,
        aggregatedValues: RenderUtils.getEmptyExposures(
          vulnerability.aggregatedValues,
          node.policyState || 'unmanaged',
        ),
      };
    }

    node.fill = vulnerabilityRoleFill(
      TrafficStore.isNodeLoadedForVulnerabilities(node.href)
        ? vulnerability
          ? node.vulnerabilitySeverity
          : 'none'
        : 'unknown',
    );
  } else {
    node.fill = node.labels.role ? roleFill(node.labels.role.value) : '';
  }

  node.roleParent = node.data.roleParent;
  node.clusterParent = node.data.clusterParent;
  node.appGroupParent = node.data.appGroupParent;
  node.roleParent = node.data.roleParent;
  node.built = node.data.built;
  node.stale = node.data.stale;
  node.caps = node.data.caps;
  node.data = RenderUtils.getWorkloadData(node) || node.data;
  node.size = 20;
  node.tooltipInfo = {
    name: node.data.name || node.data.hostname,
    policyState: node.policyState,
    labels: node.labels,
    caps: node.caps,
  };
}

export function parseRoleForRender(role) {
  const filters = TrafficFilterStore.getHiddenPolicyStates().length;
  const mapRoute = MapPageStore.getMapRoute();
  const loadConnectedGroups = JSON.parse(sessionStorage.getItem('loadConnectedGroups')) || [];

  // Group is collapsed if this role is in a connected group in the app group map, and it is not in the loaded set of groups
  const collapsedGroup =
    mapRoute.previd &&
    mapRoute.prevtype === 'focused' &&
    mapRoute.id === role.data.appGroupParent &&
    !loadConnectedGroups.includes([mapRoute.previd, mapRoute.id].join(','));

  role.type = 'role';
  role.identifier = role.href;
  role.labels = RenderUtils.getLabels(role.data.labels);
  role.name = role.data.name;
  role.caps = role.data.caps;
  // role.entityCounts is calculated based on filtering data.entityCounts is the total
  role.entityCounts = filters ? role.entityCounts : role.data.entityCounts;
  role.policyState = RenderUtils.getPolicyState(role.data, role.type, role.entityCounts);
  role.workloadsNum = filters ? role.workloadsNum : role.data.workloadCounts;
  role.containerWorkloadsNum = filters ? role.containerWorkloadsNum : role.data.containerWorkloadCounts;
  role.virtualServicesNum = role.data.virtualServiceCounts;
  role.virtualServersNum = role.data.virtualServerCounts;
  role.clusterParent = role.data.clusterParent;
  role.appGroupParent = role.data.appGroupParent;
  role.built = role.data.built;
  role.stale = role.data.stale;
  role.size = 40;
  role.collapsedGroup = collapsedGroup;

  const vulnerability = TrafficStore.getNodeVulnerabilityByHref(role.href);

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    if (vulnerability) {
      role.vulnerabilitySeverity = RenderUtils.getVulnerabilityForRender(
        TrafficFilterStore.getAll().exposedVulnerabilities
          ? vulnerability.aggregatedValues.maxExpSeverity
          : vulnerability.aggregatedValues.maxSeverity,
        'severity',
      );
      role.vulnerability = {
        ...vulnerability,
        aggregatedValues: RenderUtils.getEmptyExposures(vulnerability.aggregatedValues, role.policyState),
      };
    }

    role.fill = vulnerabilityRoleFill(
      TrafficStore.isNodeLoadedForVulnerabilities(role.href)
        ? vulnerability
          ? role.vulnerabilitySeverity
          : 'none'
        : 'unknown',
    );
  } else {
    role.fill = '#708796';
  }
}

export function parseVirtualServiceForRender(node) {
  node.type = 'virtualService';
  node.identifier = node.href;
  node.labels = RenderUtils.getLabels(node.data.labels);
  node.name = node.labels.role ? node.labels.role.value : node.data.name;
  node.subType = node.data.subType;

  if (node.labels.role) {
    node.name = node.labels.role.value;
    // If the hostname is a FQDN, it should be truncated to the "leaf" domain.
    // E.g., if the hostname is "a2nwgdhdc101.prod.iad2.secureserver.net", it should be truncated to just "a2nwgdhdc101"
    node.secondaryName = node.data.name || (node.data.hostname && node.data.hostname.split('.')[0]);
  } else {
    node.name = node.data.name || node.data.hostname;
  }

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    node.fill = vulnerabilityRoleFill('none');
  } else {
    node.fill = node.labels.role ? roleFill(node.labels.role.value) : '';
  }

  node.policyState = RenderUtils.getPolicyState(node.data, node.type);
  node.roleParent = node.data.roleParent;
  node.clusterParent = node.data.clusterParent;
  node.appGroupParent = node.data.appGroupParent;
  node.built = node.data.built;
  node.stale = node.data.stale;
  node.caps = node.data.caps;
  node.data = JoinedVirtualServerStore.getSpecified(node.href) || node.data;
  node.size = 20;
  node.tooltipInfo = {
    name: node.data.name,
    policyState: node.subType === 'virtual_server' ? getPolicyIntlByState(node.data.mode) : node.policyState,
    labels: node.labels,
  };
}

export function parseClustersForRender(clusters, displayType, mapRouteId) {
  const filters = TrafficFilterStore.getHiddenPolicyStates().length;

  _.forOwn(clusters, cluster => {
    cluster.type = 'group';
    cluster.nodes = [];
    cluster.links = [];
    cluster.policyState = RenderUtils.getPolicyState(cluster.data, cluster.type);
    cluster.labels = RenderUtils.getLabels(cluster.data.labels);
    // cluster.entityCounts is calculated based on filtering data.entityCounts is the total
    cluster.entityCounts = filters ? cluster.entityCounts : cluster.data.entityCounts;
    cluster.virtualServicesNum = cluster.data.virtualServiceCounts;
    cluster.virtualServersNum = cluster.data.virtualServerCounts;
    cluster.containerWorkloadsNum = filters ? cluster.containerWorkloadsNum : cluster.data.containerWorkloadCounts;
    cluster.workloadsNum = filters ? cluster.workloadsNum : cluster.data.workloadCounts;
    cluster.displayType = displayType;
    cluster.discovered = !cluster.data.labels?.length;
    cluster.focused = cluster.href === mapRouteId;
    cluster.name = cluster.data.name;
    cluster.appGroupParent = cluster.data.appGroupParent;
    cluster.built = cluster.data.built;
    cluster.stale = cluster.data.stale;
    cluster.caps = cluster.data.caps;
  });
}

export function parseLocationsForRender(locations, filters, mapRoute, truncated) {
  const policyStateFilters = TrafficFilterStore.getHiddenPolicyStates();

  // Filter group/workload counts on the frontend
  _.forOwn(locations, location => {
    if (filters.length || policyStateFilters.length) {
      let groupCount = 0;

      location.clustersNum = 0;
      location.workloadsNum = 0;
      location.containerWorkloadsNum = 0;
      location.entityCounts = 0;

      for (const groupHref in location.data.groups) {
        const group = location.data.groups[groupHref];

        groupCount += 1;

        if (filters.every(filter => group.labels.find(label => label.href === filter.href))) {
          const totalPolicyStateFiltered = RenderUtils.getPolicyStateGroupWorkloads(group, policyStateFilters);
          const totalPolicyStateFilteredContainers = RenderUtils.getPolicyStateGroupContainerWorkloads(
            group,
            policyStateFilters,
          );

          location.workloadsNum += group.workloadCounts - totalPolicyStateFiltered;
          location.containerWorkloadsNum += group.containerWorkloadCounts - totalPolicyStateFilteredContainers;
          location.entityCounts += group.entityCounts - totalPolicyStateFiltered - totalPolicyStateFilteredContainers;

          if (group.entityCounts > totalPolicyStateFiltered) {
            location.clustersNum += 1;
          }
        }
      }

      //Doing it this way to avoid any _.isEmpty logic which is slow
      if (!groupCount) {
        location.workloadsNum = location.data.num_workloads;
        location.containerWorkloadsNum = location.data.num_container_workloads;
        location.entityCounts = location.data.entityCounts;
      }
    } else {
      location.clustersNum = location.data.num_clusters;
      location.workloadsNum = location.data.num_workloads;
      location.containerWorkloadsNum = location.data.num_container_workloads;
      location.entityCounts = location.data.entityCounts;
    }

    location.href = location.data.href;
    location.type = 'location';
    location.name = location.data.name;
    location.id = _.last(location.data.href.split('/'));
    location.virtualServersNum = location.data.num_virtual_servers;
    location.virtualServicesNum = location.data.num_virtual_services;
    location.row = {
      virtualServices: location.containerWorkloadsNum ? 1 : 0,
      virtualServers:
        location.containerWorkloadsNum && location.virtualServicesNum
          ? 2
          : location.containerWorkloadsNum || location.virtualServicesNum
          ? 1
          : 0,
    };
    location.focused = mapRoute && mapRoute.type === 'location' && location.id === mapRoute.id;
    location.truncated = truncated && location.focused;
    location.draggable = !mapRoute; // location can only be dragged in location view
    location.built = location.data.built;
    location.stale = location.data.stale;
  });
}

export function getParsedAppGroup(appGroup, displayType, mapRouteId) {
  const vulnerability =
    MapPageStore.getAppMapVersion() === 'vulnerability' ? TrafficStore.getNodeVulnerabilityByHref(appGroup.href) : null;
  const policyState = RenderUtils.getPolicyState(appGroup, 'group');

  return {
    type: 'group',
    caps: appGroup.caps,
    appGroup: true,
    filteredNodes: RenderUtils.getPolicyStateGroupWorkloads(appGroup, TrafficFilterStore.getHiddenPolicyStates()),
    nodes: [],
    links: [],
    data: {...appGroup},
    policyState,
    labels: RenderUtils.getLabels(appGroup.labels),
    workloadsNum: appGroup.workloadCounts,
    displayType,
    discovered: false,
    focused: appGroup.href === mapRouteId,
    entityCounts: appGroup.entityCounts,
    virtualServicesNum: appGroup.virtualServiceCounts,
    virtualServersNum: appGroup.virtualServerCounts,
    containerWorkloadsNum: appGroup.containerWorkloadCounts,
    name: appGroup.name,
    href: appGroup.href,
    built: appGroup.built,
    stale: appGroup.stale,
    vulnerabilitySeverity:
      vulnerability &&
      RenderUtils.getVulnerabilityForRender(
        TrafficFilterStore.getAll().exposedVulnerabilities
          ? vulnerability.aggregatedValues.maxExpSeverity
          : vulnerability.aggregatedValues.maxSeverity,
        'severity',
      ),
    vulnerability: vulnerability && {
      ...vulnerability,
      aggregatedValues: RenderUtils.getEmptyExposures(
        RenderUtils.cleanVulnerabilityValues(vulnerability.aggregatedValues),
        policyState,
      ),
    },
  };
}

export function getParsedAppSupergroup(focusedAppGroup, appGroupType, connectedAppGroup, nextAppGroup, prevAppGroup) {
  const appGroupsNum = focusedAppGroup[appGroupType].reduce((count, appGroup) => {
    if (!appGroup.isHidden) {
      count += 1;
    }

    return count;
  }, 0);

  let maxSeverity = -1;

  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    maxSeverity = _.reduce(
      focusedAppGroup[appGroupType],
      (result, appGroup) => {
        const vulnerability = appGroup.vulnerability;

        return Math.max((vulnerability && vulnerability.maxSeverity) || -1, result);
      },
      maxSeverity,
    );
  }

  return {
    href: appGroupType + focusedAppGroup.href,
    type: 'location',
    name: appGroupType,
    appGroupsNum,
    appGroupType,
    id: appGroupType,
    clustersNum: appGroupsNum,
    entityCounts: 1, // a default value to avoid location charts disappearing
    connectedAppGroups: focusedAppGroup[appGroupType],
    nextAppGroup,
    prevAppGroup,
    vulnerabilitySeverity: RenderUtils.getVulnerabilityForRender(maxSeverity, 'severity'),
    appMapVersion: MapPageStore.getAppMapVersion(),
    action: connectedAppGroup ? 'next' : 'view',
    traffic: appGroupType === 'consuming' ? focusedAppGroup.consumingTraffic : focusedAppGroup.providingTraffic,
    focused: false,
    truncated: false,
    draggable: false, // location can only be dragged in location view
    ruleCoverageTruncated: focusedAppGroup.ruleCoverageTruncated,
  };
}

/** Take traffic link's source or target node and map it to the nodes array
 * because the nodes array will be passed to Graph.jsx to be rendered (eventually)
 * @param {Array} nodes Node data for rendering
 * @param {Array} internets Internet data for rendering
 * @param {Object} trafficNode
 * @param {Object} trafficLink
 * @return node, internet, or ipList that matches the trafficNode passed in
 */
export function parseTrafficNodeForRender(nodes, internets, trafficNode) {
  if (
    trafficNode.type === 'workload' ||
    trafficNode.type === 'virtualService' ||
    trafficNode.type === 'virtualServer' ||
    trafficNode.type === 'role' ||
    trafficNode.type === 'group'
  ) {
    return nodes[trafficNode.href];
  }

  const internet = {};

  internet.type = trafficNode.type;
  internet.href = trafficNode.href;
  internet.allHrefs = trafficNode.allHrefs;
  internets.push(internet);

  return internet;
}

export function getParsedAppSupergroupLinks(appSupergroups, appGroups, appGroupHref, mapRoute) {
  const links = {};
  const connectedHref = mapRoute.prevtype === 'focused' ? mapRoute.id : null;

  if (appSupergroups.consuming) {
    const consumingLinkHref = `${appGroupHref},consuming`;

    links[consumingLinkHref] = {
      identifier: consumingLinkHref,
      target: appGroups[appGroupHref],
      source: appSupergroups.consuming,
      connections: {},
      type: 'gray',
      linkType: 'appSupergroupLink',
      traffic: appSupergroups.consuming.traffic,
      isHidden:
        appSupergroups.consuming.appGroupsNum === 0 ||
        (mapRoute.type === 'consuming' &&
          connectedHref &&
          appSupergroups.consuming.connectedAppGroups.every(group => group.href === connectedHref || group.isHidden)),
    };
  }

  if (appSupergroups.providing) {
    const providingLinkHref = `providing,${appGroupHref}`;

    links[providingLinkHref] = {
      identifier: providingLinkHref,
      target: appSupergroups.providing,
      source: appGroups[appGroupHref],
      connections: {},
      type: 'gray',
      linkType: 'appSupergroupLink',
      traffic: appSupergroups.providing.traffic,
      isHidden:
        appSupergroups.providing.appGroupsNum === 0 ||
        (mapRoute.type === 'providing' &&
          connectedHref &&
          appSupergroups.providing.connectedAppGroups.every(group => group.href === connectedHref || group.isHidden)),
    };
  }

  return links;
}

/** Take filtered array of links, and fill them with attributes Link component needs to render
 * @param {Array} nodes Renderable node data, that has already gone through parseNodesForRender
 * @param {Array} links Array of link hrefs, not yet filled with render data
 */
export function parseNodesLinksForRender(nodes, links) {
  const colorBlind = UserStore.getColorBlindMode();
  // note: this internets is not used else where right now.
  // it contains all the internets and could have duplicates.
  const internets = [];

  _.forOwn(links, link => {
    link.identifier = link.href;
    link.source = parseTrafficNodeForRender(nodes, internets, link.data.source, link);
    link.target = parseTrafficNodeForRender(nodes, internets, link.data.target, link);
    link.weight = link.data.weight;
    link.allServicesRule = Boolean(link.data.allServicesRule);
    link.droppedTraffic = link.data.droppedTraffic;
    link.totalConnections = link.data.filteredConnections;
    link.sessionCount = link.data.filteredSessions;
    link.ruleCount = link.data.filteredRules;
    link.allowDenyRuleCount = link.data.filteredRuleDenyRules;
    link.denyRuleCount = link.data.filteredDenyRules;
    link.serviceNum = link.data.serviceNum - (link.data.totalConnections - link.data.filteredConnections);
    link.colorBlind = colorBlind;
    link.maxSeverity = link.data.maxVulnerabilitySeverity;
    link.maxExpSeverity = link.data.maxExpVulnerabilitySeverity;
    link.exposureTraffic = link.data.exposureTraffic;
    link.filtered = {...link.data.filtered};

    if (!RenderUtils.isInternetIpList(link.source) && !RenderUtils.isInternetIpList(link.target)) {
      // Wait for the links to be aggregated before determining the color of the internet links
      const policyVersion = MapPageStore.getPolicyVersion();

      link.version = policyVersion;
      link.type = calculateLinkCoverage(link, policyVersion);
    }
  });
}

export function calculateLinkCoverage(link, policyVersion) {
  if (MapPageStore.getAppMapVersion() === 'vulnerability') {
    // If the target of the link has loaded vulnerabilities
    if (TrafficStore.isNodeLoadedForVulnerabilities(link.target.href) || RenderUtils.isInternetIpList(link.target)) {
      // If any vulnerabilities exist for the traffic on this link
      if (link.maxSeverity >= 0) {
        return 'vulnerability';
      }

      return 'gray';
    }

    return 'unknown';
  }

  if (policyVersion === 'draft' && RenderUtils.isDiscoveredGroupLink(link)) {
    // Cannot write Rules for Discovered Groups
    return 'discovered';
  }

  // The link is always green with an all services rule
  // The link is green if the number of rules at least as big as the number of connections
  // BUT only if we got all the services from the backend
  //
  // The link is red less threshold of the sessions are covered
  //
  // Everything in-between is light green

  // Only worry that the rules are loaded if in draft mode
  // Always wait for the traffic to be loaded
  if (
    (!TrafficStore.isLinkLoadedForRules(link.href) && policyVersion === 'draft') ||
    !TrafficStore.isLinkTrafficLoaded(link.href)
  ) {
    if (link.source.type === 'group') {
      return 'gray';
    }

    if (RenderUtils.isDiscoveredContainerWorkloadLink(link)) {
      // Cannot write Rules in the product for containers without labels
      return 'red';
    }

    return 'unknown';
  }

  const counts = link.filtered || link.data?.filtered;

  let ruleCount;
  let potentialCount;
  let unknownCount;

  const sourceEnforcement = link.source.data?.mode;
  const targetEnforcement = link.target.data?.mode;
  const selective =
    sourceEnforcement?.selective ||
    sourceEnforcement === 'selective' ||
    targetEnforcement?.selective ||
    targetEnforcement === 'selective';
  const enforced =
    sourceEnforcement?.enforced ||
    sourceEnforcement === 'enforced' ||
    targetEnforcement?.enforced ||
    targetEnforcement === 'enforced';
  // Count of sessions blocked due to no rules - applicable in enforced state
  const noRuleBlockCount = enforced ? link.sessionCount - link.ruleCount : 0;
  // Count of sessions blocked due to deny rules - applicable in selective state
  const denyBlockCount = selective && !enforced ? link.denyRuleCount : 0;

  if (policyVersion === 'reported') {
    ruleCount = counts.allowed + counts.allowedAcrossBoundary;
    potentialCount = counts.potentiallyBlocked + counts.potentiallyBlockedByBoundary;
    unknownCount = counts.unknown;
  } else {
    ruleCount = link.ruleCount + link.allowDenyRuleCount;
    // Potential count is the total minus the allowed and blocked
    potentialCount = link.sessionCount - ruleCount - denyBlockCount - noRuleBlockCount;
    unknownCount = 0;

    // Unless everything is covered by a rule in the group links
    // there is no way to be sure about the link color, so return unknown
    if (link.source?.type === 'group' && link.sessionCount - ruleCount) {
      return 'gray';
    }
  }

  const redCount = link.sessionCount - ruleCount - unknownCount - potentialCount;

  if (redCount > 0) {
    return 'red';
  }

  if (potentialCount) {
    return 'orange';
  }

  if (ruleCount) {
    return 'green';
  }

  return 'gray';
}

/** Calculate a cluster or node's internet icons and internet links
 * @param {Object} link Calculated from parseNodesLinksForRender
 * @param {Object} internets Holds all internet data for a cluster or a node, keyed by internet type
 * @param {Object} internetLinks Holds all internet link info for a cluster or node,
 *   keyed by internet type + workload href
 * @param {Object} cluster Cluster the internets and internet links belong to
 *   Pass in undefined if trying to calculate for node
 * @param {Object} node Node the internets and internet links belong to
 *   Pass in undefined if trying to calculate for cluster
 */
export function createInternetsAndLinks(link, internets, internetLinks, cluster, node) {
  let internet;
  let internetType;
  let linkIdentifier;
  let source;
  let target;

  if (RenderUtils.isInternetIpList(link.source)) {
    internet = link.source;
    internetType = link.source.type;
  } else {
    internet = link.target;
    internetType = link.target.type;
  }

  internets[internetType] ||= {
    // For each cluster, want to have three icons, internet, domain and ipList, if they exist
    identifier: internetType,
    type: internetType,
    cluster,
    node,
    internets: [],
  };

  internet.allHrefs.forEach(href => {
    let name = null;

    if (internetType === 'fqdn' || internetType === 'ipList') {
      // If the href is a number, use it as a reference to the FQDN
      if (RenderUtils.isHrefFqdn(href)) {
        name = TrafficStore.getFqdn(href);
      } else {
        // an ipList can have multiple href's, so make sure to push them all in
        const ipList = TrafficStore.getIplist(href);

        name = ipList ? ipList.name : href;
      }
    }

    // If the type is FQDN don't add the internet type to the internets
    if (internetType !== 'fqdn' || RenderUtils.isHrefFqdn(href) || href.includes('ip_list')) {
      internets[internetType].internets.push({
        type: internetType,
        href,
        linkHref: link.href,
        name,
        fqdnIpListMatch: link.data.target.fqdnIpListMatch,
      });
    }
  });

  if (RenderUtils.isInternetIpList(link.source)) {
    source = internets[internetType];
    target = link.target;
    linkIdentifier = `${internetType},${link.target.href}`;
  } else {
    source = link.source;
    target = internets[internetType];
    linkIdentifier = `${link.source.href},${internetType}`;
  }

  internetLinks[linkIdentifier] ||= {
    identifier: linkIdentifier,
    href: link.href,
    source,
    target,
    weight: 0,
    serviceNum: 0,
    allServicesRule: true,
    totalConnections: 0,
    sessionCount: 0,
    ruleCount: 0,
    denyRuleCount: 0,
    allowDenyRuleCount: 0,
    filtered: RenderUtils.getNewPolicyDecisions(0),
    internetLinks: [],
    ipListLinks: [],
    isHidden: link.isHidden,
    isSupressed: link.isSupressed,
    connections: {},
    type: link.type, // TODO: change to "isAllowed"
    colorBlind: link.colorBlind,
    maxSeverity: -1,
    exposureTraffic: true,
  };

  const policyVersion = MapPageStore.getPolicyVersion();
  const appMapVersion = MapPageStore.getAppMapVersion();
  const overlappingServices = RenderUtils.aggregateConnections(
    internetLinks[linkIdentifier].connections,
    link.connections,
  );

  internetLinks[linkIdentifier].version = policyVersion;
  internetLinks[linkIdentifier].isHidden &&= link.isHidden;
  internetLinks[linkIdentifier].isSupressed &&= link.isSupressed;
  internetLinks[linkIdentifier].weight += link.weight;
  internetLinks[linkIdentifier].serviceNum += link.serviceNum - overlappingServices;
  internetLinks[linkIdentifier].allServicesRule &= Boolean(link.allServicesRule);
  internetLinks[linkIdentifier].droppedTraffic |= Boolean(link.droppedTraffic);
  internetLinks[linkIdentifier].totalConnections += link.totalConnections - overlappingServices;
  internetLinks[linkIdentifier].sessionCount += link.sessionCount;
  internetLinks[linkIdentifier].ruleCount += link.ruleCount;
  internetLinks[linkIdentifier].denyRuleCount += link.denyRuleCount;
  internetLinks[linkIdentifier].allowDenyRuleCount += link.allowDenyRuleCount;

  RenderUtils.policyDecisions().forEach(
    policyDecision => (internetLinks[linkIdentifier].filtered[policyDecision] += link.data.filtered[policyDecision]),
  );
  internetLinks[linkIdentifier].maxSeverity = Math.max(internetLinks[linkIdentifier].maxSeverity, link.maxSeverity);
  internetLinks[linkIdentifier].exposureTraffic &= link.data.exposureTraffic;

  // If the vulnerability link is filtered to gray or potentiallyVulnerable do not calculate the coverage
  if (
    appMapVersion !== 'vulnerability' ||
    internetLinks[linkIdentifier].type === 'vulnerability' ||
    (link.type !== 'gray' && link.type !== 'potentiallyVulnerable')
  ) {
    internetLinks[linkIdentifier].type = calculateLinkCoverage(internetLinks[linkIdentifier], policyVersion);
  } else if (internetLinks[linkIdentifier].type !== 'potentiallyVulnerable') {
    // Only use the gray from the new link if the old one is not already potentially blocked
    internetLinks[linkIdentifier].type = link.type;
  }

  internetLinks[linkIdentifier].internetLinks.push({
    type: 'traffic', // TODO: should this be here?
    href: link.href,
    connections: link.connections,
  });
}

/** Go through all links and grab the ones that belong to the cluster
 * If any internet links belong to the cluster, then also add it to the cluster's internets array
 * @param {Array} links All the links to be rendered, calculated from parseNodesLinksForRender
 * @param {Object} cluster Cluster the internets and links belong to
 */
export function internetsAndLinksByCluster(links, cluster) {
  const clusterInternets = {};
  const clusterInternetLinks = {};

  _.forOwn(links, link => {
    if (link.source.cluster === cluster && link.target.cluster === cluster) {
      // If both source and target are in the same cluster, save as intra-app link
      cluster.links.push(link);
      delete links[link.href];
    } else if (
      (RenderUtils.isInternetIpList(link.source) && link.target.cluster === cluster) ||
      (link.source.cluster === cluster && RenderUtils.isInternetIpList(link.target))
    ) {
      // If one side is going to internet but the other is in cluster, save as internet link
      createInternetsAndLinks(link, clusterInternets, clusterInternetLinks, cluster);

      delete links[link.href];
    }
  });

  cluster.internets = _.values(clusterInternets);
  cluster.links = _.union(cluster.links, _.values(clusterInternetLinks));
}

export function fillNonDiscoveryClusters(nodes, links, clusters, displayType, mapRoute, mapLevel) {
  _.forOwn(nodes, node => {
    let cluster;

    if (mapLevel === 'focusedAppGroup') {
      cluster = clusters[node.appGroupParent || node.data.appGroupParent];
    } else if (mapLevel === 'connectedAppGroup') {
      const parent = node.appGroupParent || node.data.appGroupParent;

      cluster = clusters[parent];
    } else {
      cluster = clusters[node.clusterParent || node.data.clusterParent];
    }

    if (!cluster) {
      return;
    }

    node.cluster = cluster;
    cluster.nodes.push(node);
    delete nodes[node.href];
  });

  _.forOwn(clusters, (cluster, key) => {
    if (!_.isEmpty(links)) {
      // if there any links, then fill the cluster with them
      internetsAndLinksByCluster(links, cluster);
      calculateLinkColor(cluster.links);
    }

    // sort nodes to make sure the same sequence in positioning.
    cluster.nodes = _.sortBy(cluster.nodes, 'href');
    cluster.nodeHrefs = cluster.nodes.map(node => node.href);
    cluster.displayType = displayType;
    cluster.focused = mapLevel === 'connectedAppGroup' ? key === mapRoute.previd : key === mapRoute.id;
    // focused, consuming, providing
    cluster.connectionType = mapRoute.prevtype && cluster.focused ? mapRoute.prevtype : mapRoute.type;

    // if cluster is summary or token, make sure to empty cluster.nodes
    // it's important to do this so that we only progressively load
    // full info of the nodes that are showing on the screen
    if (displayType === 'summary') {
      cluster.workloadsNum = cluster.nodes.length;
      cluster.nodes = [];
      cluster.links = [];
    } else if (displayType === 'token') {
      cluster.workloadsNum = _.sum(cluster.nodes, node => (node.type === 'role' ? node.workloadsNum : 1));
      cluster.nodes = [];
      cluster.links = [];
    }
  });
}

// DFS
function calculateDiscovery(nodes, links, clusters) {
  const connectedNodes = {};

  _.forOwn(links, link => {
    // Don't keep track of internet/ipList as part of connected nodes
    if (RenderUtils.isInternetIpList(link.source) || RenderUtils.isInternetIpList(link.target)) {
      return;
    }

    // Or interapp links
    if (link.source.cluster || link.target.cluster) {
      return;
    }

    connectedNodes[link.source.href] ||= {};

    // The particular connection hasn't been discovered yet, so mark it false
    connectedNodes[link.source.href][link.target.href] ||= false;

    connectedNodes[link.target.href] ||= {};

    connectedNodes[link.target.href][link.source.href] ||= false;
  });

  // Assign group numbers to all connected nodes
  let groupNum = 1;
  let source;
  let target;
  const recurseConnections = function (sourceHref, groupNum) {
    _.forOwn(connectedNodes[sourceHref], (discovered, targetHref) => {
      if (!connectedNodes[sourceHref][targetHref]) {
        connectedNodes[sourceHref][targetHref] = groupNum;
        source = _.find(nodes, node => node.href === sourceHref);
        target = _.find(nodes, node => node.href === targetHref);
        source.cluster = `discovery_${groupNum}`;
        target.cluster = `discovery_${groupNum}`;

        // Prevent recursing through targetHrefs that already have a group number assigned
        if (!connectedNodes[targetHref][Object.keys(connectedNodes[targetHref])[0]]) {
          recurseConnections(targetHref, groupNum);
        }
      }
    });
  };
  // convert it as an array and
  // sorted by key to keep the sequence of discovery groups to be the same
  const connectedNodesArray = _.sortBy(connectedNodes, (node, key) => key);

  connectedNodesArray.forEach(element => {
    const sourceHref = _.keys(element)[0];

    recurseConnections(sourceHref, groupNum);
    groupNum += 1;
  });

  // Create discovered clusters
  const discoveredClusters = _.transform(
    nodes,
    (result, node) => {
      if (node.cluster) {
        result[node.cluster] ||= [];

        result[node.cluster].push(node);
      }
    },
    {},
  );

  _.forOwn(discoveredClusters, (nodesInCluster, key) => {
    if (!key) {
      return;
    }

    const cluster = {};
    const discoveredCluster = TrafficStore.getNode('discovered');

    // This should always exist, but to be on thte safe side check first
    cluster.caps = discoveredCluster ? discoveredCluster.caps : {rulesets: [], workloads: []};
    cluster.workloadsNum = 0;
    cluster.containerWorkloadsNum = 0;
    cluster.virtualServersNum = 0;
    cluster.virtualServicesNum = 0;

    // Calculate cluster's nodes, links, internets
    cluster.links = [];
    cluster.nodesHrefs = [];
    cluster.nodes = nodesInCluster
      .map(node => {
        node.cluster = cluster;
        node.clusterParent = key;
        delete nodes[node.href];
        cluster.nodesHrefs.push(node.href);

        if (node.type === 'workload') {
          if (node.subType === 'container') {
            cluster.containerWorkloadsNum += 1;
          } else {
            cluster.workloadsNum += 1;
          }
        } else if (node.type === 'virtualService') {
          if (node.subType === 'virtualServer') {
            cluster.virtualServersNum += 1;
          } else {
            cluster.virtualServicesNum += 1;
          }
        }

        return node;
      })
      .sort((a, b) => (a.href > b.href ? 1 : a.href < b.href ? -1 : 0));

    internetsAndLinksByCluster(links, cluster);

    const clusterDetails = TrafficStore.getCluster(key);
    const policyStates = {visibility: 0, selective: 0, enforced: 0, unmanaged: 0, idle: 0};
    const containerPolicyStates = {visibility: 0, selective: 0, enforced: 0, unmanaged: 0, idle: 0};

    cluster.nodes.forEach(node => {
      if (node.type === 'workload') {
        if (node.subType === 'container') {
          containerPolicyStates[node.policyState] += 1;
        } else {
          policyStates[node.policyState] += 1;
        }
      }
    });

    cluster.type = 'group';
    cluster.href = key; // note(swu): cluster id's?
    cluster.displayType = 'full';
    cluster.discovered = true;

    cluster.labels = [];
    cluster.data = {
      caps: clusterDetails && clusterDetails.caps,
      mode: policyStates,
      containerMode: containerPolicyStates,
    };
    cluster.mode = policyStates;
    cluster.containerMode = containerPolicyStates;
    cluster.policyState = RenderUtils.getPolicyState(cluster, 'group');

    clusters[cluster.href] = cluster;
  });
}

// The function will return true if app/env/loc labels are still the same
const areLabelsEqual = ({labels: a}, {labels: b}) => {
  // a is cluster.labels, b is preCluster.labels
  const isAppLabelEqual = a.app ? a.app && b.app && a.app.href === b.app.href : !b.app;
  const isEnvLabelEqual = a.env ? a.env && b.env && a.env.href === b.env.href : !b.env;
  const isLocLabelEqual = a.loc ? a.loc && b.loc && a.loc.href === b.loc.href : !b.loc;

  return isAppLabelEqual && isEnvLabelEqual && isLocLabelEqual;
};

// The function will return true if all workloads
// in the current cluster equal to the previous cluster.
const areWorkloadsEqual = ({nodeHrefs: currHrefs}, {nodeHrefs: prevHrefs}) => _.isEqual(currHrefs, prevHrefs);

// Calculate id for all clusters including married, engaged and dating
export function calculateClustersId(clusters, prevClusters) {
  if (_.isEmpty(prevClusters)) {
    // Put clusterId initially.
    // NOTE: start clusterId at 1, because 0 defaults to false and that's inconvenient
    let clusterId = 1;

    _.forOwn(clusters, cluster => {
      // Use the Href as unique for non-discovered clusters
      // Make sure a single labeled href does not conflict with a calculated id
      if (!cluster.href.includes('discover')) {
        cluster.clusterId = `x${cluster.href}x`;

        return;
      }

      cluster.clusterId = clusterId;
      cluster.previousId = -1;
      clusterId++;
    });
  } else {
    _.forOwn(clusters, cluster => {
      // Use the Href as unique for non-discovered clusters
      if (!cluster.href.includes('discover')) {
        cluster.clusterId = `x${cluster.href}x`;

        return;
      }

      // Put the same ID to the same cluster for below cases:
      // married => married (href), married => engaged (labels)
      // engaged => married (workloads), engaged => engaged (labels)
      // dating => married (workloads), dating => dating (workloads)
      const prevExist = _.find(prevClusters, prevCluster => {
        if (!_.isEmpty(cluster.labels) && !_.isEmpty(prevCluster.labels)) {
          return areLabelsEqual(cluster, prevCluster);
        }

        if (!_.isEmpty(cluster.nodeHrefs) && !_.isEmpty(prevCluster.nodeHrefs)) {
          // this should only be for discovered clusters, and their nodeHrefs should be filled
          // no matter if they're in full, sweetspot, or cluster level
          return areWorkloadsEqual(cluster, prevCluster);
        }

        return false;
      });

      // if the matching prevCluster we found doesn't have clusterId,
      // then don't apply it
      //TODO: calculate clusterId for discovered clusters
      if (prevExist && prevExist.clusterId) {
        cluster.clusterId = prevExist && prevExist.clusterId;
        cluster.previousId = prevExist && prevExist.previousId;
      }
    });
  }

  // TODO: dating => dating (workloads tree)
  // Put new ID to new clusters
  const newClusters = _.filter(clusters, cluster => _.isUndefined(cluster.clusterId));

  if (newClusters.length) {
    // if previous clusters don't have maxClusterId, default it to 1
    let {max: maxClusterId = 1} = minmaxFor(prevClusters, 'clusterId');

    _.forOwn(newClusters, cluster => {
      cluster.clusterId = maxClusterId + 1;
      maxClusterId++;
    });
  }
}

/** Takes leftover nodes and links that didn't belong to any clusters
 *  and sees if any of the internet links belong to the unconnected nodes
 * @param {Array} nodes Unconnected nodes
 * @param {Array} links Interapp links or internet links for unconncted nodes
 */
export function calculateUnconnectedNodes(nodes, links) {
  // Internet links for unconnected nodes
  _.forOwn(links, link => {
    if (RenderUtils.isInternetIpList(link.source) || RenderUtils.isInternetIpList(link.target)) {
      const node = link.source.type === 'workload' ? link.source : link.target;

      node.internets ||= {};

      node.links ||= {};

      createInternetsAndLinks(link, node.internets, node.links, undefined, node);
      delete links[link.href];
    }
  });
  _.forOwn(nodes, node => {
    node.internets &&= _.values(node.internets);

    node.links &&= _.values(node.links);
  });
}

export function reconcileInterappLinks(links, clusterLinks) {
  _.forOwn(links, link => {
    // remove any workload links where both sides' cluster aren't full
    // If the source or target is missing delete the link
    if (
      !link.source ||
      !link.target ||
      link.source?.cluster?.displayType !== 'full' ||
      link.target?.cluster?.displayType !== 'full'
    ) {
      delete links[link.href];
    }
  });

  _.forOwn(clusterLinks, clusterLink => {
    // remove any cluster links where both sides are full
    // If the source or target is missing delete the link
    if (
      !clusterLink.source ||
      !clusterLink.target ||
      (clusterLink.source?.displayType === 'full' && clusterLink.target?.displayType === 'full')
    ) {
      delete clusterLinks[clusterLink.href];
    }
  });
}

export function calculateLinkColor(links) {
  _.forOwn(links, link => {
    link.type = calculateLinkCoverage(link, MapPageStore.getPolicyVersion());
  });
}

export function getClustersConnectedToLinks(clusterLinks, clusters, allClusters) {
  _.forOwn(clusterLinks, traffic => {
    if (allClusters[traffic.source.href]) {
      clusters[traffic.source.href] = allClusters[traffic.source.href];
    }

    if (allClusters[traffic.target.href]) {
      clusters[traffic.target.href] = allClusters[traffic.target.href];
    }
  });
}

export function getAllRolesInExpandedClusters(nodes, allWorkloads, expandedRoleHrefs, roleThreshold) {
  // get all roles and any expanded roles

  TrafficStore.getAllRoleNodes().forEach(role => {
    //Calculate roles that have been filtered out
    const roleNodes = [];

    role.children.forEach(workload => {
      const node = allWorkloads[workload.href];

      // make sure that not only do we find the workload
      // but that the workload's role parent matches the role
      if (node && role.href === node.data.roleParent) {
        roleNodes.push(node);
      }
    });

    // if role has less than roleThreshold workloads or if it's expanded,
    // then store those
    if (roleNodes.length < roleThreshold || expandedRoleHrefs.includes(role.href)) {
      roleNodes.forEach(node => {
        nodes[node.href] = node;
        node.collapsible = true;
      });
    } else {
      // else store the role
      nodes[role.href] = {
        type: role.type,
        href: role.href,
        nodes: roleNodes,
        data: role,
      };
    }
  });
}

export function calculateRoleTraffics(expandedRoleHrefs, links, roleThreshold) {
  TrafficStore.getAllRoleTraffics().forEach(roleTraffic => {
    if (roleTraffic.source.type === 'virtualServer' || roleTraffic.target.type === 'virtualServer') {
      return;
    }

    let sourceExpanded = expandedRoleHrefs.includes(roleTraffic.source.href);
    let targetExpanded = expandedRoleHrefs.includes(roleTraffic.target.href);
    const sourceIsInternet = RenderUtils.isInternetIpList(roleTraffic.source);
    const targetIsInternet = RenderUtils.isInternetIpList(roleTraffic.target);

    // also see if role has more than roleThreshold children to determine if it's expanded
    if (!sourceIsInternet) {
      sourceExpanded ||= _.values(TrafficStore.getNode(roleTraffic.source.href).children).length < roleThreshold;
    }

    if (!targetIsInternet) {
      targetExpanded ||= _.values(TrafficStore.getNode(roleTraffic.target.href).children).length < roleThreshold;
    }

    if (
      (sourceExpanded && targetExpanded) ||
      (sourceIsInternet && targetExpanded) ||
      (sourceExpanded && targetIsInternet)
    ) {
      // if both sides are expanded, or one of the sides is internet
      // then include all workload links between them
      _.forOwn(roleTraffic.childrenTraffics, workloadTraffic => {
        links[workloadTraffic.href] = {
          href: workloadTraffic.href,
          connections: _.cloneDeep(workloadTraffic.connections),
          data: workloadTraffic,
        };
      });
    } else if (sourceExpanded || targetExpanded) {
      // if only one side is expanded, group it by that side and then put in links
      _.forOwn(roleTraffic.childrenTraffics, workloadTraffic => {
        const trafficKey = sourceExpanded
          ? `${workloadTraffic.source.href},${roleTraffic.target.href}`
          : `${roleTraffic.source.href},${workloadTraffic.target.href}`;
        const edge = TrafficStore.getTraffic(trafficKey);

        // if we can't find this mixed role traffic, just don't draw it
        // most likely we can't find it because new traffic has come in
        // within the past 10 minutes, that full traffic reported
        // and simple traffic didn't report.  this is acceptable not to draw.
        if (edge) {
          links[edge.href] = {
            href: edge.href,
            connections: edge.connections,
            data: edge,
          };
        }
      });
    } else {
      // else just put in the role traffic
      links[roleTraffic.href] = {
        href: roleTraffic.href,
        connections: _.cloneDeep(roleTraffic.connections),
        data: roleTraffic,
      };
    }
  });
}

export default {
  calculateGraph,
  parseNodesForRender,
  parseWorkloadForRender,
  parseRoleForRender,
  parseVirtualServiceForRender,
  parseClustersForRender,
  parseLocationsForRender,
  getParsedAppGroup,
  getParsedAppSupergroup,
  parseTrafficNodeForRender,
  parseNodesLinksForRender,
  getParsedAppSupergroupLinks,
  calculateLinkCoverage,
  createInternetsAndLinks,
  internetsAndLinksByCluster,
  fillNonDiscoveryClusters,
  calculateClustersId,
  calculateUnconnectedNodes,
  reconcileInterappLinks,
  calculateLinkColor,
  getClustersConnectedToLinks,
  getAllRolesInExpandedClusters,
  calculateRoleTraffics,
};
