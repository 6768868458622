/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React, {PropTypes} from 'react';
import _ from 'lodash';
import intl from 'intl';
import {SessionStore} from '../../stores';
import ExplorerActions from '../../actions/ExplorerActions';
import OrgStore from '../../stores/OrgStore';

export default React.createClass({
  propTypes: {
    selectedSource: PropTypes.string,
    selectedTarget: PropTypes.string,
    clickedTick: PropTypes.object,
    clickedTickPath: PropTypes.object,
  },

  shouldComponentUpdate(nextProps) {
    return (
      !_.isEqual(nextProps.selectedSource, this.props.selectedSource) ||
      !_.isEqual(nextProps.selectedTarget, this.props.selectedTarget) ||
      !_.isEqual(nextProps.clickedTick, this.props.clickedTick) ||
      !_.isEqual(nextProps.clickedTickPath, this.props.clickedTickPath)
    );
  },

  handleClick(evt, path, pathClass, dimension) {
    if (pathClass === 'pathBlue') {
      const clickedTick = {...this.props.clickedTick};
      const clickedTickPath = {...this.props.clickedTickPath};

      clickedTickPath[dimension] = [];

      for (const eachPath of this.props.clickedTickPath[dimension]) {
        if (path !== 'Top') {
          clickedTickPath[dimension].push(eachPath);
        }

        if (
          eachPath.clickedLabel === path.clickedLabel &&
          eachPath.clickedValue === path.clickedValue &&
          eachPath.nextLabel === path.nextLabel
        ) {
          break;
        }
      }

      clickedTick[dimension] = _.last(clickedTickPath[dimension]);
      ExplorerActions.clickTick(clickedTick, clickedTickPath);
    }
  },

  renderPath(clickedTickPath, endpoint) {
    const pathSeparator = ' > ';

    if (_.isEmpty(clickedTickPath[endpoint])) {
      return;
    }

    return clickedTickPath[endpoint].map((path, i) => {
      const pathClass = i === clickedTickPath[endpoint].length - 1 ? 'pathGray' : 'pathBlue';
      const pathText =
        path.clickedValue === intl('Common.IPAddress')
          ? path.clickedValue
          : `${SessionStore.isEdge() && path.clickedLabel === 'app' ? '' : _.startCase(path.clickedLabel)} : ${
              path.clickedValue
            }`;

      return (
        <span key={path.clickedValue}>
          <span className="pathGray">{pathSeparator}</span>
          <span className={pathClass} onClick={_.partial(this.handleClick, this, path, pathClass, endpoint)}>
            {pathText.length > 20 ? `${pathText.slice(0, 30)}...` : pathText}
          </span>
        </span>
      );
    });
  },

  render() {
    const {clickedTickPath, selectedSource, selectedTarget} = this.props;
    const sourcePath = this.renderPath(clickedTickPath, 'source');
    const targetPath = this.renderPath(clickedTickPath, 'target');
    const pathClass = !selectedSource || !selectedTarget ? 'dimensionPathHidden' : 'dimensionPath';
    const sourceTopClass = sourcePath ? 'pathBlue' : 'pathGray';
    const targetTopClass = targetPath ? 'pathBlue' : 'pathGray';

    const source = (
      <span className={pathClass}>
        <div className="dimensionPathTitle">{intl('Explorer.ConsumerSelections')}</div>
        <div className="dimensionPathContent">
          <span className={sourceTopClass} onClick={_.partial(this.handleClick, this, 'Top', sourceTopClass, 'source')}>
            Top
          </span>
          {sourcePath}
        </div>
      </span>
    );

    const target = (
      <span className={pathClass}>
        <div className="dimensionPathTitle">{intl('Explorer.ProviderSelections')}</div>
        <div className="dimensionPathContent">
          <span className={targetTopClass} onClick={_.partial(this.handleClick, this, 'Top', targetTopClass, 'target')}>
            Top
          </span>
          {targetPath}
        </div>
      </span>
    );

    const contents = OrgStore.reverseProviderConsumer() ? [source, target] : [target, source];

    return <div className="navPathPanel">{contents}</div>;
  },
});
