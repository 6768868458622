/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React, {PropTypes} from 'react';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import {AlertDialog, Dialog, SpinnerOverlay} from '../components';
import Constants from '../constants';
import StoreMixin from '../mixins/StoreMixin';
import {WorkloadStore, MapPageStore} from '../stores';
import {RestApiUtils, WorkloadUtils, GridDataUtils, GraphDataUtils} from '../utils';

function getStateFromStore() {
  return {
    status: WorkloadStore.getStatus(),
  };
}

export default React.createClass({
  propTypes: {
    workloads: PropTypes.array,
    policyState: PropTypes.string,
    visibilityLevel: PropTypes.string,
    onUpdate: PropTypes.func,
    mode: PropTypes.string,
    logTraffic: PropTypes.string,
  },

  mixins: [StoreMixin(WorkloadStore, getStateFromStore)],

  getDefaultProps() {
    return {
      workloads: [],
      policyState: '',
      visibilityLevel: '',
      mode: '',
      logTraffic: '',
      onUpdate: _.noop,
    };
  },

  handleCancel() {
    actionCreators.closeDialog();
  },

  handleConfirm() {
    if (this.props.containers) {
      this.props.workloads.forEach(workload => {
        RestApiUtils.containerWorkload
          .update(GridDataUtils.getIdFromHref(workload.href), {
            mode: this.props.mode,
            log_traffic: this.props.logTraffic,
            visibility_level: this.props.visibilityLevel || 'flow_summary',
          })
          .catch(_.noop)
          .then(() => {
            actionCreators.closeDialog();
            this.props.onUpdate();
          });
      });
    } else {
      let workloads = _.filter(this.props.workloads, workload => {
        if (workload && workload.agent && workload.agent.status) {
          return true;
        }
      });

      workloads = _.map(workloads, ({href}) => ({href}));
      RestApiUtils.workloads
        .update({
          workloads,
          enforcement_mode: this.props.policyState,
        })
        .catch(_.noop)
        .then(() => {
          actionCreators.closeDialog();
          this.props.onUpdate();
        });
    }

    this.handleReloadGraphData();
  },

  async handleReloadGraphData() {
    // Wait 2 seconds before rebuilding the summary graphs
    if (MapPageStore.getMapType() === 'app') {
      await new Promise(resolve => setTimeout(resolve, 2000));
      GraphDataUtils.getTraffic('appGroup', {type: 'rebuild'});
    } else {
      await new Promise(resolve => setTimeout(resolve, 2000));
      GraphDataUtils.getTraffic('location', {type: 'rebuild'});
    }

    await GraphDataUtils.loadTraffic('rebuild');
  },

  render() {
    let workloads = this.props.workloads;

    if (!this.props.containers) {
      workloads = _.filter(this.props.workloads, workload => {
        if (workload && workload.agent && workload.agent.status) {
          return true;
        }
      });
    }

    const unmanagedCount = this.props.workloads.length - workloads.length;

    if (!workloads.length) {
      return (
        <AlertDialog
          title={intl('Policy.StateChangeCant')}
          message={intl('Policy.UnmanagedWorkloadsNotChangeCount', {
            countNumber: unmanagedCount,
            count: intl.num(unmanagedCount),
          })}
        />
      );
    }

    const psc = WorkloadUtils.policyStateContent(this.props.policyState, this.props.visibilityLevel);

    const actions = [
      {
        text: intl('Common.Cancel'),
        onClick: this.handleCancel,
        type: 'nofill',
        tid: 'cancel',
      },
      {
        text: intl('Common.OK'),
        type: 'primary',
        autoFocus: true,
        onClick: this.handleConfirm,
        tid: 'confirm',
      },
    ];

    return (
      <Dialog
        className="ConfirmPolicyState"
        title={intl('Policy.StateChangeConfirmWorkload')}
        actions={actions}
        tid="policystate"
      >
        {this.state.status === Constants.STATUS_BUSY ? <SpinnerOverlay /> : null}
        <div className="Workload-ChangePolicyState">
          <p data-tid="policystate-managed">
            {intl(
              'Policy.StateChangeMessage',
              {
                count: workloads.length,
                countTid: 'policystate-managed-affected',
                total: this.props.workloads.length,
                totalTid: 'policystate-managed-total',
                title: `${psc.title}${psc.visibilityLevel ? ` ${psc.visibilityLevel}` : ''}`,
              },
              {html: true},
            )}
          </p>
          {!this.props.containers && unmanagedCount > 0 && (
            <p data-tid="policystate-unmanged">
              {intl(
                'Policy.UnmanagedWorkloadsNotChangeCount',
                {
                  countNumber: unmanagedCount,
                  count: <b data-tid="policystate-unmanaged-count">{intl.num(unmanagedCount)}</b>,
                },
                {jsx: true},
              )}
            </p>
          )}
        </div>
      </Dialog>
    );
  },
});
