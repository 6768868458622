/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import cx from 'classnames';
import {State, Navigation} from 'react-router';
import intl from 'intl';
import Label from '../Label';
import Vulnerability from '../Vulnerability';
import StoreMixin from '../../mixins/StoreMixin';
import UserMixin from '../../mixins/UserMixin';
import RestApiUtils from '../../utils/RestApiUtils';
import GraphDataUtils from '../../utils/GraphDataUtils';
import RenderUtils from '../../utils/RenderUtils';
import ServiceUtils from '../../utils/ServiceUtils';
import {
  CommandPanelStore,
  GeneralStore,
  IpListStore,
  MapPageStore,
  ServiceStore,
  TrafficFilterStore,
  TrafficStore,
} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import {Icon, Spinner} from '..';

const MAX_SERVICES = 256;

export function getStateFromStore() {
  return {
    status: TrafficStore.getStatus(),
    vulnerabilityView: MapPageStore.getAppMapVersion() === 'vulnerability',
    policyVersion: MapPageStore.getPolicyVersion(),
    transmissionFilters: TrafficFilterStore.getTransmissionFilters(),
    anyIpList: IpListStore.getAnyIpList(),
    servicesLoaded: ServiceStore.isLoaded(),
    exposedVulnerabilities: TrafficFilterStore.getAll().exposedVulnerabilities,
    trafficSelection: CommandPanelStore.getTrafficSelections(),
    sort: GeneralStore.getSorting('trafficPanel') || {key: 'trafficWeight', direction: true},
  };
}

export default React.createClass({
  mixins: [
    State,
    Navigation,
    UserMixin,
    StoreMixin(
      [CommandPanelStore, GeneralStore, IpListStore, MapPageStore, ServiceStore, TrafficFilterStore, TrafficStore],
      getStateFromStore,
    ),
  ],

  componentDidMount() {
    const {anyIpList, servicesLoaded} = this.state;

    this.requestedLinks = {};

    _.defer(() => {
      if (!anyIpList) {
        RestApiUtils.ipLists.getInstance('any');
      }

      if (!servicesLoaded) {
        RestApiUtils.services.getCollection({max_results: 100_000});
      }
    });

    this.updateSelections();
    this.isPanelMounted = true;
  },

  componentWillUnmount() {
    this.isPanelMounted = false;
  },

  componentDidUpdate(nextProps) {
    const nodes = this.getUnloadedNodes(nextProps);

    // If showAddresses is set and all the nodes are loaded
    if (!nodes.length && this.state.showAddresses) {
      const type = this.state.showAddresses.type;
      const source = nextProps.data[type === 'pb' ? 'targets' : 'sources'].find(
        nextSource => nextSource.href === this.state.showAddresses.source.href,
      );

      // Open the new panel
      _.defer(() => {
        actionCreators.clickActionItem({
          type: 'pbUbAction',
          formData: {type, source},
        });
      });

      // Don't retrigger this once's it's closed
      this.setState({showAddresses: null});
    }

    //Limit the number of re-renders
    const isSelectedServiceFiltered = this.props.data.services.some(service =>
      RenderUtils.isSelectedService(service, nextProps.data.selectedService),
    );

    if (
      !isSelectedServiceFiltered ||
      !RenderUtils.isSelectedService(this.props.data.selectedService, nextProps.data.selectedService) ||
      this.props.data.href !== nextProps.data.href
    ) {
      this.updateSelections();
    }
  },

  getUnloadedNodes(props) {
    const {loadedNodes, transmissionFilters} = this.state;
    const trafficClasses = ['unicast', 'broadcast', 'multicast', 'core_service'];
    const nodes = [];

    transmissionFilters.forEach((transmissionFilter, i) => {
      if (transmissionFilter) {
        // Get the private IPs for any workload connected to the internet
        if (
          this.props.data.sources[0] &&
          (this.props.data.sources[0].type === 'internet' || this.props.data.sources[0].type === 'fqdn')
        ) {
          nodes.push(`${props.data.href.split(',')[1]}x${trafficClasses[i]}`);
        }

        if (
          this.props.data.targets[0] &&
          (this.props.data.targets[0].type === 'internet' || this.props.data.targets[0].type === 'fqdn')
        ) {
          nodes.push(`${props.data.href.split(',')[0]}x${trafficClasses[i]}`);
        }
      }
    });

    return _.difference(nodes, loadedNodes);
  },

  handleSort(column) {
    const {key, direction} = this.state.sort;
    const sort = {key: column, direction: key === column ? !direction : true};

    actionCreators.updateGeneralSorting('trafficPanel', sort);
  },

  handleSelectService(formData) {
    actionCreators.clickActionItem({
      type: 'detailAction',
      formData,
    });
  },

  handleSelectPbUb(pbUb) {
    const nodes = this.getUnloadedNodes(this.props);

    // For the internet, are all the nodes loaded with private addresses?
    if (this.props.data.name === intl('Common.IPLists') || !nodes.length) {
      // Get the latest data from the props
      const type = pbUb.type;
      const source = this.props.data[type === 'pb' ? 'targets' : 'sources'].find(
        nextSource => nextSource.href === pbUb.source.href,
      );

      _.defer(() => {
        actionCreators.clickActionItem({
          type: 'pbUbAction',
          formData: {type, source},
        });
      });
    } else {
      // Get the full data
      this.getPrivateAddressData();
      this.setState({showAddresses: pbUb});
    }
  },

  selectPbUb(pbUbType, pbUb) {
    _.defer(() => {
      const {type, href, labels} = pbUb;

      actionCreators.selectPbUb({pbUbType, type, href, labels});
    });
  },

  updateService(service) {
    // Update Service Selection.
    this.selectService(service);

    // Update pB selection.
    _.defer(() => {
      const {anyIpList} = this.state;
      const availableTargets = _.filter(this.props.data.targets, target => {
        // Filter out anything missing a name or missing a label
        // Also, filter the 0.0.0.0/0 ipList, although we need it for the rules, we don't want to see it on the page.
        const isAnyIpList = target.type === 'ipList' && anyIpList && target.href === anyIpList.href;

        return (target.name && !isAnyIpList) || !_.isEmpty(target.labels);
      });

      if (!_.isEmpty(availableTargets)) {
        // If nothing else is selected, pick the first available item
        this.selectPbUb('pb', availableTargets[0]);
      }
    });
  },

  selectService(service) {
    if (!service) {
      return;
    }

    _.defer(() => {
      actionCreators.selectService({...service});
      // clear service name user may have added in add rule panel
      actionCreators.clearUserDefinedService();
    });
  },

  handleIpAddress(address) {
    if (!this.isUserReadOnly()) {
      this.transitionTo('workloadCreate', null, {address});
    }
  },

  async getPrivateAddressData() {
    let {loadedNodes} = this.state;
    const nodes = this.getUnloadedNodes(this.props);
    const requests = [];

    // get private addresses for connected workloads
    if (nodes.length) {
      nodes.forEach(node => {
        const nodeInfo = node.split('x');
        let nodeHref = [nodeInfo[0]];
        const trafficClass = nodeInfo[nodeInfo.length - 1];

        if (nodeHref[0].includes('workloads')) {
          const query = {workloads: JSON.stringify(nodeHref), include_private: true};

          actionCreators.clearTrafficOnNext(JSON.stringify(query));
          requests.push(GraphDataUtils.getTraffic({...query, traffic_class: trafficClass}, {type: 'rebuildStale'}));
        } else {
          // Remove the last item which is the traffic clall
          nodeInfo.splice(-1, 1).join(',');
          // combine the rest of the label ids
          nodeHref = nodeInfo.join('x');

          const query = {roles: true, role_keys: JSON.stringify([nodeHref]), include_private: true};

          actionCreators.clearTrafficOnNext(JSON.stringify(query));
          requests.push(GraphDataUtils.getTraffic({...query, traffic_class: trafficClass}, {type: 'rebuildStale'}));
        }

        if (loadedNodes) {
          loadedNodes.push(node);
        } else {
          loadedNodes = [node];
        }
      });
    }

    await Promise.all(requests);

    if (this.isPanelMounted) {
      this.setState({loadedNodes});
    }
  },

  updateSelections() {
    // If service, pb, or ub hasn't yet been set in CommandPanelStore,
    // then trigger the event to update it with the first element in each
    const {trafficSelection} = this.state;
    const selectedService = trafficSelection.service;
    const selectedPb = trafficSelection.pb;
    const selectedUb = trafficSelection.ub;

    if (
      !selectedService ||
      (this.props.data.services &&
        this.props.data.services.length &&
        !this.props.data.services.some(service => service.key === selectedService.key))
    ) {
      this.selectService(this.props.data.services[0]);
    }

    const {anyIpList} = this.state;
    const availableTargets = _.filter(this.props.data.targets, target => {
      // Filter out anything missing a name or missing a label
      // Also, filter the 0.0.0.0/0 ipList, although we need it for the rules, we don't want to see it on the page.
      const isAnyIpList =
        (target.type === 'ipList' || target.type === 'fqdn') && anyIpList && target.href === anyIpList.href;

      return (target.name && !isAnyIpList) || !_.isEmpty(target.labels);
    });

    if (!selectedPb && !_.isEmpty(availableTargets)) {
      // If nothing else is selected, pick the first available item
      this.selectPbUb('pb', availableTargets[0]);
    }

    const availableSources = _.filter(this.props.data.sources, source => {
      const isAnyIpList = source.type === 'ipList' && anyIpList && source.href === anyIpList.href;

      return (source.name && !isAnyIpList) || !_.isEmpty(source.labels);
    });

    if (!selectedUb && !_.isEmpty(availableSources)) {
      this.selectPbUb('ub', availableSources[0]);
    }
  },

  renderLabel(label) {
    return <Label type={label.key} icon={label.icon} text={label.value} />;
  },

  renderLabels(labels) {
    if (!labels) {
      return null;
    }

    const roleLabel = labels.role && Object.keys(labels.role).length ? this.renderLabel(labels.role) : null;
    const appLabel = labels.app && Object.keys(labels.app).length ? this.renderLabel(labels.app) : null;
    const envLabel = labels.env && Object.keys(labels.env).length ? this.renderLabel(labels.env) : null;
    const locLabel = labels.loc && Object.keys(labels.loc).length ? this.renderLabel(labels.loc) : null;

    return (
      <div className="PbUbLabels--Wrap">
        {roleLabel && <div>{roleLabel}</div>}
        {appLabel}
        {envLabel}
        {locLabel}
      </div>
    );
  },

  renderPbUb(type, sources, interapp) {
    if (!sources) {
      return;
    }

    // if there's more than 1 source, then filter out any with no name
    // but if there's only 1, then render it so that it says "N/A"
    sources = _.filter(sources, source => source.name || !_.isEmpty(source.labels));

    if (_.isEmpty(sources)) {
      return (
        <tr className="MapSubInfoPanel-Row">
          <td className="MapSubInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
            {intl('Common.NA')}
          </td>
        </tr>
      );
    }

    return _.map(sources, (source, index) => {
      const classNames = cx({
        'TrafficPanel': true,
        'MapSubInfoPanel-Row': true,
        'MapSubInfoPanel-Row--Wrap': true,
        'MapSubInfoPanel-Row--UnsetHover': true,
        'MapSubInfoPanel-Row--NoPointer':
          sources.length <= 2 && source.type !== 'ipList' && source.type !== 'fqdn' && source.type !== 'internet',
      });

      const rowHighlight = cx('MapSubInfoPanel-Row-Label', {
        'MapSubInfoPanel-Row--NoHover': sources.length <= 2,
        'MapSubInfoPanel-Row--Hover': sources.length > 2,
        'MapSubInfoPanel-Row--Selected': source.isSelected && sources.length > 1,
      });
      const clickPbUb = _.partial(this.handleSelectPbUb, {type, source});
      let clickAction = sources.length > 1 ? _.partial(this.selectPbUb, type, source) : clickPbUb;

      if (
        sources[0].type === 'label' ||
        sources[0].type === 'role' ||
        sources[0].type === 'workload' ||
        sources[0].type === 'group'
      ) {
        clickAction = _.noop;
      }

      let icon = source.type;

      if (!RenderUtils.isHrefFqdn(source.href) && source.href.includes('ip_list') && source.fqdnIpListMatch) {
        icon = 'domain';
      } else if (!RenderUtils.isHrefFqdn(source.href) && source.href.includes('ip_list')) {
        icon = 'ip-list';
      } else if (source.type === 'fqdn') {
        icon = null;
      }

      const showTrafficArrow = source.trafficPd && (source.type === 'ipList' || source.type === 'fqdn');
      const arrowIconName = RenderUtils.getPolicyArrowIconName(source.trafficPd);
      const policyDecisionClasses = RenderUtils.getPolicyDecisionClassNames(
        source.trafficPd,
        this.props.data.colorBlind,
      );
      const trafficArrowClasses = cx({
        [policyDecisionClasses]: true,
        'PolicyDecision-Arrow': true,
      });
      // display the internet/ipList/workload without role label
      const sourceLabels = {key: null, value: source.name, icon};
      let sourceContent = (
        <div className="TrafficPanel-ConsumersProviders-Item">
          {showTrafficArrow ? <Icon name={arrowIconName} customClass={trafficArrowClasses} size="large" /> : null}
          {icon ? this.renderLabel(sourceLabels) : <span className="MapSubInfoPanel-Row-Name">{source.name}</span>}
        </div>
      );

      if (source.labels) {
        // display pbub all labels for interApp Links, otherwise only display role label
        sourceContent =
          interapp &&
          (Object.values(source.labels).some(label => Object.keys(label).length) ||
            source.type === 'label' ||
            source.type === 'group')
            ? this.renderLabels(source.labels)
            : this.renderLabel(source.labels.role || sourceLabels);
      }

      const sourceRow = <div className="MapSubInfoPanel-Row--Wrap">{sourceContent}</div>;

      // Don't show the 0.0.0.0/0 list for ipList links
      if (
        (source.type === 'ipList' || source.type === 'fqdn') &&
        source.name &&
        source.name.includes(intl('IPLists.Any'))
      ) {
        return null;
      }

      return (
        <tr className={classNames} key={index} onClick={clickAction} data-tid="map-sub-info-panel-row">
          <td className={rowHighlight} data-tid="map-info-panel-row-value">
            {sourceRow}
          </td>
          {_.compact(source.addresses).length ? (
            <td className="MapSubInfoPanel-Row-Icon" onClick={clickPbUb}>
              <Icon styleClass="Chevron-Padding" size="xxlarge" name="next" />
            </td>
          ) : null}
        </tr>
      );
    });
  },

  renderServices() {
    if (this.spinner) {
      return (
        <tr>
          <td>
            <Spinner size="twenty" />
          </td>
        </tr>
      );
    }

    const target = this.props.data.targets[0];
    let exposureApplicable = true;

    if ((target && target.type === 'label') || (target && target.type === 'workload')) {
      exposureApplicable =
        !target.unmanaged &&
        target.policyState !== 'idle' &&
        target.policyState !== 'unmanaged' &&
        target.policyState !== 'mixed';
    }

    const services = RenderUtils.getSortedTrafficPanelServices(
      this.props.data.services,
      this.state.sort.key,
      this.state.sort.direction,
      this.state.vulnerabilityView,
    );

    return (
      services &&
      _.map(services.slice(0, MAX_SERVICES), (service, index) => {
        const trafficBarStyle = {width: service.trafficWeight};
        const clickService = _.partial(this.handleSelectService, service);
        const clickAction = this.props.data.services.length > 1 ? _.partial(this.updateService, service) : clickService;
        const serviceName = service.matchedServices.length ? (
          service.name
        ) : (
          <span className="MapSubInfoPanel-serviceName">{service.name}</span>
        );
        //No port number if the traffic is ICMP.
        const servicePortProtocol = ServiceUtils.getPort(service)
          ? `${service.port} ${service.friendlyProtocol}`
          : String(service.friendlyProtocol);

        let vulnerability;
        const {vulnerabilityView, exposedVulnerabilities} = this.state;

        if (vulnerabilityView) {
          const values = service.vulnerabilities && service.vulnerabilities.aggregatedValues;

          vulnerability =
            service.vulnerabilities && (!exposedVulnerabilities || (values && values.vulnerablePortExposure)) ? (
              <td className="MapSubInfoPanel-Row-TrafficVulnerability">
                {values && <Vulnerability vulnerability={{...values, exposureApplicable}} opacity />}
              </td>
            ) : (
              <td className="MapSubInfoPanel-Row-TrafficVulnerability" />
            );
        }

        const serviceClasses = cx({
          'MapSubInfoPanel-Row': true,
          'MapSubInfoPanel-Row--Selected': service.isSelected,
          'MapSubInfoPanel-Row--Hover': this.props.data.services.length > 1,
          'MapSubInfoPanel-Row--Selectable': this.props.data.services.length > 1 && !service.isSelected,
          'MapSubInfoPanel-Row--NoHover': this.props.data.services.length <= 1,
          'MapSubInfoPanel-Row--Vulnerability': vulnerabilityView,
        });
        const {colorBlind} = this.props.data;
        const {trafficPd} = service;
        const policyDecisionClasses = RenderUtils.getPolicyDecisionClassNames(trafficPd, colorBlind);
        const trafficBarClasses = cx({
          [policyDecisionClasses]: true,
          TrafficBar: true,
        });
        const trafficArrowClasses = cx({
          [policyDecisionClasses]: true,
          'PolicyDecision-Arrow': true,
        });

        const isUnicastTransmission = !service.connectionClass || service.connectionClass === 'U';
        const trafficTransmissionTypeClasses = cx({
          'TransmissionTypeIndicator': true,
          'TransmissionTypeIndicator--broadcast': service.connectionClass === 'B',
          'TransmissionTypeIndicator--multicast': service.connectionClass === 'M',
          'TransmissionTypeIndicator--unicast': isUnicastTransmission,
        });

        return (
          <tr className={serviceClasses} key={index} onClick={clickAction} data-tid="map-sub-info-panel-row">
            <td
              className="MapSubInfoPanel-Row-Label MapSubInfoPanel-Row-Width"
              data-tid="map-info-panel-row-value-service"
            >
              <Icon
                customClass={trafficArrowClasses}
                size="large"
                name={RenderUtils.getPolicyArrowIconName(trafficPd)}
              />
              <span className="MapSubInfoPanel-Row-Overflow servicesListItem-name">{serviceName}</span>
            </td>
            <td
              className="MapSubTrafficPanel-Row-Value servicesListItem-port"
              data-tid="map-info-panel-row-value-port-protocol"
            >
              <div>{servicePortProtocol}</div>
            </td>
            <td className="MapSubInfoPanel-Row-Transmission" data-tid="map-info-panel-row-value-service">
              <span className={trafficTransmissionTypeClasses}>
                {isUnicastTransmission ? '' : service.connectionClass}
              </span>
            </td>
            {vulnerabilityView ? (
              vulnerability
            ) : (
              <td className="MapSubInfoPanel-Row-TrafficBar" data-tid="map-info-panel-row-value-traffic">
                <div className={trafficBarClasses} style={trafficBarStyle} />
              </td>
            )}
            <td className="MapSubInfoPanel-Row-TrafficAction">{service.trafficAction}</td>
            <td onClick={clickService} data-tid="map-info-panel-row-view-service">
              <Icon styleClass="Chevron-Padding" size="xxlarge" name="next" />
            </td>
          </tr>
        );
      })
    );
  },

  render() {
    const {sources, targets, services, interapp, selectedService, serviceNum} = this.props.data;
    const serviceLabel = RenderUtils.getTrafficPanelServicesLabelText(services, serviceNum, MAX_SERVICES);
    const {vulnerabilityView} = this.state;
    const {key, direction} = this.state.sort;
    const sort = this.props.data.services.length > 1;
    const [nameSortUpIconClass, nameSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses('name', key, direction);
    const [portSortUpIconClass, portSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses('port', key, direction);
    const [trafficSortUpIconClass, trafficSortDownIconClass] = RenderUtils.getTrafficPanelSortClasses(
      'trafficWeight',
      key,
      direction,
    );

    const servicesTitle = (
      <tr className="MapSubInfoPanel-Row MapSubInfoPanel-Header" data-tid="map-sub-info-panel-row">
        <td className="MapSubInfoPanel-Row-Header-Title MapSubInfoPanel-Row-Width">
          <div className="MapSubInfoPanel-Row-Header-Title-Value servicesList-name" data-tid="map-info-panel-row-label">
            <span
              className="MapSubInfoPanel-Row-Header"
              onClick={() => this.handleSort('name')}
              data-tid="map-info-panel-row-header-label"
            >
              <span className="MapSubInfoPanel-Row-Overflow">{serviceLabel}</span>
              {sort ? (
                <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                  <Icon styleClass={nameSortUpIconClass} name="sort-up" size="medium" />
                  <Icon styleClass={nameSortDownIconClass} name="sort-down" size="medium" />
                </span>
              ) : null}
            </span>
          </div>
          <div
            className="MapSubInfoPanel-Row-Header-Value servicesList-port"
            data-tid="map-info-panel-row-value-service"
          >
            <span
              className="MapSubInfoPanel-Row-Header"
              onClick={() => this.handleSort('port')}
              data-tid="map-info-panel-row-header-port"
            >
              <span>{intl('Port.Port')}</span>
              {sort ? (
                <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                  <Icon styleClass={portSortUpIconClass} name="sort-up" size="medium" />
                  <Icon styleClass={portSortDownIconClass} name="sort-down" size="medium" />
                </span>
              ) : null}
            </span>
          </div>
          <div
            className="MapSubInfoPanel-Row-Header-Transmission MapSubInfoPanel-Row-Header-Value"
            data-tid="map-info-panel-row-value-transmission"
          >
            <span className="MapSubInfoPanel-Row-Header" data-tid="map-info-panel-row-header-transmission" />
          </div>
          <div className="MapSubInfoPanel-Row-Header-TrafficBar" data-tid="map-info-panel-row-value-service">
            <span
              className="MapSubInfoPanel-Row-Header"
              onClick={() => this.handleSort('trafficWeight')}
              data-tid="map-info-panel-row-header-value"
            >
              {vulnerabilityView ? <span>{intl('Common.Vulnerability')}</span> : <span>{intl('Common.Traffic')}</span>}
              {sort ? (
                <span className="MapSubInfoPanel-Sort" data-tid="map-sub-info-panel-row">
                  <Icon styleClass={trafficSortUpIconClass} name="sort-up" size="medium" />
                  <Icon styleClass={trafficSortDownIconClass} name="sort-down" size="medium" />
                </span>
              ) : null}
            </span>
          </div>
        </td>
      </tr>
    );

    const servicesRow = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder" data-tid="map-info-panel-entities-row">
        <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow">
          <div className="MapInfoPanel-Row--Scroll MapInfoPanel-SetBorder">
            <table className="MapSubInfoPanelWidth MapSubInfoPanel">
              <tbody>{this.renderServices()}</tbody>
            </table>
          </div>
        </td>
      </tr>
    );

    const pb = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder" data-tid="map-info-panel-row">
        <th className="MapInfoPanel MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          <div className="MapInfoPanel-Row-Header-WorkloadsList">
            <span>{intl('Common.Providers')}</span>
            <span className="MapInfoPanel-Row-EnforcementMode">
              {RenderUtils.getTrafficPanelEnforcementModeText(selectedService, 'providers')}
            </span>
          </div>
        </th>
        <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
          <div className="MapInfoPanel-Row--Scroll MapInfoPanel-SetBorder MapInfoPanel-RowValue--Margin">
            <table className="MapSubInfoPanel MapSubInfo-Panel--Align">
              <tbody>{this.renderPbUb('pb', targets, interapp)}</tbody>
            </table>
          </div>
        </td>
      </tr>
    );
    const ub = (
      <tr className="MapInfoPanel-Row MapInfoPanel-Row--SetBorder" data-tid="map-info-panel-row">
        <th className="MapInfoPanel MapInfoPanel-Row-Label" data-tid="map-info-panel-row-label">
          <div className="MapInfoPanel-Row-Header-WorkloadsList">
            <span>{intl('Common.Consumers')}</span>
            <span className="MapInfoPanel-Row-EnforcementMode">
              {RenderUtils.getTrafficPanelEnforcementModeText(selectedService, 'consumers')}
            </span>
          </div>
        </th>
        <td className="MapInfoPanel-Row-Value MapInfoPanel-Row-Value-Overflow" data-tid="map-info-panel-row-value">
          <div className="MapInfoPanel-Row--Scroll MapInfoPanel-SetBorder MapInfoPanel-RowValue--Margin">
            <table className="MapSubInfoPanel MapSubInfo-Panel--Align">
              <tbody>{this.renderPbUb('ub', sources, interapp)}</tbody>
            </table>
          </div>
        </td>
      </tr>
    );

    const servicesTableClassNames = cx({
      'MapInfoPanel': true,
      'TrafficPanel-Services': true,
      'TrafficPanel-Services--vulnerabilities-off': !this.state.vulnerabilityView,
    });

    return (
      <div>
        <table className={servicesTableClassNames}>
          <tbody>
            {servicesTitle}
            {servicesRow}
          </tbody>
        </table>
        <table className="MapInfoPanel TrafficPanel-ConsumersProviders">
          <tbody>
            {ub}
            {pb}
          </tbody>
        </table>
      </div>
    );
  },
});
