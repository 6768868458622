/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import React from 'react';
import cx from 'classnames';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import RenderUtils from '../../utils/RenderUtils';
import actionCreators from '../../actions/actionCreators';
import RoleVisualization from '../../utils/Role';
import GraphTransformStore from '../../stores/GraphTransformStore';

const gray = '#7f8c8d';

let dragged = false;

export default React.createClass({
  componentDidMount() {
    this.d3Wrapper = d3.select(findDOMNode(this));
    this.d3Wrapper
      .selectAll('.il-role-rect, .il-role-text-name')
      .on('click', GraphTransformStore.getInteractionType() === 'select' ? this.selectRole : null);

    this.d3Wrapper.select('.il-role-rect').on('mouseout', RoleVisualization.unhoverRole);

    if (GraphTransformStore.getInteractionType() === 'select' && this.props.whileDragRole && this.props.afterDragRole) {
      this.d3Wrapper.call(RoleVisualization.dragRole, this.whileDrag, this.afterDrag);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }

    this.d3Wrapper
      .datum(this.props.data)
      .call(_.bind(RoleVisualization.enter, RoleVisualization))
      .call(_.bind(RoleVisualization.update, RoleVisualization));
  },

  componentDidUpdate() {
    this.d3Wrapper
      .select('.il-role-rect')
      .on('click', GraphTransformStore.getInteractionType() === 'select' ? this.selectRole : null);
    this.d3Wrapper.datum(this.props.data).call(_.bind(RoleVisualization.update, RoleVisualization));

    if (GraphTransformStore.getInteractionType() === 'select' && this.props.whileDragRole && this.props.afterDragRole) {
      this.d3Wrapper.call(RoleVisualization.dragRole, this.whileDrag, this.afterDrag);
    } else {
      this.d3Wrapper.on('mousedown.drag', null);
    }
  },

  afterDrag() {
    if (!dragged || GraphTransformStore.getInteractionType() === 'move') {
      dragged ||= false;

      return;
    }

    // only call this function when something really dragged.
    const data = {
      type: this.props.data.type,
      href: this.props.data.href,
      labels: this.props.data.labels,
      x: Number(this.props.data.x.toFixed(2)), // Round to 2 decimal points so that
      y: Number(this.props.data.y.toFixed(2)), // we store a smaller number
    };

    this.props.afterDragRole(data);
  },

  selectRole() {
    // Suppress click if it's the end of a drag event
    if (d3.event.defaultPrevented) {
      return;
    }

    if (!this.props.data.identifier) {
      return;
    }

    const nodes = [
      {
        type: this.props.data.type,
        href: this.props.data.href,
        clusterHref: this.props.data.cluster ? this.props.data.cluster.href : null,
      },
    ];

    if (this.props.data.selected) {
      actionCreators.unselectComponent(nodes);
    } else {
      actionCreators.updateComponentSelection(nodes);
    }

    if (this.props.hoverNode) {
      this.props.hoverNode(this.props.data);
    }
  },

  handleHoverRole(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.hoverNode) {
      this.props.hoverNode(this.props.data);
    }

    if (!this.tooltip && !this.props.data.isLegend) {
      actionCreators.showMapTooltip({
        type: 'role',
        tooltipInfo: {...this.props.data},
        location: {x: evt.pageX, y: evt.pageY},
      });

      this.tooltip = true;
    }
  },

  handleUnhoverRole() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    if (this.props.unhoverNode && !this.props.data.selected) {
      this.props.unhoverNode(this.props.data);
    }

    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleContextMenu(evt) {
    evt.preventDefault();
    actionCreators.showMapMenu({
      type: 'role',
      data: {...this.props.data},
      location: {x: evt.pageX, y: evt.pageY},
    });

    /* Hide the tooltip on right click */
    this.tooltip = false;
    actionCreators.hideMapTooltip();
  },

  handleHoverText(evt) {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    const vulnerability = this.props.data.vulnerability;

    if (!this.tooltip && vulnerability && vulnerability.aggregatedValues.vulnerabilityExposureScore !== null) {
      actionCreators.showMapTooltip({
        type: 'vulnerability',
        tooltipInfo: {...vulnerability.aggregatedValues},
        location: {x: evt.pageX, y: evt.pageY},
      });

      this.tooltip = true;
    }
  },

  handleUnhoverText() {
    if (
      GraphTransformStore.getInteractionType() === 'move' ||
      GraphTransformStore.getInteractionType() === 'rightClick'
    ) {
      return;
    }

    actionCreators.hideMapTooltip();
    this.tooltip = false;
  },

  whileDrag(x, y) {
    if (GraphTransformStore.getInteractionType() === 'move') {
      return;
    }

    const role = update(this.props.data, {
      $merge: {x, y, drag: true},
    });

    dragged = true;
    this.props.whileDragRole(role, role);
  },

  render() {
    const {data} = this.props;
    const roleWorkloadsIsReadable = data.isLegend ? true : data.caps && data.caps.workloads.includes('read');
    const rolePolicyState = cx({
      'il-role-policyState':
        roleWorkloadsIsReadable && (data.policyState === 'enforced' || data.policyState === 'selective'),
      'il-role-policyState-enforced': roleWorkloadsIsReadable && data.policyState === 'enforced',
      'il-role-policyState-selective': roleWorkloadsIsReadable && data.policyState === 'selective',
    });

    const vulnerabilityExposureScoreClass = cx('il-role-vulnerability', {
      'None': data.vulnerabilitySeverity === 'none',
      'Vulnerability-Text--low': data.vulnerabilitySeverity === 'low',
      'Vulnerability-Text--medium': data.vulnerabilitySeverity === 'medium',
      'Vulnerability-Text--high': data.vulnerabilitySeverity === 'critical',
      'Vulnerability-Text--critical': data.vulnerabilitySeverity === 'high',
    });

    const vulnerabilitySyncingClass = cx('il-role-syncing', {
      'Vulnerability-Text--low': data.vulnerabilitySeverity === 'low',
      'Vulnerability-Text--medium': data.vulnerabilitySeverity === 'medium',
      'Vulnerability-Text--high': data.vulnerabilitySeverity === 'critical',
      'Vulnerability-Text--critical': data.vulnerabilitySeverity === 'high',
    });

    const roleNameClass = cx({
      'il-vulnerabilty-name': Boolean(data.vulnerability),
    });

    const roleFill = data.fill || gray;
    const truncateAt = 15 * GraphTransformStore.getTransform().scale;

    let vulnerabilityExposureScore;
    let vulnerabilityValues;
    let vulnerability;
    let name = data.name;

    if (name && name.length > truncateAt) {
      name = `${name.slice(0, truncateAt)}...`;
    }

    if (data.vulnerability) {
      const ves = data.vulnerability.aggregatedValues?.vulnerabilityExposureScore;

      vulnerabilityValues = data.vulnerability.aggregatedValues;
      vulnerabilityExposureScore = isNaN(ves) ? ves : `${RenderUtils.roundNumber(ves)} `;
      vulnerability = (
        <text className="il-role-text-vulnerability">
          <tspan className={vulnerabilityExposureScoreClass}>{vulnerabilityExposureScore}</tspan>
        </text>
      );

      if (ves === intl('Workloads.Status.Syncing')) {
        vulnerability = <text className={vulnerabilitySyncingClass} />;
      }
    }

    const dataTid = this.props.data.isLegend ? this.props['data-tid'] : data.policyState;

    return (
      <g
        className="il-role"
        onMouseEnter={this.handleHoverRole}
        onMouseLeave={this.handleUnhoverRole}
        onContextMenu={this.handleContextMenu}
        data-tid={`role-${data.name}`}
      >
        {Boolean(rolePolicyState) && <circle className={rolePolicyState} />}
        <circle className="il-role-rect" data-tid={dataTid} fill={roleFill} />
        <text className="il-role-workloadsNum">{data.entityCounts}</text>
        {vulnerabilityValues && !_.isEmpty(vulnerabilityValues.wideExposure) && <text className="il-role-internet" />}
        <text className="il-role-text-name">
          <tspan className={roleNameClass}>{name}</tspan>
        </text>
        {vulnerability}
      </g>
    );
  },
});
