/**
 * Copyright 2019 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {GraphDataUtils, GridDataUtils, RestApiUtils} from '.';
import actionCreators from '../actions/actionCreators';
import {GraphStore, TrafficStore, SessionStore} from '../stores';
import {getSessionUri, getInstanceUri, isAPIAvailable} from '../lib/api';
import {getId} from '../../../app/utils/href';

function getTabs(members) {
  const tabs = ['workloads'];

  if (members.virtualServices && members.virtualServices.length) {
    tabs.push('virtualServices');
  }

  if (members.virtualServers && members.virtualServers.length) {
    tabs.push('virtualServers');
  }

  if (members.containerWorkloads && members.containerWorkloads.length) {
    tabs.push('containerWorkloads');
  }

  if (members.pairingProfiles && members.pairingProfiles.length) {
    tabs.push('pairingProfiles');
  }

  return tabs;
}

function calculateMemberTabTo(appGroup) {
  if (!appGroup) {
    return 'appGroupWorkloads';
  }

  const {workloadCounts, containerWorkloadCounts, virtualServiceCounts} = appGroup;

  if (workloadCounts) {
    return 'appGroupWorkloads';
  }

  if (containerWorkloadCounts) {
    return 'appGroupContainerWorkloads';
  }

  if (virtualServiceCounts) {
    return 'appGroupVirtualServices';
  }

  return 'appGroupVirtualServers';
}

async function getGroup(currentGroup, groupHref, type, vulnerabilities, workloads) {
  if (!GraphDataUtils.isSummaryDataLoaded('requested')) {
    await Promise.all([
      GraphDataUtils.getTraffic('location', {route: 'groups'}),
      GraphDataUtils.getTraffic('appGroup', {route: 'groups'}),
    ]);
  }

  let group = TrafficStore.getNode(groupHref);

  if (groupHref.includes('discovery')) {
    // The discovery groups are built based on traffic, and are calculated in the graph store
    // This will not work if these pages are reloaded with out loading illumination first
    group = GraphStore.getCluster(groupHref);
  }

  if (!group) {
    return {};
  }

  const caps = group.caps;

  getMembers(group, type, vulnerabilities, workloads);

  return {group, caps};
}

function getGroupLabelHrefs(groupHref) {
  const groupLabelHrefs = groupHref.split('x').reduce((result, labelId) => {
    if (labelId !== 'discovered') {
      const label = getSessionUri(getInstanceUri('labels'), {label_id: labelId});

      result.push(label);
    }

    return result;
  }, []);

  let group = TrafficStore.getNode(groupHref);

  if (groupHref.includes('discovery')) {
    // The discovery groups are built based on traffic, and are calculated in the graph store
    // This will not work if these pages are reloaded with out loading illumination first
    group = GraphStore.getCluster(groupHref);
  }

  return {groupHref, groupLabelHrefs, group};
}

function calculateLabels(group, labels) {
  return JSON.stringify([
    Object.values(group.labels.reduce((result, label) => ({...result, [label.key]: label.href}), labels)),
  ]);
}

function getMembers(group, type, vulnerabilities, workloads) {
  // The full map will have 'discovered' set to true for discovery groups based on traffic
  // The leveled map will have 'discovered' as the href, and we will load all workloads without labels
  if (group && group.labels && !group.discovered) {
    const labels = calculateLabels(group, {});
    const appGroups = type === 'appgroups';
    const hrefPrefix = getSessionUri(getInstanceUri('labels'), {label_id: ''}).slice(0, -1);
    // Start with an empty set of labels
    let groupLabels = {
      app: `${hrefPrefix}?key=app&exists=false`,
      env: `${hrefPrefix}?key=env&exists=false`,
      loc: `${hrefPrefix}?key=loc&exists=false`,
    };

    // Replace with all the labels we have for this group
    groupLabels = calculateLabels(group, groupLabels);

    _.defer(() => {
      if (workloads) {
        RestApiUtils.workloads.getCollection(
          {
            labels: appGroups ? labels : groupLabels,
            representation: vulnerabilities ? 'workload_labels_services_vulnerabilities' : 'workload_labels_services',
            max_results: 500,
          },
          true,
        );
      }

      RestApiUtils.virtualServices.getCollection(
        'draft',
        {
          labels: appGroups ? labels : groupLabels,
          max_results: 500,
          representation: 'virtual_service_labels_services_and_workloads',
        },
        true,
      );
      RestApiUtils.containerWorkloads.getCollection({labels: appGroups ? labels : groupLabels, max_results: 500}, true);

      // Pairing Profiles does not support existes=false
      if (group?.caps?.workloads.includes('write')) {
        RestApiUtils.pairingProfiles.getCollection(
          {labels, representation: 'pairing_profile_labels'},
          true,
          appGroups ? false : group.labels,
        );
      }
    });
  } else if (group && group.discovered) {
    // Load workloads one by one for groups defined by their traffic
    _.defer(async () => {
      for (const workloadChunk of _.chunk(group.nodesHrefs, 5)) {
        await Promise.all(
          workloadChunk.map(href => {
            if (href.includes('virtual_server')) {
              return RestApiUtils.virtualServers.get(GridDataUtils.getIdFromHref(href), 'draft');
            }

            if (href.includes('virtual_service')) {
              return RestApiUtils.virtualServices.getInstance(GridDataUtils.getIdFromHref(href), 'draft');
            }

            if (href.includes('container')) {
              return RestApiUtils.containerWorkloads.getInstance(GridDataUtils.getIdFromHref(href));
            }

            return RestApiUtils.workloads.getInstance(GridDataUtils.getIdFromHref(href), {
              representation: 'workload_labels_services',
            });
          }),
        );
      }
    });
  }
}

function getMapRoute(group, groupHref, mapLevel, type) {
  // Do not route to the location map for scoped users
  if ((type !== 'appgroups' && SessionStore.isUserScoped()) || !group?.caps?.rulesets.includes('read')) {
    return null;
  }

  // Find Leveled map route for the reload case
  if (type === 'appgroups') {
    const history = JSON.parse(sessionStorage.getItem('MapPageStore'));

    // If a connected group was open for this group, use that route
    return history && history.appMapRouteName === 'prevAppMapLevel' && history.appMapRoute.previd === groupHref
      ? {
          route: 'prevAppMapLevel',
          params: history.appMapRoute,
        }
      : {
          route: 'appMapLevel',
          params: {
            type: 'focused',
            id: groupHref,
          },
        };
  }

  if (group && group.labels && group.labels.length && mapLevel !== 'full') {
    const locLabel = group.labels.find(label => label.key === 'loc');

    return locLabel
      ? {
          route: 'prevMapLevel',
          params: {
            prevtype: 'location',
            previd: locLabel.href.split('/').pop(),
            type: 'group',
            id: groupHref,
          },
        }
      : {route: 'map'};
  }

  return {route: 'map'};
}

function getType(path) {
  const type = path.split('/')[1].toLowerCase();

  return type === 'mapgroup' ? 'group' : type;
}

const descriptionMap = new Map([
  ['dhcp_v4_ingress', intl('EssentialServiceRules.Ingress.DHCPv4')],
  ['dhcp_v6_ingress', intl('EssentialServiceRules.Ingress.DHCPv6')],
  ['ipsec_ingress', intl('EssentialServiceRules.IPSec')],
  ['traceroute_ingress', intl('EssentialServiceRules.Ingress.Traceroute')],
  ['icmp_v6_ingress', intl('EssentialServiceRules.Ingress.ICMPv6')],
  ['dns_egress', intl('EssentialServiceRules.Egress.DNS')],
  ['dhcp_v4_egress', intl('EssentialServiceRules.Egress.DHCPv4')],
  ['dhcp_v6_egress', intl('EssentialServiceRules.Egress.DHCPv6')],
  ['ipsec_egress', intl('EssentialServiceRules.IPSec')],
  ['icmp_v6_egress', intl('EssentialServiceRules.Egress.ICMPv6')],
  ['traceroute_egress', intl('EssentialServiceRules.Egress.Traceroute')],
  ['pce_egress', intl('EssentialServiceRules.Egress.PCE')],
  ['pce_event_service_egress', intl('EssentialServiceRules.Egress.EventService')],
]);

const areIpsDifferent = (draftIps, activeIps) => {
  if (draftIps.length !== activeIps.length) {
    return true;
  }

  const ipHrefs = draftIps.reduce((set, {href}) => set.add(getId(href)), new Set());
  const oldIpHrefs = activeIps.reduce((set, {href}) => set.add(getId(href)), new Set());
  const union = new Set([...ipHrefs, ...oldIpHrefs]);

  return union.size !== ipHrefs.size;
};

const allWorkloads = [{actors: 'ams'}];
const consuming_security_principals = [];
const resolve_labels_as = {
  consumers: ['workloads'],
  providers: ['workloads'],
};

const formatEssentialServiceRule = (key, type, draftRule, activeRule) => {
  const rule = {
    description: descriptionMap.get(key),
    ingress_services: draftRule.ingress_services,
    consuming_security_principals,
    resolve_labels_as,
    href: key, // need this for RuleNote component - see usage of href in that file
  };

  // ip_lists is an array of objects, ips is an array of strings
  const ips = draftRule.ip_lists ?? draftRule.ips;

  rule[type === 'ingress' ? 'consumers' : 'providers'] = ips.map(ip => ({
    ip_list: typeof ip === 'string' ? {name: ip} : ip,
  }));

  rule[type === 'ingress' ? 'providers' : 'consumers'] = allWorkloads;

  if (draftRule.readonly) {
    return rule;
  }

  if (draftRule.enabled !== activeRule.enabled || areIpsDifferent(draftRule.ip_lists, activeRule.ip_lists)) {
    rule.update_type = 'update';
  }

  return rule;
};

// this is how RenderUtils.js, getScope function wants it formatted to show All-All-All for scopes
const scopes = [[]];

async function getEssentialServiceRules() {
  if (!isAPIAvailable('essential_service_rules.get')) {
    return [];
  }

  const [{body: draft}, {body: active}] = await Promise.all([
    RestApiUtils.essentialServiceRules.get('draft'),
    RestApiUtils.essentialServiceRules.get('active'),
  ]);

  const formattedRules = Object.keys(draft).reduce(
    (output, key) => {
      const type = draft[key]?.direction;

      if (type) {
        output[type === 'egress' ? 'outbound' : 'inbound'].rules.push(
          formatEssentialServiceRule(key, type, draft[key], active[key]),
        );
      } else {
        // metadata applicable to both rule objs
        output.inbound[key] = draft[key];
        output.outbound[key] = draft[key];
      }

      return output;
    },
    {
      inbound: {rules: [], name: intl('EssentialServiceRules.Inbound'), scopes},
      outbound: {rules: [], name: intl('EssentialServiceRules.Outbound'), scopes},
    },
  );

  actionCreators.setEssentialServiceRules([formattedRules.inbound, formattedRules.outbound]);
}

export default {
  calculateMemberTabTo,
  getGroup,
  getGroupLabelHrefs,
  getMembers,
  getTabs,
  getMapRoute,
  getType,
  getEssentialServiceRules,
};
