/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React, {PropTypes} from 'react';
import actionCreators from '../../actions/actionCreators';
import {
  AlertDialog,
  Button,
  Banner,
  ConfirmationDialog,
  Grid,
  NotificationGroup,
  Pagination,
  OSEntitySelect,
  Badge,
  RulesetAndScopesCollapseTitle,
} from '..';
import OSLabelSelect from '../OSLabelSelect.jsx';
import Constants from '../../constants';
import {GeneralStore} from '../../stores';
import {GridDataUtils, RestApiUtils, RulesetUtils, RuleValidationUtils, GeneralUtils, WorkloadUtils} from '../../utils';

const resourceType = 'rule_sets';

const sortScopeLabel = label => {
  let labelValue = intl('Common.All');

  if (label && label.value) {
    labelValue = label.value;
  } else if (label && label.name) {
    labelValue = label.name;
  }

  return GridDataUtils.sortStringValue(labelValue);
};

export default React.createClass({
  propTypes: {
    ruleset: PropTypes.object,
    rulesetId: PropTypes.string,
    version: PropTypes.string,
    readonly: PropTypes.bool,
    scopes: PropTypes.array,
    ruleLabelTypes: PropTypes.object,
    pageLength: PropTypes.number,
    collapsedByDefault: PropTypes.bool,
    setIgnoreChanges: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      ruleset: {},
      rulesetId: '',
      version: 'draft',
      readonly: false,
      scopes: [],
      ruleLabelTypes: {},
      pageLength: 250,
      collapsedByDefault: false,
    };
  },

  getInitialState() {
    let selection = GeneralStore.getSelection('rulesetScopeList');
    const sorting = GeneralStore.getSorting('rulesetScopeList');

    selection = selection && selection.id === this.props.rulesetId ? selection.selection : [];

    return {
      collapsed: this.props.collapsedByDefault,
      selection: selection || [],
      sorting: sorting || [],
      submitted: false,
      filters: null,
      page: 1,
      errors: [],
      rbacErrors: [],
      adding: null,
      editing: null,
    };
  },

  getErrors(scope, otherScopes, justSubmitted) {
    const errors = RuleValidationUtils.validateScope(
      scope.app,
      scope.env,
      scope.loc,
      otherScopes,
      this.props.ruleLabelTypes,
      justSubmitted || this.state.submitted,
    );

    if (
      !justSubmitted &&
      this.state.submitted &&
      errors.length &&
      errors[0] === Constants.REQUIRED_SCOPE_FIELDS &&
      (!this.state.errors.length || this.state.errors[0].key !== Constants.REQUIRED_SCOPE_FIELDS)
    ) {
      errors.shift();
    }

    return errors;
  },

  handleEditOpen(row) {
    this.checkPendingChanges().then(() => {
      const scope = _.cloneDeep(row);

      scope.app ||= {key: 'app', type: 'all', value: intl('Common.AllApplications')};

      scope.env ||= {key: 'env', type: 'all', value: intl('Common.AllEnvironments')};

      scope.loc ||= {key: 'loc', type: 'all', value: intl('Common.AllLocations')};

      this.props.setIgnoreChanges(false);
      this.setState({adding: null, editing: scope, submitted: false, rbacErrors: []});
    });
  },

  handleEditSave() {
    const editing = this.state.editing ? this.state.editing : {};
    const otherScopes = _.filter(this.props.scopes, scope => scope.id !== editing.id);
    const scopeErrors = this.getErrors(editing, otherScopes, true);

    if (scopeErrors && scopeErrors.length) {
      this.setState({submitted: true, errors: this.generateErrors(scopeErrors)});

      return;
    }

    const scopes = _.cloneDeep(this.props.scopes).filter(scope => !scope.deleted);
    const index = _.findIndex(scopes, scope => scope.id === this.state.editing.id);

    if (this.props.proposed) {
      scopes[index] = Object.values(this.state.editing)
        .filter(scope => scope && scope.href)
        .map(scope => ({[scope.href.includes('label_group') ? 'label_group' : 'label']: scope}));

      this.props.onScopeChange(scopes);
      this.props.setIgnoreChanges(true);
      this.setState({editing: null, submitted: false, errors: []});

      return;
    }

    scopes[index] = this.state.editing;

    RestApiUtils.ruleSet
      .update(this.props.rulesetId, {
        scopes: RulesetUtils.getScopesFromLabelKeys(scopes).map(scope => RulesetUtils.fixRulesetArr(scope)),
      })
      .catch(err => {
        const body = _.get(err, 'parsedBody[0]');

        if (err.status === 406 && body && body.token.includes('rbac')) {
          const rbacErrors = [
            {
              type: 'error',
              title: body.message,
            },
          ];

          this.setState({rbacErrors});
        }
      })
      .then(() => {
        RestApiUtils.secPolicies.dependencies();
      });

    if (!this.state.rbacErrors.length) {
      this.props.setIgnoreChanges(true);
      this.setState({editing: null, submitted: false, errors: []});
    }
  },

  handleAddCancel() {
    this.checkPendingChanges().then(() => {
      this.props.setIgnoreChanges(true);
      this.setState({adding: null, errors: [], rbacErrors: [], submitted: false});
    });
  },

  handleAddChange(added, removed) {
    const scope = this.state.adding && !_.isEmpty(this.state.adding) ? _.cloneDeep(this.state.adding) : {};

    if (removed) {
      delete scope[removed.key];
    }

    if (added) {
      scope[added.key] = added;
    }

    this.setState({
      adding: scope,
      rbacErrors: [],
      errors: this.generateErrors(this.getErrors(scope, this.props.scopes, false)),
    });
  },

  handleAddOpen() {
    if (this.state.collapsed) {
      this.setState({collapsed: false});
    }

    this.checkPendingChanges().then(() => {
      this.props.setIgnoreChanges(false);
      this.setState({adding: {}, editing: null, errors: [], rbacErrors: [], submitted: false, filters: null});
    });
  },

  handleAddSave() {
    const adding = this.state.adding && !_.isEmpty(this.state.adding) ? this.state.adding : {};
    const scopeErrors = this.getErrors(adding, this.props.scopes, true);

    if (scopeErrors && scopeErrors.length) {
      this.setState({submitted: true, errors: this.generateErrors(scopeErrors)});

      return;
    }

    const scopes = _.cloneDeep(this.props.ruleset.scopes);
    const addedScope = RulesetUtils.getScopesFromLabelKeys([adding])[0];

    scopes.unshift(addedScope);
    RestApiUtils.ruleSet
      .update(this.props.rulesetId, {
        scopes: scopes.map(scope => RulesetUtils.fixRulesetArr(scope)),
      })
      .catch(err => {
        const body = _.get(err, 'parsedBody[0]');

        if (err.status === 406 && body && body.token.includes('rbac')) {
          const rbacErrors = [
            {
              type: 'error',
              title: body.message,
            },
          ];

          this.setState({rbacErrors});
        }
      })
      .then(() => {
        RestApiUtils.secPolicies.dependencies();
        actionCreators.updateGeneralSelection('rulesetScopeList', []);

        if (!this.state.rbacErrors.length) {
          this.props.setIgnoreChanges(true);
          this.setState({adding: null, selection: [], submitted: false, errors: []});
        }
      });
  },

  handleCollapse() {
    this.checkPendingChanges().then(() => {
      this.setState({collapsed: !this.state.collapsed});
    });
  },

  handleEditCancel() {
    this.checkPendingChanges().then(() => {
      this.props.setIgnoreChanges(true);
      this.setState({editing: null, errors: [], rbacErrors: [], submitted: false});
    });
  },

  handleEditChange(added, removed) {
    const scope = this.state.editing ? _.cloneDeep(this.state.editing) : {};

    if (removed) {
      delete scope[removed.key];
    }

    if (added) {
      scope[added.key] = added;
    }

    const otherScopes = _.filter(this.props.scopes, otherScope => otherScope.id !== scope.id);

    this.setState({
      editing: scope,
      rbacErrors: [],
      errors: this.generateErrors(this.getErrors(scope, otherScopes, false)),
    });
  },

  handleErrorsChange(errors) {
    this.setState({errors});
  },

  handleFilterCancel() {
    this.setState({filters: null});
  },

  handleFilterChange(filters) {
    this.checkPendingChanges('edit').then(() => {
      this.setState({filters, page: 1});
    });
  },

  handleFilterOpen() {
    if (this.state.collapsed) {
      this.setState({collapsed: false});
    }

    this.checkPendingChanges('add').then(() => {
      this.setState({filters: {}, adding: null, errors: [], rbacErrors: [], submitted: false});
    });
  },

  handlePageChange(page) {
    this.checkPendingChanges('edit').then(() => {
      this.setState({page});
    });
  },

  handleRbacErrorsChange(rbacErrors) {
    this.setState({rbacErrors});
  },

  handleRemove() {
    const messages = [];

    if (this.state.selection.length === _.filter(this.props.scopes, scope => !scope.deleted).length) {
      actionCreators.openDialog(<AlertDialog message={intl('Rulesets.Rules.RemoveAllScopes')} />);

      return;
    }

    this.checkPendingChanges('scope').then(() => {
      messages.push(intl('Rulesets.Rules.ConfirmRemoveScopes'));
      actionCreators.openDialog(
        <ConfirmationDialog
          title={intl('Rulesets.Rules.DeleteScopes')}
          message={messages}
          onConfirm={this.handleRemoveConfirm}
        />,
      );
    });
  },

  handleRemoveConfirm() {
    const remainingScopes = this.props.scopes.filter(
      scope => !scope.deleted && !this.state.selection.includes(scope.id),
    );

    RestApiUtils.ruleSet
      .update(this.props.rulesetId, {
        scopes: RulesetUtils.getScopesFromLabelKeys(remainingScopes),
      })
      .catch(err => {
        const body = _.get(err, 'parsedBody[0]');

        if (err.status === 406 && body && body.token.includes('rbac')) {
          const rbacErrors = [
            {
              type: 'error',
              title: body.message,
            },
          ];

          this.setState({rbacErrors});
        }
      })
      .then(() => {
        RestApiUtils.secPolicies.dependencies();
        actionCreators.updateGeneralSelection('rulesetScopeList', []);
        this.setState({selection: [], page: 1});
      });
  },

  handleRowSelect(selection, lastSelected = selection) {
    const newSelection = GridDataUtils.selectToggle(this.state.selection, selection);

    actionCreators.updateGeneralSelection('rulesetScopeList', {id: this.props.rulesetId, selection: newSelection});
    this.setState({selection: newSelection, lastSelected, rbacErrors: []});
  },

  handleSort(key, direction) {
    this.checkPendingChanges('edit').then(() => {
      const sorting = [];

      if (key) {
        sorting.push({
          key,
          direction,
        });
      }

      actionCreators.updateGeneralSorting('rulesetScopeList', sorting);
      this.setState({sorting});
    });
  },

  checkPendingChanges(type) {
    let newState = null;

    switch (type) {
      case 'add':
        if (!_.isEmpty(this.state.adding)) {
          newState = {adding: null};
        }

        break;
      case 'edit':
        if (this.state.editing) {
          newState = {editing: null};
        }

        break;
      default:
        if (!_.isEmpty(this.state.adding) || this.state.editing) {
          newState = {adding: null, editing: null};
        }

        break;
    }

    if (newState) {
      return new Promise(resolve => {
        const confirm = () => {
          this.setState(newState, () => {
            resolve();
          });
        };

        actionCreators.openDialog(
          <ConfirmationDialog message={intl('Rulesets.ActionDiscardPendingChanges')} onConfirm={confirm} />,
        );
      });
    }

    return Promise.resolve();
  },

  generateErrors(errors) {
    return _.map(errors.length > 1 ? errors.slice(0, 1) : errors, error => ({
      type: 'error',
      title: RuleValidationUtils.getValidationMessage(error),
      closeable: true,
      key: error,
    }));
  },

  render() {
    const {scopes, proposed} = this.props;
    const readonly = this.props.readonly || this.props.version !== 'draft';
    const {adding, editing} = this.state;
    const selectedLabels = adding
      ? WorkloadUtils.getSelectedLabels(adding)
      : editing
      ? WorkloadUtils.getSelectedLabels(editing)
      : [];
    const scopeColumns = [
      {
        key: 'status',
        label: intl('Common.Status'),
        style: 'tag',
        sortable: true,
        format: (value, row) => {
          let type;
          let text;

          if (row.created) {
            if (this.props.version === 'draft') {
              text = intl('Rulesets.Rules.AdditionPending');
            } else {
              text = intl('Rulesets.Rules.Added');
            }

            type = 'created';
          } else if (row.deleted) {
            if (this.props.version === 'draft') {
              text = intl('Rulesets.Rules.DeletionPending');
            } else {
              text = intl('Rulesets.Rules.Deleted');
            }

            type = 'deleted';
          }

          if (type && text) {
            return (
              <Badge key={type} type={type}>
                {text}
              </Badge>
            );
          }

          return null;
        },
        sortValue: (value, row) => {
          if (row.created) {
            return 'created';
          }

          if (row.deleted) {
            return 'deleted';
          }

          return '';
        },
      },
      {
        key: 'app',
        label: intl('Common.Application'),
        style: 'scope',
        sortable: true,
        addComponent: (
          <OSLabelSelect
            allowGroup={true}
            ref="appSelect"
            placeholder={intl('Rulesets.Rules.SelectApplication')}
            tid="applabel"
            onChange={this.handleAddChange}
            selected={this.state.adding && this.state.adding.app ? [this.state.adding.app] : null}
            type="app"
            all={true}
            allowCreate={!this.props.readonly}
            defaultSelected={true}
            allowMultiSelect={false}
            autoFocus={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        editComponent: (
          <OSLabelSelect
            allowGroup={true}
            placeholder={intl('Rulesets.Rules.SelectApplication')}
            tid="applabel"
            onChange={this.handleEditChange}
            selected={this.state.editing && this.state.editing.app ? [this.state.editing.app] : [{}]}
            type="app"
            all={true}
            allowCreate={true}
            allowMultiSelect={false}
            defaultSelected={true}
            autoFocus={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        format: label => RulesetUtils.formatScopeLabel('app', label),
        sortValue: sortScopeLabel,
      },
      {
        key: 'env',
        label: intl('Common.Environment'),
        sortable: true,
        style: 'scope',
        addComponent: (
          <OSLabelSelect
            allowGroup={true}
            placeholder={intl('Rulesets.Rules.SelectEnvironment')}
            tid="envlabel"
            onChange={this.handleAddChange}
            selected={this.state.adding && this.state.adding.env ? [this.state.adding.env] : null}
            type="env"
            all={true}
            allowCreate={!this.props.readonly}
            allowMultiSelect={false}
            defaultSelected={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        editComponent: (
          <OSLabelSelect
            allowGroup={true}
            placeholder={intl('Rulesets.Rules.SelectEnvironment')}
            tid="envlabel"
            onChange={this.handleEditChange}
            selected={this.state.editing && this.state.editing.env ? [this.state.editing.env] : null}
            type="env"
            all={true}
            allowCreate={true}
            allowMultiSelect={false}
            defaultSelected={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        format: label => RulesetUtils.formatScopeLabel('env', label),
        sortValue: sortScopeLabel,
      },
      {
        key: 'loc',
        label: intl('Common.Location'),
        sortable: true,
        style: 'scope',
        addComponent: (
          <OSLabelSelect
            allowGroup={true}
            placeholder={intl('Rulesets.Rules.SelectLocation')}
            tid="loclabel"
            onChange={this.handleAddChange}
            selected={this.state.adding && this.state.adding.loc ? [this.state.adding.loc] : null}
            type="loc"
            all={true}
            allowCreate={!this.props.readonly}
            allowMultiSelect={false}
            defaultSelected={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        editComponent: (
          <OSLabelSelect
            allowGroup={true}
            placeholder={intl('Rulesets.Rules.SelectLocation')}
            tid="loclabel"
            onChange={this.handleEditChange}
            selected={this.state.editing && this.state.editing.loc ? [this.state.editing.loc] : null}
            type="loc"
            all={true}
            allowCreate={true}
            allowMultiSelect={false}
            defaultSelected={true}
            resourceType={resourceType}
            selectedLabels={selectedLabels}
          />
        ),
        format: label => RulesetUtils.formatScopeLabel('loc', label),
        sortValue: sortScopeLabel,
      },
    ];

    if (!readonly) {
      const disableSave =
        editing && (Boolean(this.state.errors.length) || ['app', 'env', 'loc'].some(type => !editing[type]));

      scopeColumns.push({
        key: 'actions',
        style: 'actions',
        addComponent: () => [
          <Button
            icon="save"
            content="icon-only"
            disabled={disableSave}
            onClick={this.handleAddSave}
            tid="add-save"
            key="save"
            customClass="Button--save"
          />,
          <Button
            icon="cancel"
            type="secondary"
            content="icon-only"
            onClick={this.handleAddCancel}
            tid="add-cancel"
            key="cancel"
          />,
        ],
        editComponent: () => [
          <Button
            icon="save"
            content="icon-only"
            disabled={disableSave}
            onClick={this.handleEditSave}
            tid="edit-save"
            key="save"
            customClass="Button--save"
          />,
          <Button
            icon="cancel"
            type="secondary"
            content="icon-only"
            onClick={this.handleEditCancel}
            tid="edit-cancel"
            key="cancel"
          />,
        ],
        format: (value, row) =>
          row.deleted ? null : (
            <Button
              icon="edit"
              type="nofill"
              content="icon-only"
              onClick={() => {
                this.handleEditOpen(row);
              }}
              tid="edit"
            />
          ),
      });
    }

    let filteredScopes = scopes;
    let filterHrefs = [];

    if (!_.isEmpty(this.state.filters)) {
      filterHrefs = GeneralUtils.deepPluck(this.state.filters, 'href');
      filteredScopes = [];

      scopes.forEach(scope => {
        const scopeKeys = Object.keys(scope);
        const allAppsMatched = this.state.filters[intl('Common.AllApplications')] ? !scopeKeys.includes('app') : true;
        const allEnvsMatched = this.state.filters[intl('Common.AllEnvironments')] ? !scopeKeys.includes('env') : true;
        const allLocsMatched = this.state.filters[intl('Common.AllLocations')] ? !scopeKeys.includes('loc') : true;
        let labelsMatched = true;

        if (filterHrefs.length) {
          const scopeHrefs = GeneralUtils.deepPluck(scope, 'href');

          labelsMatched = filterHrefs.every(href => scopeHrefs.includes(href));
        }

        if (allAppsMatched && allEnvsMatched && allLocsMatched && labelsMatched) {
          filteredScopes.push(scope);
        }
      });
    }

    const emptyScopes = (
      <Banner type="notice" header={intl(scopes ? 'Rulesets.Rules.Header.NoMatchingScopes' : 'Common.NoData')} />
    );
    const scopeDeleteDisabled = readonly || this.state.selection.length === 0;
    const isFiltered = Boolean(
      filterHrefs.length ||
        (this.state.filters &&
          [intl('Common.AllApplications'), intl('Common.AllEnvironments'), intl('Common.AllLocations')].some(
            value => this.state.filters[value],
          )),
    );

    // UI doesn't support a "role" label in a Ruleset Scope (API does)
    const roleLabelInScope = this.props.scopes.some(scope => scope.role);

    return (
      <div className="RulesetScopes" data-tid="page-rulesetrules-scopes">
        {roleLabelInScope && (
          <NotificationGroup notifications={[{type: 'warning', title: intl('Rulesets.RoleLabelNotSupported')}]} />
        )}
        <RulesetAndScopesCollapseTitle
          title={intl('Common.Scopes')}
          collapsed={this.state.collapsed}
          reorderMode={this.props.reorderMode}
          onClick={this.handleCollapse}
          type="scopes"
          tid="scopes"
          actionButtons={
            <div className="RulesetScopes-CollapseTitle-Actions">
              {!readonly && !proposed ? (
                <Button
                  content="icon-only"
                  icon="add"
                  type="secondary"
                  onClick={this.handleAddOpen}
                  tid="add"
                  disabled={this.state.adding !== null}
                />
              ) : null}
              {!readonly && !proposed ? (
                <Button
                  content="icon-only"
                  icon="remove"
                  type="secondary"
                  disabled={scopeDeleteDisabled}
                  onClick={this.handleRemove}
                  tid="remove"
                />
              ) : null}
              <Button
                content="icon-only"
                type="secondary"
                icon="filter"
                onClick={this.handleFilterOpen}
                tid="filter"
                disabled={this.state.filters !== null}
                customClass={this.state.filters === null ? null : 'Button--active'}
              />
            </div>
          }
          pagination={
            filteredScopes.length > 250 ? (
              <Pagination
                totalRows={filteredScopes.length}
                pageLength={this.props.pageLength}
                onPageChange={this.handlePageChange}
                page={this.state.page}
                count={
                  isFiltered
                    ? {
                        matched: filteredScopes.length,
                        total: scopes.length,
                      }
                    : null
                }
                isFiltered={isFiltered}
              />
            ) : null
          }
        >
          {this.state.filters === null ? null : (
            <div className="RulesetScopes-filter" data-tid="page-rulesetscopes-filter">
              <OSEntitySelect
                ref="filterScopes"
                selected={this.state.filters}
                onChange={this.handleFilterChange}
                version={this.props.version}
                hideLabel="role"
                placeholder={intl('Rulesets.FilterScopes')}
                autoFocus={true}
                type="scopesFilter"
              />
              <Button
                icon="cancel"
                type="secondary"
                content="icon-only"
                onClick={() => {
                  this.handleFilterCancel();
                }}
                tid="filter-cancel"
              />
            </div>
          )}
          <div className="RulesetScopes-list">
            {this.state.rbacErrors ? (
              <div className="RulesetScopes-notifications">
                <NotificationGroup notifications={this.state.rbacErrors} onChange={this.handleRbacErrorsChange} />
              </div>
            ) : null}
            {this.state.adding ? (
              <div className="RulesetScopes-notifications">
                <NotificationGroup notifications={this.state.errors} onChange={this.handleErrorsChange} />
              </div>
            ) : null}
            <Grid
              columns={scopeColumns}
              data={filteredScopes}
              editable={!readonly}
              selectable={!readonly && !proposed}
              selection={this.state.selection}
              sorting={this.state.sorting}
              sortable={true}
              onSort={this.handleSort}
              onRowSelectToggle={this.handleRowSelect}
              allowShiftSelect={true}
              lastSelected={this.state.lastSelected}
              rowClass={GridDataUtils.formatStatusRowClass}
              rowSelectable={row => !row.deleted}
              idField="id"
              emptyContent={emptyScopes}
              renderAddBar={!readonly && this.state.adding !== null}
              editingId={this.state.editing && !readonly ? this.state.editing.id : null}
              editingNotifications={
                this.state.editing && this.state.errors.length ? (
                  <NotificationGroup notifications={this.state.errors} onChange={this.handleErrorsChange} />
                ) : null
              }
              resultsPerPage={this.props.pageLength}
              currentPage={this.state.page}
            />
          </div>
        </RulesetAndScopesCollapseTitle>
      </div>
    );
  },
});
