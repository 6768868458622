/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';
import SessionStore from '../stores/SessionStore';

const linkToPatents = 'https://www.illumio.com/patents';
const linkToTerms = 'https://www.illumio.com/eula';
const linkToPrivacy = 'https://www.illumio.com/privacy-policy';

export default React.createClass({
  getInitialState() {
    const session = SessionStore.getSession();
    let ASPVersion = intl('Common.NA');

    if (session) {
      ASPVersion = `${process.env.UI_VERSION.match(/^(\d+\.\d+\.\d+).*/)[1]}-${session.product_version.build || '0'}`;
    }

    return {
      ASPVersion,
    };
  },

  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        size: 'medium',
        type: 'primary',
        onClick: () => {
          this.handleClose();
        },
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    return (
      <Dialog ref="dialog" type="detail" className="AspVersion" actions={this.getActions()}>
        <div className="logoHeader">
          <img alt={intl('Illumio.Logo')} width="120px" src="images/logo-color.svg" data-tid="illumio-logo" />
        </div>
        <div>
          <div data-tid="asp-version" className="versionDialog">
            {intl('VersionMismatch.PCE')}
            {SessionStore.isEdge() ? `: ${intl('Edge.ProductName')}` : ''} {this.state.ASPVersion}
          </div>
          <div data-tid="ui-version" className="versionDialog">
            {intl('VersionMismatch.UI')}
            {SessionStore.isEdge() ? `: ${intl('Edge.ProductName')}` : ''} {process.env.UI_VERSION}
          </div>
          <div className="About-element About-patent">
            {intl(
              'Illumio.Patent',
              {
                link: (
                  <a className="ASPVersion-Link" href={linkToPatents} target="_blank" data-tid="elem-link">
                    {linkToPatents}
                  </a>
                ),
              },
              {jsx: true},
            )}
          </div>
          <div className="About-element">
            {intl(
              'Illumio.Terms',
              {
                link: (
                  <div className="ASPVersion-Link">
                    <a href={linkToTerms} target="_blank" data-tid="elem-link">
                      {linkToTerms}
                    </a>
                  </div>
                ),
              },
              {jsx: true},
            )}
          </div>
          <div className="About-element">
            {intl(
              'Illumio.Privacy',
              {
                link: (
                  <div className="ASPVersion-Link">
                    <a href={linkToPrivacy} target="_blank" data-tid="elem-link">
                      {linkToPrivacy}
                    </a>
                  </div>
                ),
              },
              {jsx: true},
            )}
          </div>
          <div className="About-element">{intl('Illumio.Copyright')}</div>
        </div>
      </Dialog>
    );
  },
});
