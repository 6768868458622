/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import cx from 'classnames';
import intl from 'intl';
import {findDOMNode} from 'react-dom';
import update from 'react-addons-update';
import Icon from '../../components/Icon.jsx';
import actionCreators from '../../actions/actionCreators';
import NodeComponent from '../../components/graph/Node.jsx';
import RoleComponent from '../../components/graph/Role.jsx';
import LinkComponent from '../../components/graph/Link.jsx';
import ClusterComponent from '../../components/graph/Cluster.jsx';
import {UserStore} from '../../stores';
import MapPageStore from '../../stores/MapPageStore';

const blue = '#BDC3C7';
const green = '#81CB47';
const orange = '#F17D00';
const red = '#E80000';
const crimson = '#9F1009';
const infoblue = '#007cc5';

export default React.createClass({
  getInitialState() {
    return {
      hidden: true,
    };
  },

  handleHideLegend(evt) {
    const relatedTarget = evt.relatedTarget || evt.nativeEvent.explicitOriginalTarget || document.activeElement;

    if (findDOMNode(this).contains(relatedTarget)) {
      findDOMNode(this).focus();

      return;
    }

    this.setState({hidden: true});
  },

  toggleLegend() {
    const hidden = !this.state.hidden;

    if (!hidden) {
      actionCreators.setTrafficFilterHidden(true);
    }

    this.setState({hidden});

    // if we're supposed to show the panel, then also focus in
    if (!hidden) {
      findDOMNode(this).focus();
    }
  },

  renderTrafficSection() {
    const vulnerabilityMap = MapPageStore.getAppMapVersion() === 'vulnerability';
    const greenThreshold = localStorage.getItem('green') || 100;
    const svgStyle = {width: 94, height: 30};
    const notRuleReady = {
      source: {x: 7, y: 32},
      target: {x: 110, y: 40},
      type: 'gray',
      isLegend: true,
      colorBlind: 'normal',
      method: 'legend',
    };

    const ruleNotAllow = update(notRuleReady, {
      $merge: {type: 'red', colorBlind: UserStore.getColorBlindMode()},
    });
    const ruleAllow = update(notRuleReady, {
      $merge: {type: 'green', colorBlind: UserStore.getColorBlindMode()},
    });
    const potentiallyBlocked = update(notRuleReady, {
      $merge: {type: 'orange'},
    });
    const rulePartial = update(notRuleReady, {
      $merge: {type: 'lightGreen', colorBlind: UserStore.getColorBlindMode()},
    });
    const ruleLoading = update(notRuleReady, {
      $merge: {type: 'unknown'},
    });
    const discovered = update(notRuleReady, {
      $merge: {type: 'discovered'},
    });

    const legendPanelTrafficsClass = cx({
      'LegendPanel-Body-Row LegendPanel-Workloads--column': true,
      'LegendPanel-Traffics': MapPageStore.getMapType() === 'app' || MapPageStore.getMapType() === 'loc',
      'LegendPanel-Traffic-Padding': vulnerabilityMap,
    });

    const trafficPanelClass = cx({
      'LegendPanel-Body-Column': true,
      'LegendPanel-Traffics': true,
      'LegendPanel-Traffic-Padding': vulnerabilityMap,
    });

    const gray = update(notRuleReady, {
      $merge: {type: 'gray'},
    });
    const vulnerability = update(notRuleReady, {
      $merge: {type: 'vulnerability'},
    });
    const potentiallyVulnerable = update(notRuleReady, {
      $merge: {type: 'potentiallyVulnerable'},
    });

    return (
      <div className="LegendPanel-vulnerability">
        {vulnerabilityMap ? (
          <div className={trafficPanelClass}>
            <h4 className="LegendPanel-body-title LegendPanel-body-title--column">{intl('Map.TrafficLinks')}</h4>
            <div className="LegendPanel-Body--TrafficLinksTable">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <svg data-tid="TrafficLinks" style={svgStyle}>
                        <LinkComponent data-tid="vulnerability" data={vulnerability} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.VulnerableTraffic')}</td>
                  </tr>
                  <tr>
                    <td>
                      <svg data-tid="TrafficLinks" style={svgStyle}>
                        <LinkComponent data-tid="potentially-vulnerable" data={potentiallyVulnerable} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">
                      {intl('Map.LegendPanel.PotentiallyBlockedVulnerableTraffic')}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <svg data-tid="TrafficLinks" style={svgStyle}>
                        <LinkComponent data-tid="grey" data={gray} />
                      </svg>
                    </td>
                    <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.BlockedOrNotVulnerabile')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className={legendPanelTrafficsClass}>
            <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
              <h4 className="LegendPanel-body-title LegendPanel-body-title--column">{intl('Map.TrafficLinks')}</h4>
              <div className="LegendPanel-Body--TrafficLinksTable">
                <table>
                  <tbody>
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="blocked" data={ruleNotAllow} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Common.Blocked')}</td>
                    </tr>
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="potentially-blocked" data={potentiallyBlocked} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Common.PotentiallyBlocked')}</td>
                    </tr>
                    {greenThreshold < 100 ? (
                      <tr className="LegendPanel-Body-Tr-Traffic">
                        <td>
                          <svg data-tid="TrafficLinks" style={svgStyle}>
                            <LinkComponent data-tid="rule-partial" data={rulePartial} />
                          </svg>
                        </td>
                        <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.PartiallyAllowed')}</td>
                      </tr>
                    ) : null}
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="rule-allow" data={ruleAllow} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Common.Allowed')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="LegendPanel-Body-Column LegendPanel-VS-Padding">
              <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
                <div className="LegendPanel-body-title-placeholder">""</div>
              </h4>
              <div className="LegendPanel-Body--TrafficLinksTable">
                <table>
                  <tbody>
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="rule-loading" data={ruleLoading} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.LoadingData')}</td>
                    </tr>
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="not-rule-ready" data={notRuleReady} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.UnknownRuleStatus')}</td>
                    </tr>
                    <tr className="LegendPanel-Body-Tr-Traffic">
                      <td>
                        <svg data-tid="TrafficLinks" style={svgStyle}>
                          <LinkComponent data-tid="discovered" data={discovered} />
                        </svg>
                      </td>
                      <td className="LegendPanel-trafficslabel">{intl('Map.LegendPanel.Discovered')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
  renderVulnerabilitySection() {
    const svgVulnerabilityStyle = {width: 34, height: 30};
    const buildingWorkload = {x: 16, y: 16, fill: blue, isLegend: true};
    const VulnerabilityLow = {x: 16, y: 16, fill: green, isLegend: true};
    const VulnerabilityInfo = {x: 16, y: 16, fill: infoblue, isLegend: true};
    const VulnerabilityMedium = {x: 16, y: 16, fill: orange, isLegend: true};
    const VulnerabilityHigh = {x: 16, y: 16, fill: red, isLegend: true};
    const VulnerabilityCritical = {x: 16, y: 16, fill: crimson, isLegend: true};

    const legendPanelTrafficsClass = cx({
      'LegendPanel-Body-Row': true,
      'LegendPanel-Traffics': true,
    });

    return (
      <div className={legendPanelTrafficsClass}>
        <div className="LegendPanel-Vul-Padding LegendPanel-Body-Column LegendPanel-Body-halfColumn-fix">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-groups">{intl('Vulnerability.MaxSeverity')}</div>
          </h4>
          <table>
            <tbody>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="vulnerability-critical" data={VulnerabilityCritical} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.Critical')}</td>
              </tr>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="vulnerability-medium" data={VulnerabilityMedium} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.Medium')}</td>
              </tr>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="vulnerability-high" data={VulnerabilityHigh} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.High')}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column LegendPanel-Body-halfColumn-fix">
          <div className="LegendPanel-body-title-placeholder">""</div>
          <table>
            <tbody>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="vulnerability-low" data={VulnerabilityLow} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.Low')}</td>
              </tr>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="vulnerability-info" data={VulnerabilityInfo} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.Info')}</td>
              </tr>
              <tr>
                <td className="LegendPanel-td-vulnerability">
                  <svg data-tid="Vulnerability" style={svgVulnerabilityStyle}>
                    <NodeComponent data-tid="building-workload" data={buildingWorkload} />
                  </svg>
                </td>
                <td className="LegendPanel-workloadslabel">{intl('Common.None')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  },

  renderPolicyDecisionsSection() {
    const blockedPolicyClasses = cx({
      'PolicyDecision-Arrow': true,
      'TrafficBlocked': true,
      'ColorDeficiency': UserStore.getColorBlindMode() === 'deficient',
    });

    const blockedByBoundaryClasses = cx({
      allowedAcrossBoundary: true,
      TrafficBlocked: true,
      ColorDeficiency: UserStore.getColorBlindMode() === 'deficient',
    });

    const allowedPolicyClasses = cx({
      'PolicyDecision-Arrow': true,
      'TrafficAllowed': true,
      'ColorDeficiency': UserStore.getColorBlindMode() === 'deficient',
    });

    const allowedAcrossBoundaryClasses = cx({
      allowedAcrossBoundary: true,
      TrafficAllowed: true,
      ColorDeficiency: UserStore.getColorBlindMode() === 'deficient',
    });

    const potentiallyBlockedPolicyClasses = cx({
      'PolicyDecision-Arrow': true,
      'TrafficPotentiallyBlocked': true,
      'ColorDeficiency': UserStore.getColorBlindMode() === 'deficient',
    });

    const potentiallyBlockedByBoundaryClasses = cx({
      allowedAcrossBoundary: true,
      TrafficPotentiallyBlocked: true,
      ColorDeficiency: UserStore.getColorBlindMode() === 'deficient',
    });

    return (
      <div className="LegendPanel-Body-Row legendPanel-PolicyDecisions">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">{intl('Explorer.PolicyDecisions')}</h4>
          <div className="LegendPanel-Body--PolicyDecisionsTable">
            <table className="LegendPanel-Body-Table-Groups">
              <tbody>
                <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                  <td data-tid="TrafficBlocked" className="LegendPanel-PolicyDecisionsIcon">
                    <Icon name="arrow-right" customClass={blockedPolicyClasses} size="xlarge" />
                  </td>
                  <td className="LegendPanel-PolicyDecisionslabel">{intl('Map.Traffic.Filter.BlockedNoRule')}</td>
                </tr>
                <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                  <td data-tid="TrafficPotentiallyBlocked" className="LegendPanel-PolicyDecisionsIcon">
                    <Icon name="arrow-right" customClass={potentiallyBlockedPolicyClasses} size="xlarge" />
                  </td>
                  <td className="LegendPanel-PolicyDecisionslabel">
                    {intl('Map.Traffic.Filter.PotentiallyBlockedNoRule')}
                  </td>
                </tr>
                <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                  <td data-tid="TrafficAllowed" className="LegendPanel-PolicyDecisionsIcon">
                    <Icon name="arrow-right" customClass={allowedPolicyClasses} size="xlarge" />
                  </td>
                  <td className="LegendPanel-PolicyDecisionslabel">{intl('Map.Traffic.Filter.AllowedByRule')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column">
          <div className="LegendPanel-body-title-placeholder">""</div>
          <table className="LegendPanel-Body-Table-Groups">
            <tbody>
              <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                <td data-tid="TrafficBlocked" className="LegendPanel-PolicyDecisionsIcon">
                  <Icon name="boundary-right" customClass={blockedByBoundaryClasses} size="xlarge" />
                </td>
                <td className="LegendPanel-PolicyDecisionslabel">{intl('Common.BlockedByBoundary')}</td>
              </tr>
              <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                <td data-tid="TrafficPotentiallyBlocked" className="LegendPanel-PolicyDecisionsIcon">
                  <Icon name="boundary-right" customClass={potentiallyBlockedByBoundaryClasses} size="xlarge" />
                </td>
                <td className="LegendPanel-PolicyDecisionslabel">{intl('Common.PotentiallyBlockedByBoundary')}</td>
              </tr>
              <tr className="LegendPanel-Body-Tr-PolicyDecisions">
                <td data-tid="TrafficAllowed" className="LegendPanel-PolicyDecisionsIcon">
                  <Icon name="across-boundary-right" customClass={allowedAcrossBoundaryClasses} size="xlarge" />
                </td>
                <td className="LegendPanel-PolicyDecisionslabel">{intl('Common.AllowedAcrossBoundary')}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  },

  renderTransmissionModeSection() {
    return (
      <div className="LegendPanel-Body-Row LegendPanel-Workloads--column legendPanel-PolicyDecisions">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn-fix">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">{intl('Common.TransmissionMode')}</h4>
          <div className="LegendPanel-Body--PolicyDecisionsTable">
            <table>
              <tbody>
                <tr className="LegendPanel-Body-Tr-TransmissionMode">
                  <td className="LegendPanel-td-TransmissionMode">
                    <span className="TransmissionTypeIndicator--multicast" data-tid="Multicast">
                      M
                    </span>
                  </td>
                  <td className="LegendPanel-TransmissionModelabel">{intl('Map.Traffic.Multicast')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column">
          <div className="LegendPanel-body-title-placeholder">""</div>
          <div>
            <table>
              <tbody>
                <tr className="LegendPanel-Body-Tr-TransmissionMode">
                  <td className="LegendPanel-td-TransmissionMode">
                    <span className="TransmissionTypeIndicator--broadcast" data-tid="Broadcast">
                      B
                    </span>
                  </td>
                  <td className="LegendPanel-TransmissionModelabel">{intl('Map.Traffic.Broadcast')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  renderWorkloadsSection() {
    const svgStyle = {width: 40, height: 40};
    const buildingWorkload = {x: 20, y: 20, fill: blue, isLegend: true};

    const testingWorkload = update(buildingWorkload, {
      $merge: {policyState: 'selective'},
    });
    const enforcedWorkload = update(buildingWorkload, {
      $merge: {policyState: 'enforced'},
    });
    const unmanagedWorkload = update(buildingWorkload, {
      $merge: {type: 'workload', unmanaged: true},
    });
    const idleWorkload = update(buildingWorkload, {
      $merge: {type: 'workload', policyState: 'idle'},
    });

    return (
      <div className="LegendPanel-Body-Row LegendPanel-Workloads LegendPanel-Workloads--column">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div>{intl('Common.Workloads')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg data-tid="Workload" style={svgStyle}>
                      <NodeComponent data-tid="building-workload" data={buildingWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr>
                  <td>
                    <svg data-tid="Workload" style={svgStyle}>
                      <NodeComponent data-tid="testing-workload" data={testingWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr>
                  <td>
                    <svg data-tid="Workload" style={svgStyle}>
                      <NodeComponent data-tid="enforced-workload" data={enforcedWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Workloads.FullEnforcement')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-Body-Column LegendPanel-VS-Padding">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-title-placeholder">""</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg data-tid="Workload" style={svgStyle}>
                      <NodeComponent data-tid="unmanaged-workload" data={unmanagedWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Unmanaged')}</td>
                </tr>
                <tr>
                  <td>
                    <svg data-tid="Workload" style={svgStyle}>
                      <NodeComponent data-tid="idle-workload" data={idleWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Idle')}</td>
                </tr>
                <tr>
                  <td />
                  <td className="LegendPanel-workloadslabel" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  renderGroupsSection() {
    const svgGroupStyle = {width: 60, height: 34};
    const buildingCluster = {width: 44, height: 20, x: 32, y: 15, isLegend: true};
    const testingCluster = update(buildingCluster, {
      $merge: {policyState: 'selective'},
    });
    const enforcedCluster = update(testingCluster, {
      $merge: {policyState: 'enforced', type: null},
    });

    const svgRoleStyle = {width: 40, height: 40};
    const role = {
      x: 20,
      y: 20,
      type: 'role',
      fill: blue,
      isLegend: true,
      entityCounts: '',
      size: 13,
      policySize: 16,
      paddingRadius: 10,
    };

    const selectiveRole = update(role, {
      $merge: {policyState: 'selective'},
    });
    //
    const fullRole = update(role, {
      $merge: {policyState: 'enforced'},
    });

    return (
      <div className="LegendPanel-Body-Row LegendPanel-Groups">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div>{intl('Common.Groups')}</div>
          </h4>
          <div>
            <table className="LegendPanel-Body-Table-Groups">
              <tbody>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Groups" style={svgGroupStyle}>
                      <ClusterComponent data-tid="building-cluster" data={buildingCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Groups" style={svgGroupStyle}>
                      <ClusterComponent data-tid="testing-cluster" data={testingCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Groups" style={svgGroupStyle}>
                      <ClusterComponent data-tid="enforced-cluster" data={enforcedCluster} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Workloads.FullEnforcement')}</td>
                </tr>
                <tr>
                  <td />
                  <td className="LegendPanel-workloadslabel" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-groups">{intl('Common.Roles')}</div>
          </h4>
          <div>
            <table className="LegendPanel-Body-Table-Groups">
              <tbody>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Roles" style={svgRoleStyle}>
                      <RoleComponent data-tid="role" data={role} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Roles" style={svgRoleStyle}>
                      <RoleComponent data-tid="selective-role" data={selectiveRole} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr className="LegendPanel-Body-Tr-Groups">
                  <td>
                    <svg data-tid="Roles" style={svgRoleStyle}>
                      <RoleComponent data-tid="full-role" data={fullRole} />
                    </svg>
                  </td>
                  <td className="LegendPanel-clusterslabel">{intl('Workloads.FullEnforcement')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  renderOtherWorkloadsSection() {
    const svgStyle = {width: 40, height: 40};
    const containerWorkload = {
      x: 20,
      y: 20,
      fill: blue,
      isLegend: true,
      type: 'workload',
      subType: 'container',
      caps: {workloads: ['read']},
    };

    const testingContainerWorkload = update(containerWorkload, {
      $merge: {policyState: 'selective'},
    });
    const enforcedContainerWorkload = update(containerWorkload, {
      $merge: {policyState: 'enforced'},
    });

    const virtualService = {
      x: 16,
      y: 16,
      fill: blue,
      isLegend: true,
      type: 'virtualService',
    };

    return (
      <div className="LegendPanel-Body-Row LegendPanel-Others LegendPanel-Workloads--column">
        <div className="LegendPanel-Body-Column LegendPanel-Body-halfColumn">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div>{intl('Common.ContainerWorkloads')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg data-tid="Other-Workloads" style={svgStyle}>
                      <NodeComponent data-tid="container-workload" data={containerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.VisibilityOnly')}</td>
                </tr>
                <tr>
                  <td>
                    <svg data-tid="Other-Workloads" style={svgStyle}>
                      <NodeComponent data-tid="testing-container-workload" data={testingContainerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('EnforcementBoundaries.SelectiveEnforcement')}</td>
                </tr>
                <tr>
                  <td>
                    <svg data-tid="Other-Workloads" style={svgStyle}>
                      <NodeComponent data-tid="enforced-container-workload" data={enforcedContainerWorkload} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Workloads.FullEnforcement')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="LegendPanel-VS-Padding LegendPanel-Body-Column">
          <h4 className="LegendPanel-body-title LegendPanel-body-title--column">
            <div className="LegendPanel-body-groups">{intl('Common.VirtualServices')}</div>
          </h4>
          <div>
            <table>
              <tbody>
                <tr>
                  <td>
                    <svg data-tid="Other-Workloads" style={svgStyle}>
                      <NodeComponent data-tid="virtual-service" data={virtualService} />
                    </svg>
                  </td>
                  <td className="LegendPanel-workloadslabel">{intl('Common.Node')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  },

  render() {
    const {policyVersion, disabled} = this.props;
    const vulnerabilityMap = MapPageStore.getAppMapVersion() === 'vulnerability';

    const classes = cx({
      'LegendPanel': true,
      'LegendPanel-draft': policyVersion === 'draft',
    });

    const bodyClasses = cx({
      'LegendPanel-body': true,
      'hidden': this.state.hidden,
    });

    const buttonClasses = cx({
      'LegendPanel-icon': true,
      'LegendButton--active': !this.state.hidden,
      'LegendPanel--disabled': disabled,
    });

    return (
      <div className={classes} tabIndex="-1" onBlur={this.handleHideLegend}>
        <div className={buttonClasses} onClick={!disabled && this.toggleLegend} data-tid="legend-button">
          {intl('Map.LegendPanel.Legend')}&nbsp;
          <Icon name="dropdown" />
        </div>
        <div className={bodyClasses}>
          {this.renderGroupsSection()}
          {this.renderWorkloadsSection()}
          {this.renderOtherWorkloadsSection()}
          {this.renderTrafficSection()}
          {vulnerabilityMap && this.renderVulnerabilitySection()}
          {this.renderPolicyDecisionsSection()}
          {this.renderTransmissionModeSection()}
        </div>
      </div>
    );
  },
});
