/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
/* intl-disable */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import {Navbar, Grid, Select, SpinnerOverlay} from '../components';
import {ToolBar, ToolGroup} from '../components/ToolBar';
import Constants from '../constants';
import StoreMixin from '../mixins/StoreMixin';
import {GeneralStore, TrafficEventStore} from '../stores';
import {GridDataUtils, RestApiUtils, ServiceUtils} from '../utils';

function getStateFromStores() {
  return {
    trafficEvents: TrafficEventStore.getAll(),
    status: [TrafficEventStore.getStatus()],
  };
}

export default React.createClass({
  mixins: [StoreMixin([TrafficEventStore], getStateFromStores)],

  getInitialState() {
    const sorting = GeneralStore.getSorting('trafficEventsList');
    const filter = GeneralStore.getFilter('trafficEventsList');

    return {
      sorting: sorting || [{key: 'end_time', direction: true}],
      filter: filter || 'all',
    };
  },

  componentDidMount() {
    RestApiUtils.trafficEvents.getCollection();
  },

  onRefresh() {
    RestApiUtils.trafficEvents.getCollection();
  },

  handleFilterChange(evt) {
    actionCreators.updateGeneralFilter('trafficEventsList', evt.value);
    this.setState({filter: evt.value});
  },

  handleSort(key, direction) {
    const sorting = [];

    if (key) {
      sorting.push({key, direction});
    }

    actionCreators.updateGeneralSorting('trafficEventsList', sorting);
    this.setState({sorting});
  },

  render() {
    const columns = [
      {
        key: 'href',
        label: intl('Common.ID'),
        style: 'id',
        sortable: true,
        type: 'numeric',
        format: value => GridDataUtils.getIdFromHref(value),
        sortValue: value => {
          const id = GridDataUtils.getIdFromHref(value);
          const intID = parseInt(id, 10);

          return isNaN(intID) ? id : intID;
        },
      },
      {
        key: 'dest_port',
        label: intl('Common.Service'),
        sortable: true,
        format: (value, row) => {
          if (value > 0) {
            return `${value} ${ServiceUtils.lookupProtocol(row.dest_protocol)}`;
          }

          return ServiceUtils.lookupProtocol(row.dest_protocol);
        },
      },
      {
        key: 'dest_agent_name',
        type: 'string',
        label: intl('Common.Providers'),
        sortable: true,
      },
      {
        key: 'src_agent_name',
        type: 'string',
        label: intl('Common.Consumers'),
        sortable: true,
      },
      {
        key: 'direction',
        label: intl('TrafficEvents.Direction'),
        sortable: true,
        format: value => {
          switch (value) {
            case 'outbound':
              return intl('Common.Outbound');
            case 'inbound':
              return intl('Common.Inbound');
          }
        },
      },
      {
        key: 'start_time',
        label: intl('TrafficEvents.FirstTime'),
        type: 'date',
        style: 'date',
        sortable: true,
      },
      {
        key: 'end_time',
        label: intl('TrafficEvents.LastTime'),
        type: 'date',
        style: 'date',
        sortable: true,
      },
      {
        key: 'flow_count',
        label: intl('TrafficEvents.FlowCount'),
        type: 'numeric',
        sortable: true,
      },
      {
        key: 'action',
        label: intl('Common.Action'),
        sortable: true,
        type: 'string',
        format: value => {
          switch (value) {
            case 'blocked':
              return intl('Common.Blocked');
            case 'allowed':
              return intl('Common.PotentiallyBlocked');
          }
        },
      },
    ];

    const selectOptions = [
      {value: 'all', label: intl('TrafficEvents.AllEvents')},
      {value: 'blocked', label: intl('TrafficEvents.BlockedEvents')},
      {value: 'allowed', label: intl('TrafficEvents.PotentiallyBlockedEvents')},
    ];

    let trafficEvents = this.state.trafficEvents;

    switch (this.state.filter) {
      case 'blocked':
      case 'allowed':
        trafficEvents = _.filter(trafficEvents, item => item.action === this.state.filter);
        break;
    }

    let selectionHelp = '';

    if (this.state.filter === 'allowed') {
      selectionHelp = intl('TrafficEvents.TrafficBlockedWhenEnforced');
    }

    if (this.state.filter === 'blocked') {
      selectionHelp = intl('TrafficEvents.TrafficBlockedByEnforcement');
    }

    return (
      <div className="ListPage TrafficEventsList" data-tid="page-trafficevents-list">
        {this.state.status.includes(Constants.STATUS_BUSY) ? <SpinnerOverlay /> : null}
        <Navbar search={false} />
        <ToolBar>
          <ToolGroup>
            <div className="ListPage-Filter">
              <Select
                autoFocus={true}
                options={selectOptions}
                selected={this.state.filter}
                onSelect={this.handleFilterChange}
              />
            </div>
            <div>
              <span className="ListPage-Count-Total" data-tid="elem-count-total">
                {intl('TrafficEvents.TrafficEventsCount', {count: trafficEvents.length})}
              </span>
            </div>
          </ToolGroup>
        </ToolBar>
        <div className="TrafficEvents-page-help">
          <div>{selectionHelp}</div>
        </div>
        <Grid
          columns={columns}
          data={trafficEvents}
          sorting={this.state.sorting}
          sortable={true}
          idField="href"
          onSort={this.handleSort}
          onRowSelectToggle={this.handleSelectToggle}
        />
      </div>
    );
  },
});
