/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import intl from 'intl';
import {Navigation} from 'react-router';
import actionCreators from '../actions/actionCreators';
import Icon from './Icon.jsx';
import {Menu, MenuItem, MenuDelimiter} from './Menu';
import {SessionStore} from '../stores';
import {ASPVersion} from '../modals';

const tid = 'comp-navbar-';

export default React.createClass({
  mixins: [Navigation],

  handleOpenVersionDialog() {
    actionCreators.openDialog(<ASPVersion />);
  },

  renderContent() {
    const supportLink = SessionStore.getSupportLink();
    const helpLink = SessionStore.isEdge()
      ? SessionStore.getEdgeUrl()
      : `${SessionStore.getHelpLink()}/${SessionStore.getHelpVersion()}/Home.htm`;
    const termsLink = 'https://www.illumio.com/eula';
    const privacyLink = 'https://www.illumio.com/privacy-policy';
    const patentLink = 'https://www.illumio.com/patents';
    const whatsNewLink = SessionStore.getWhatsNewDocUrl();
    const product = _.capitalize(SessionStore.getProduct());

    return [
      <MenuItem link={{href: helpLink, target: '_blank'}} text={intl('Common.Help')} data-tid={`${tid}help`} />,
      <MenuDelimiter />,
      <MenuItem
        link={{href: supportLink, target: '_blank'}}
        text={intl('Common.Support')}
        data-tid={`${tid}support`}
      />,
      <MenuDelimiter />,
      <MenuItem link={{href: termsLink, target: '_blank'}} text={intl('Common.Terms')} data-tid={`${tid}terms`} />,
      <MenuItem
        link={{href: privacyLink, target: '_blank'}}
        text={intl('Common.PrivacyPolicy')}
        data-tid={`${tid}privacy-policy`}
      />,
      <MenuItem link={{href: patentLink, target: '_blank'}} text={intl('Common.Patents')} data-tid={`${tid}patents`} />,
      <MenuDelimiter />,
      ...(__ANTMAN__
        ? []
        : [
            <MenuItem
              link={{href: whatsNewLink, target: '_blank'}}
              text={intl('WhatsNew.WhatsNewIn', {product})}
              data-tid={`${tid}whatsnew`}
            />,
          ]),
      <MenuItem
        onSelect={this.handleOpenVersionDialog}
        text={__ANTMAN__ ? intl('Illumio.About.Xpress') : intl('Illumio.About.Core')}
        data-tid={`${tid}about`}
      />,
    ];
  },

  render() {
    return (
      <Menu tid="Navbar-help" icon={<Icon name="dropdown" size="xlarge" />}>
        {this.renderContent}
      </Menu>
    );
  },
});
