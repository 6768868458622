/**
 * Copyright 2016 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../actions/actionCreators';
import Dialog from '../components/Dialog.jsx';
import ServiceUtils from '../utils/ServiceUtils';
import RenderUtils from '../utils/RenderUtils';
import Icon from '../components/Icon.jsx';

export default React.createClass({
  getActions() {
    return [
      {
        text: intl('Common.OK'),
        tid: 'ok',
        ref: 'confirmButton',
        onClick: this.handleClose,
      },
    ];
  },

  handleClose() {
    actionCreators.closeDialog();
  },

  render() {
    const {services} = this.props;

    const servicesList = Object.values(services)
      .sort((a, b) => a.port - b.port)
      .reduce((result, connection) => {
        Object.keys(connection.class || {none: ''}).forEach(connectionClass => {
          const {port, protocol, service} = connection;
          const protocolName = ServiceUtils.lookupProtocol(protocol);

          result.push(
            <div
              className="RuleBuilderGrid-port-protocol-item"
              key={[port, protocol, service, connectionClass].join(',')}
            >
              {connectionClass === 'none' ? null : (
                <Icon
                  position="before"
                  size="large"
                  styleClass={connectionClass === 'U' ? 'RuleBuilderGrid-Unicast' : ''}
                  name={RenderUtils.getTransmissionModeIcon(connectionClass)}
                />
              )}
              {intl('PolicyGenerator.PortProtocolProcess', {
                port: protocolName.includes('ICMP') ? '' : port,
                protocol: protocolName,
                process: service || false,
              })}
            </div>,
          );
        });

        return result;
      }, []);

    return (
      <Dialog
        ref="dialog"
        type="alert"
        alert={true}
        actions={this.getActions()}
        title={intl('PolicyGenerator.Grid.PortsProtocolsProcesses')}
      >
        <div className="PolicyGeneratorGrid-port-protocol-list">{servicesList}</div>
      </Dialog>
    );
  },
});
