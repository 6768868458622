/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import * as d3 from 'd3';
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import svgIntersections from 'svg-intersections';
import StoreMixin from '../../mixins/StoreMixin';
import {GraphTransformStore, GraphStore, MapPageStore, SessionStore} from '../../stores';
import actionCreators from '../../actions/actionCreators';
import {ModeAlert} from '../../modals';
import {Tooltip, Icon} from '..';
import RestApiUtils from '../../utils/RestApiUtils';

// 3 interaction type: select, multiselect and move (multiselect will only be triggered by shiftDown)
function getStateFromStore() {
  return {
    interactionType: GraphTransformStore.getInteractionType(),
  };
}

export default React.createClass({
  mixins: [StoreMixin([GraphTransformStore], getStateFromStore)],

  componentDidMount() {
    this.brush = null;
    this.dragged = false;
    this.spaceToMove = false;
    this.startPoint = [0, 0];
    this.selectionMap = {}; // selectionMap is used to track overall link selection, {key: value} = {identifier: traffic}
    this.allSelections = [];
    this.shiftDown = false; // extraSelect is used to track longpress (shift) keyboard event
    this.spaceDown = false;
    this.inClusterSelection = false;
    this.svg = d3.select('#svg-illumination');

    if (this.state.interactionType === 'select') {
      this.handleMultiSelect();
    }

    window.onblur = () => {
      // reset everything = default
      if (GraphTransformStore.getInteractionType() === 'multiselect') {
        actionCreators.updateInteractionType('select');
      }

      this.shiftDown = false;
      this.spaceDown = false;
    };

    // press shift to switch to multi-select mode
    document.onkeydown = evt => {
      if (evt.repeat) {
        return;
      }

      if (this.state.interactionType === 'select' && evt.keyCode === 16 && this.shiftDown === false) {
        this.shiftDown = true;
        d3.selectAll('.brush').remove();
        this.handleMultiSelect();
        actionCreators.updateInteractionType('multiselect');
      }

      if (this.state.interactionType === 'select' && evt.keyCode === 32 && this.spaceDown === false) {
        // pressing space
        this.spaceToMove = true;
        this.spaceDown = true;
        this.handleMoveMode();
      }
    };

    document.onkeyup = evt => {
      if (evt.repeat) {
        return;
      }

      if (evt.keyCode === 16 && this.state.interactionType !== 'move') {
        this.shiftDown = false;
        actionCreators.updateInteractionType('select');
      } else if (evt.keyCode === 32 && this.spaceDown === true) {
        if (this.dragged) {
          this.spaceDown = false;
        } else {
          this.handleMultiSelect();
          actionCreators.updateInteractionType('select');
          this.spaceDown = false;
          this.spaceToMove = false;
        }
      }
    };
  },

  componentWillMount() {
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'hide_message');
  },

  mousedown() {
    if (d3.event.button !== 0) {
      return;
    }

    if (d3.select(d3.event.target).classed('il-cluster-type')) {
      this.inClusterSelection = true;
    }

    d3.selectAll('.brush').remove();

    const height = MapPageStore.getMapType() === 'app' ? 130 : 90; // navBarHeight + searchBarHeight + bannerHeight;

    this.startPoint = [d3.event.pageX, d3.event.pageY - height];

    this.brush = this.svg.append('rect').attr('id', 'extent').attr('class', 'brush');
    this.svg.on('mousemove', this.mousemove);
  },

  mousemove() {
    if (d3.event.button !== 0) {
      return;
    }

    this.dragged = true;

    const height = MapPageStore.getMapType() === 'app' ? 130 : 90; // navBarHeight + searchBarHeight + bannerHeight;
    const extentOffeset = SessionStore.isNewUI() ? 35 : height;
    const boundary = 1;
    const m = [d3.event.pageX, d3.event.pageY - extentOffeset];

    // set boundary of brush, so that later on in mouseup, we will not get e.target == brush rect
    this.brush
      .attr('width', Math.abs(m[0] - Number(this.startPoint[0]) - boundary))
      .attr('height', Math.abs(m[1] - Number(this.startPoint[1]) - boundary));

    if (m[0] < this.startPoint[0] && m[1] < this.startPoint[1]) {
      // going up-left
      this.brush
        .attr('x', Math.min(m[0] + boundary, Number(this.startPoint[0])))
        .attr('y', Math.min(m[1] + boundary, Number(this.startPoint[1])));
    } else {
      this.brush
        .attr('x', Math.min(m[0], Number(this.startPoint[0])))
        .attr('y', Math.min(m[1], Number(this.startPoint[1])));
    }
  },

  mouseup(evt) {
    //!!important: only execute mouseup when brush exists (mousedown & mousemove already called on svg)
    if (evt.button !== 0 || !d3.select('#extent')[0][0]) {
      return;
    }

    if (!d3.select(evt.target).classed('il-cluster-type')) {
      this.inClusterSelection = false;
    }

    const target = evt.target;

    actionCreators.updateMultipleSelection([]);
    this.svg.on('mousemove', null);

    if (this.dragged) {
      // drag + shift
      if (this.shiftDown) {
        this.selectionMap = GraphStore.getSelectionMap();

        // drag + no-shift
      } else {
        this.selectionMap = {};
        this.allSelections = [];
      }

      this.brushEnded();
      actionCreators.updateSelectionType('link');
      this.dragged = false;
    } else {
      // delete brush if not dragged
      d3.selectAll('.brush').remove();

      // click + shift
      if (this.shiftDown) {
        if (d3.select(target).classed('TrafficLink') && GraphStore.getSelectionType() === 'link') {
          this.selectionMap = GraphStore.getSelectionMap();

          d3.select(target).each(linkData => {
            this.addToSelectionMap(linkData, 'link');
          });
          this.allSelections = [];

          for (const key in this.selectionMap) {
            if (this.selectionMap.hasOwnProperty(key)) {
              //inherited properties are omitted
              this.allSelections.push(this.selectionMap[key]);
            }
          }

          this.handleSelectComponent(this.allSelections);
        } else if (d3.select(target.parentNode).classed('il-node') && GraphStore.getSelectionType() === 'node') {
          /* enable this node-multiSelect function in the future

          this.selectionMap = {};
          GraphStore.getSelections().forEach(node => {
            this.selectionMap[node.href] = node;
          });
          d3.select(target).each(n => {
            this.addToSelectionMap(n, 'node');
          });
          this.allSelections = [];

          for (const key in this.selectionMap) {
            if (this.selectionMap.hasOwnProperty(key)) { //inherited properties are omitted
              this.allSelections.push(this.selectionMap[key]);
            }
          }

          this.handleSelectComponent(this.allSelections);
         */
        }

        // click + no-shift
      } else {
        this.allSelections = [];
        this.selectionMap = {};

        if (d3.select(target).classed('TrafficLink')) {
          this.handleSelectComponent([]);
          _.defer(() => {
            actionCreators.updateSelectionType('link');
          });
        } else if (d3.select(target.parentNode).classed('il-node')) {
          _.defer(() => {
            actionCreators.updateSelectionType('node');
          });
        } else if (d3.select(target).classed('il-role-rect')) {
          _.defer(() => {
            actionCreators.updateSelectionType('role');
          });
        } else if (d3.select(target).classed('il-graph-background')) {
          this.handleClearSelection();
        }
      }
    }

    this.inClusterSelection = false;
  },

  moveModeMouseUp(evt) {
    if (evt.button !== 0 || !evt.target.ownerSVGElement) {
      return;
    }

    if (!this.spaceDown && this.dragged && this.spaceToMove) {
      this.handleMultiSelect();
      actionCreators.updateInteractionType('select');
      this.spaceToMove = false;
    }

    this.svg.on('mousemove', null);

    const target = evt.target;

    if (!this.dragged) {
      if (
        !d3.select(target).classed('il-graph-background') &&
        MapPageStore.getMapLevel() !== 'full' &&
        !GraphStore.getHideMessage().hideMoveToolAlert
      ) {
        actionCreators.openDialog(
          <ModeAlert
            message="click"
            onConfirm={() => {
              this.ignoreChanges = true;
            }}
          />,
        );
      }
    }

    this.dragged = false;
  },

  handleMultiSelect() {
    if (this.state.interactionType === 'move') {
      actionCreators.updateInteractionType('select');
    }

    this.svg.classed('moveMode', false);
    this.svg.classed('selectMode', true);
    this.svg.on('mousedown', this.mousedown);
    document.onmouseup = this.mouseup;
  },

  handleMoveMode() {
    actionCreators.updateInteractionType('move');
    d3.selectAll('.brush').remove();
    this.svg.classed('moveMode', true);
    this.svg.classed('selectMode', false);

    //disable brush, enable moveMode alert when trying to click on things
    this.svg.on('mousedown', () => {
      this.svg.on('mousemove', () => {
        if (d3.event.button !== 0) {
          return;
        }

        this.dragged = true;
      });
    });

    document.onmouseup = this.moveModeMouseUp;
  },

  handleClearSelection() {
    d3.selectAll('.brush').remove();
    this.handleSelectComponent([]);
    this.allSelections = [];
    this.selectionMap = {};
  },

  brushEnded() {
    const extent = d3.select('#extent');
    const rectParams = {
      x: Number(extent.attr('x')),
      y: Number(extent.attr('y')),
      width: Number(extent.attr('width')),
      height: Number(extent.attr('height')),
    };

    // disable cluster (straight) links when brush is compeletely inside a cluster (both of start/end points lie inside)
    const linkSelection = this.inClusterSelection
      ? d3.selectAll('.TrafficLink').filter(d => d.source.type !== 'group' && d.target.type !== 'group' && !d.isHidden)
      : d3.selectAll('.TrafficLink').filter(d => !d.isHidden);

    linkSelection.each(linkData => {
      const linkIdentifier = linkData.identifier;
      const linkId = linkIdentifier ? `link-${linkIdentifier.replaceAll(/[,/]/g, '-')}` : '';
      const link = d3.select(`#${linkId}`)[0][0];
      let groupTransform = [0, 0];

      if (linkData.groupTransform && linkData.source.type !== 'group' && linkData.target.type !== 'group') {
        groupTransform = linkData.groupTransform;
      }

      // check if rect contain / intersect with links
      const selected = this.ifSelected('rect', rectParams, link, groupTransform);

      if (selected) {
        this.addToSelectionMap(linkData, 'link');
      }
    });
    // push all traffic linkdata into allSelections
    this.allSelections = [];

    for (const key in this.selectionMap) {
      if (this.selectionMap.hasOwnProperty(key)) {
        //inherited properties are omitted
        this.allSelections.push(this.selectionMap[key]);
      }
    }

    if (
      this.allSelections.length === 0 &&
      MapPageStore.getMapLevel() !== 'full' &&
      !GraphStore.getHideMessage().hideSelectionToolAlert &&
      (rectParams.width > 15 || rectParams.height > 15)
    ) {
      actionCreators.openDialog(
        <ModeAlert
          message="drag"
          onConfirm={() => {
            this.ignoreChanges = true;
          }}
        />,
      );
    } else if (
      this.allSelections.length === 0 &&
      MapPageStore.getMapLevel() === 'full' &&
      this.inClusterSelection &&
      !GraphStore.getHideMessage().hideFullmapSelectionToolAlert &&
      (rectParams.width > 15 || rectParams.height > 15)
    ) {
      actionCreators.openDialog(
        <ModeAlert
          message="dragOnFull"
          onConfirm={() => {
            this.ignoreChanges = true;
          }}
        />,
      );
    }

    this.handleSelectComponent(this.allSelections);

    d3.selectAll('.brush').remove();
    this.handleMultiSelect();
  },

  ifSelected(lassoType, params, link, groupTransform) {
    // calculate main scale and transform
    // Use Regular Expression to extract all numbers from path d string
    // Then add up main/indivudual transform to get actual location on screen
    const numRegEx = /[+-]?\d*\.?\d+/g;
    const charRegEx = /[A-Za-z]/g;

    const mainScale = GraphTransformStore.getTransform().scale;
    const mainTransform = GraphTransformStore.getTransform().translate;
    const intersect = svgIntersections.intersect;
    const shape = svgIntersections.shape;

    const linkPath = link.getAttribute('d');
    const linkTransform = link.getAttribute('transform')
      ? link.getAttribute('transform').match(numRegEx).map(Number)
      : [0, 0];
    const pathChars = linkPath.match(charRegEx);
    const pathNum = linkPath.match(numRegEx).map(Number);

    let truePath = '';
    let currentNum = 0;
    // output: selected (boolean)
    let selected = false;

    // if translate only has tx value, we will assign 0 as value of y
    if (linkTransform.length === 1) {
      linkTransform.push(0);
    }

    // calculate true position from all transform/scale
    for (let i = 0; i < pathNum.length; i++) {
      pathNum[i] =
        i % 2 === 0
          ? (pathNum[i] + mainTransform[0] / mainScale + linkTransform[0] + groupTransform[0]) * mainScale
          : (pathNum[i] + mainTransform[1] / mainScale + linkTransform[1] + groupTransform[1]) * mainScale;
    }

    // path string with true position
    pathChars.forEach(char => {
      if (char === 'Z') {
        truePath += char;
      } else if (char === 'M' || char === 'L') {
        truePath += `${char} ${pathNum[currentNum]} ${pathNum[currentNum + 1]} `;
        currentNum += 2;
      } else if (char === 'C') {
        truePath += `${char} `;

        for (let j = 0; j < 6; j++) {
          truePath += `${pathNum[currentNum]} `;
          currentNum += 1;
        }
      }
    });

    const intersections = intersect(shape(lassoType, params), shape('path', {d: truePath}));

    intersections.status = intersections.points.length > 0;

    if (lassoType === 'rect') {
      // if not intersect, but path falls in rect
      if (intersections.status) {
        selected = true;
      } else if (
        pathNum[0] <= params.x + params.width &&
        pathNum[0] >= params.x &&
        pathNum[1] <= params.y + params.height &&
        pathNum[1] >= params.y
      ) {
        selected = true;
      }
    }

    if (lassoType === 'circle') {
      // if not intersect, but path falls in circle
      if (intersections.status) {
        selected = true;
      } else if (Math.abs(pathNum[0] - params.cx) <= params.r && Math.abs(pathNum[1] - params.cy) <= params.r) {
        selected = true;
      }
    }

    /*For testing purpose, comment off this code block to check if all path match
    this.svg.append('path')
      .attr('class', 'test')
      .attr('d', truePath)
      .attr('stroke-width', 4)
      .attr('stroke', 'black')
      .attr('fill', 'black');
    */

    return selected;
  },

  addToSelectionMap(data, type) {
    let selection;

    switch (type) {
      case 'link':
        if (!data.identifier || data.type === 'notLoaded' || _.isEmpty(data.connections)) {
          return;
        }

        // If this is an internet link, then send over all the hrefs.
        // If there's no internetLinks attribute, then this must
        // be a regularly link between workloads, so it only has one href
        const source = data.source;
        const target = data.target;
        const clusterHref =
          source.cluster && target.cluster && source.cluster.href === target.cluster.href ? source.cluster.href : null;

        selection = {
          type: 'traffic',
          href: data.href,
          identifier: data.identifier,
          clusterHref,
        };

        break;

      case 'node':
      case 'role':
        if (!data.identifier) {
          return;
        }

        // for unconnected node, the clusterHref is null
        selection = {
          type: data.type,
          href: data.href,
          clusterHref: data.cluster ? data.cluster.href : null,
        };

        break;

      default:
        break;
    }

    // remove if traffic already exist in selectionMap
    if (data.selected) {
      if (this.shiftDown) {
        if (this.selectionMap.hasOwnProperty(data.identifier)) {
          delete this.selectionMap[data.identifier];
        }
      } else {
        this.selectionMap[data.identifier] = selection;
      }
    } else {
      this.selectionMap[data.identifier] = selection;
    }
  },

  handleSelectComponent(data) {
    actionCreators.updateComponentSelection(data);

    if (this.props.onSelectLinks) {
      //this.link.call(_.bind(LinkVisualization.highlightLink, LinkVisualization));
      setTimeout(() => {
        this.props.onSelectLinks(data);
      }, 500);
    }
  },

  handleUnselectComponent(data) {
    actionCreators.unselectComponent(data);
  },

  render() {
    const interactionPanel = cx({
      'InteractionPanel': true,
      'InteractionPanel--hidden': !(
        this.props.data.locations.length ||
        this.props.data.clusters.length ||
        this.props.data.nodes.length
      ),
    });

    return (
      <div className={interactionPanel}>
        <div className="InteractionPanel-Button">
          <Tooltip
            content={intl('Common.SelectionTool')}
            subContent={intl('Common.SelectionToolDescription')}
            width={140}
            location="left"
          >
            <div
              className={`InteractionPanel-Button-Select ${
                this.state.interactionType === 'select' || this.state.interactionType === 'multiselect'
                  ? 'InteractionPanel-Button-Active'
                  : 'InteractionPanel-Button-Inactive'
              }`}
              onClick={this.handleMultiSelect}
              data-tid="selection-tool"
            >
              <div className="InteractionPanel-Button-Side" />
              <Icon name="selection-tool" />
              <div className="InteractionPanel-Button-Side" />
            </div>
          </Tooltip>
          <div className="InteractionPanel-Button-Border" />
          <Tooltip
            content={intl('Common.MoveTool')}
            subContent={intl('Common.MoveToolDescription')}
            width={155}
            location="left"
          >
            <div
              className={`InteractionPanel-Button-Move ${
                this.state.interactionType === 'move'
                  ? 'InteractionPanel-Button-Active'
                  : 'InteractionPanel-Button-Inactive'
              }`}
              onClick={this.handleMoveMode}
              data-tid="move-tool"
            >
              <Icon name="move-tool" />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  },
});
