/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import intl from 'intl';

export default function ResourceNotFound() {
  return (
    <div className="il-static-page">
      <header>
        <div>
          <img src="static/images/alert_icon.svg" height="14px" width="14px" />
          {intl('Static.ResourceNotFound.CodeResourceNotFound')}
        </div>
      </header>
      <section>
        <div className="il-logo-border">
          <img width="154" height="40" src="static/images/logo.svg" alt="Illumio" />
        </div>
        <h1>
          {intl('Static.ResourceNotFound.ResourceNotFound')} <a href="">{intl('Static.ResourceNotFound.StartOver')}</a>
        </h1>
      </section>
      <footer>{intl('Illumio.Copyright')}</footer>
    </div>
  );
}
