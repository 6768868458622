/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import React from 'react';
import VizUtils from '../../utils/Explorer/VizUtils';
import ParallelCoordinates from './ParallelCoordinates/ParallelCoordinates';
import intl from 'intl';
import {getViewportWidth, getViewportHeight} from 'utils/dom';

let resizeTimer;
let width;
let height;

export default React.createClass({
  getInitialState() {
    const portSorting = 'portNumber';
    const processNameSorting = 'processOrServiceName';
    const outBoundProcessNameSorting = 'outboundProcessOrServiceName';

    this.calculateSVGDimensions();

    return {
      ...this.getVizData(this.props, portSorting, processNameSorting, outBoundProcessNameSorting),
      portSorting,
      processNameSorting,
      outBoundProcessNameSorting,
    };
  },

  componentDidMount() {
    window.addEventListener('resize', this.windowResize);
    this.windowResize();
  },

  componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.links, this.props.links) ||
      !_.isEqual(nextProps.selectedSource, this.props.selectedSource) ||
      !_.isEqual(nextProps.selectedTarget, this.props.selectedTarget) ||
      !_.isEqual(nextProps.clickedTick, this.props.clickedTick) ||
      !_.isEqual(nextProps.isDataReady, this.props.isDataReady)
    ) {
      this.setState(
        this.getVizData(
          nextProps,
          this.state.portSorting,
          this.state.processNameSorting,
          this.state.outBoundProcessNameSorting,
        ),
      );
    }

    if (nextProps.collapse !== this.props.collapse) {
      this.windowResize();
    }
  },

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResize);
  },

  getVizData(props, portSorting, processNameSorting, outBoundProcessNameSorting) {
    // calculate links and positions for visualizations
    const {filteredLinks, viz} = VizUtils.calculateAndPositionViz(
      props,
      portSorting,
      processNameSorting,
      outBoundProcessNameSorting,
      width,
      height,
    ); //width is divided between PC and NL

    return {
      pcData: viz.pc, // data for parallel coordinates
      highlighted: {}, //which nodes are highlighted on graphs
      filteredLinks, // is used to update pc yCoord
    };
  },

  handleHighlight(highlighted) {
    this.setState({
      highlighted,
    });
  },

  windowResize() {
    clearTimeout(resizeTimer);

    resizeTimer = setTimeout(() => {
      // this function is called only on window resize end
      this.calculateSVGDimensions();

      const {viz} = VizUtils.calculateAndPositionViz(
        this.props,
        this.state.portSorting,
        this.state.processNameSorting,
        this.state.outBoundProcessNameSorting,
        width,
        height,
      );

      this.setState({
        pcData: viz.pc,
      });
    }, 500);
  },

  calculateSVGDimensions() {
    // Set width/height to the browser window or document size.
    const navBar = document.querySelectorAll('.Navbar')[0];
    const filters = document.querySelectorAll('.ExplorerFilter-Bar')[0];
    const navPanel = document.querySelectorAll('.Explorer-navigation')[0];
    const dropdown = document.querySelectorAll('.Explorer-navigation-picker')[0];
    const footer = document.querySelectorAll('.Footer')[0];
    const filterBar = document.querySelectorAll('.ExplorerFilter-Bar-Row')[0];
    const picker = document.querySelectorAll('.ExplorerTimeFilter-Picker')[0];
    const navPath = document.querySelectorAll('.Explorer-navPathPanel')[0];

    const navBarHeight = navBar ? navBar.clientHeight : 0;
    const filtersHeight = filters ? filters.clientHeight : 0;
    const navPanelHeight = navPanel ? navPanel.clientHeight : 0;
    const dropdownHeight = dropdown ? dropdown.clientHeight : 0;
    const footerHeight = footer ? footer.clientHeight : 0;
    const filterBarHeight = filterBar ? filterBar.clientHeight : 0;
    const pickerHeight = picker ? picker.clientHeight : 0;
    const navPathHeight = navPath ? navPath.clientHeight : 0;

    const narrow = this.props.narrow ? 40 : 0;

    width = getViewportWidth() - narrow;
    height =
      getViewportHeight() -
      navBarHeight -
      filtersHeight -
      navPanelHeight -
      dropdownHeight -
      footerHeight -
      filterBarHeight -
      pickerHeight -
      navPathHeight;
  },

  handleUpdatePortSorting(evt) {
    if (evt && evt.target) {
      const portSorting = evt.target.value;
      const pcData = _.cloneDeep(this.state.pcData);

      pcData.yCoord.port = VizUtils.sortingLinksByPorts(portSorting, this.state.filteredLinks, height).portCoord;

      this.setState({
        pcData,
        portSorting,
      });
    }
  },

  handleUpdateProcessNameSorting(evt) {
    if (evt && evt.target) {
      const processNameSorting = evt.target.value;
      const pcData = _.cloneDeep(this.state.pcData);

      pcData.yCoord.processName = VizUtils.sortingLinksByProcessName(
        processNameSorting,
        this.state.filteredLinks,
        height,
      ).processNameCoord;

      this.setState({
        pcData,
        processNameSorting,
      });
    }
  },

  handleUpdateOutboundProcessNameSorting(evt) {
    if (evt && evt.target) {
      const outBoundProcessNameSorting = evt.target.value;
      const pcData = _.cloneDeep(this.state.pcData);

      pcData.yCoord.outboundProcessName = VizUtils.sortingLinksByOutboundProcessName(
        outBoundProcessNameSorting,
        this.state.filteredLinks,
        height,
      ).outboundProcessNameCoord;

      this.setState({
        pcData,
        outBoundProcessNameSorting,
      });
    }
  },

  render() {
    const {banner, showData} = this.props;
    let pcViz = null;
    const legend = null;
    let totalserviceNumber = null;

    if (document.querySelector('.il-pcDimension-text') && document.documentMode) {
      setTimeout(() => {
        if (document.querySelectorAll('.il-pcDimension-text')[2]) {
          const portTextWidthFromLeftOfScreen = document
            .querySelectorAll('.il-pcDimension-text')[2]
            .getBoundingClientRect().left;

          document.querySelector('.il-pcDimension-selectie-portname').style.left = `${
            portTextWidthFromLeftOfScreen + 23
          }px`;
        }

        if (document.querySelectorAll('.il-pcDimension-text')[3]) {
          const processTextWidthFromLeftOfScreen = document
            .querySelectorAll('.il-pcDimension-text')[3]
            .getBoundingClientRect().left;

          document.querySelector('.il-pcDimension-selectie-processname').style.left = `${
            processTextWidthFromLeftOfScreen + 78
          }px`;
        }
      }, 0);
    }

    if (this.props.links.length) {
      totalserviceNumber = (
        <div className="ServiceNumber">{`${intl('Explorer.TotalConnections')}: ${this.props.links.length}`}</div>
      );
    }

    if (
      !_.isEmpty(this.state.pcData.pcLinks) &&
      !_.isEmpty(this.props.selectedSource) &&
      !_.isEmpty(this.props.selectedTarget)
    ) {
      pcViz = (
        <ParallelCoordinates
          data={this.state.pcData}
          selectedSource={this.props.selectedSource}
          selectedTarget={this.props.selectedTarget}
          clickedTick={this.props.clickedTick}
          clickedTickPath={this.props.clickedTickPath}
          isDataReady={this.props.isDataReady}
          vizHighlighted={this.state.highlighted}
          onHighlight={this.handleHighlight}
          portSorting={this.state.portSorting}
          onUpdatePortSorting={this.handleUpdatePortSorting}
          onUpdateProcessNameSorting={this.handleUpdateProcessNameSorting}
          onUpdateOutboundProcessNameSorting={this.handleUpdateOutboundProcessNameSorting}
          width={width}
          height={height}
        />
      );
    }

    return (
      <div>
        {totalserviceNumber}
        {banner}
        {!banner || showData ? (
          <div className="ExplorerViz">
            {pcViz}
            {legend}
          </div>
        ) : null}
      </div>
    );
  },
});
