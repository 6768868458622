/**
 * Copyright 2017 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import actionCreators from '../../actions/actionCreators';
import {Navbar} from '../../components';
import {RouterMixin, PolicyGeneratorMixin} from '../../mixins';
import {RBSuccessMessage} from '../../components/PolicyGenerator';

export default React.createClass({
  mixins: [RouterMixin, PolicyGeneratorMixin],

  componentWillMount() {
    this.setSelectedRingFenceAppGroups([]);
    this.setState(this.getRingFenceSuccessInfo());
  },

  onContinue() {
    this.transitionTo('ringFenceChoose', {}, {appgroups: false});
  },

  onSelectAnother() {
    this.setAppGroup();
    this.transitionTo('policygenerator');
  },

  onGoToRule() {
    this.transitionTo('rulesets.list');
  },

  onGoToAppGroupMap() {
    actionCreators.setMapPolicyState('draft');
    this.transitionTo('appMap');
  },

  render() {
    const {newScopes} = this.state;

    return (
      <div className="RBSuccess">
        <Navbar title={intl('Common.Success')} />
        <RBSuccessMessage
          name={intl('PolicyGenerator.RingFence.Multiple')}
          newScopes={newScopes}
          type="ringfence"
          continue={this.onContinue}
          selectAnother={this.onSelectAnother}
          goToRule={this.onGoToRule}
          goToAppGroupMap={this.onGoToAppGroupMap}
        />
      </div>
    );
  },
});
