/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import cx from 'classnames';
import {ObjectSelector} from '.';
import Spinner from './Spinner.jsx';
import Constants from '../constants';
import {findDOMNode} from 'react-dom';
import React, {PropTypes} from 'react';
import {StoreMixin, UserMixin} from '../mixins';
import {PortUtils, RestApiUtils, PortProtocolsUtils} from '../utils';
import {ServiceStore} from '../stores';
import actionCreators from '../actions/actionCreators';
import ServiceCreateDialog from '../modals/ServiceCreateDialog';

const servicesMap = {};

function getStateFromStore() {
  const services = ServiceStore.getAll();

  services.forEach(service => {
    servicesMap[service.href] = service;
  });

  return {
    services,
    status: ServiceStore.getStatus(),
    allServices: ServiceStore.getServiceAll(true),
  };
}

export default React.createClass({
  propTypes: {
    tid: PropTypes.string,
    pversion: PropTypes.string,
    onChange: PropTypes.func,
    allowNull: PropTypes.bool,
    allowCreate: PropTypes.bool,
    allowRuleAttrs: PropTypes.bool,
    onNewService: PropTypes.func,
    placeholder: PropTypes.string,
    selected: PropTypes.object,
    disabled: PropTypes.bool,
    autoFocus: PropTypes.bool,
    disableSpinner: PropTypes.bool,
    isVirtualService: PropTypes.bool,
  },

  mixins: [StoreMixin(ServiceStore, getStateFromStore), UserMixin],

  getDefaultProps() {
    return {
      allowNull: false,
      allowRuleAttrs: false,
      pversion: 'draft',
      onChange: _.noop,
      allowCreatedService: true,
      placeholder: intl('Rulesets.Rules.SelectService'),
      disableSpinner: false,
    };
  },

  getInitialState() {
    return {
      items: {},
      dropdownValues: {},
      defaultSelected: intl('Services.PolicyServices'),
    };
  },

  async componentDidMount() {
    await RestApiUtils.services.getCollection();
    this.getFacetValues('services');
    this.getFacetPortPortocols(['portProtocol', 'portRange']);

    if (this.props.isVirtualService) {
      this.setVirtualServiceItems(this.props.selected);
    } else {
      this.setRuleItems(this.props.selected);
    }
  },

  getFacetPortPortocols(facets) {
    const dropdownValues = facets.reduce((data, curValue) => {
      if (!curValue) {
        return data;
      }

      data = {...data, [`${curValue}-`]: {matches: [], num_matches: 0}};

      return data;
    }, {});

    this.setState({dropdownValues});
  },

  getFacetValues(facet, query = '', maxResults = 25) {
    if (!facet) {
      return;
    }

    if (facet === 'portProtocol' || facet === 'portRange') {
      PortProtocolsUtils.setPortProtocol.call(this, facet, query);
    } else {
      RestApiUtils.services
        .autocomplete('draft', {
          query,
          max_results: maxResults,
        })
        .then(response => {
          const dropdownValues = {
            ...this.state.dropdownValues,
            [`${facet}-${query}`]: response.body,
          };

          this.setState({dropdownValues});
        });
    }
  },

  // Method logic taken from EndpointFilter.js
  setDropdownValues(key, query, values) {
    const dropdownValues = {...this.state.dropdownValues};

    if (!((key === 'portProtocol' || key === 'portRange') && dropdownValues.hasOwnProperty(`${key}-${query}`))) {
      dropdownValues[`${key}-${query}`] = values;
    }

    this.setState({dropdownValues});
  },

  setVirtualServiceItems(virtualService = {}) {
    const items = {
      [intl('Port.PortRange')]: [],
      [intl('Port.Port')]: [],
      [intl('Services.PolicyServices')]: [],
    };

    if (virtualService.service) {
      items[intl('Services.PolicyServices')].push(virtualService.service);
    }

    if (virtualService.service_ports) {
      virtualService.service_ports.forEach(servicePort => {
        if (servicePort.to_port) {
          const toPort = PortProtocolsUtils.setProperPortProtocolFacetItems(servicePort, PortProtocolsUtils.PORT_RANGE);

          items[intl('Port.PortRange')].push(toPort);
        } else {
          const portProto = PortProtocolsUtils.setProperPortProtocolFacetItems(
            servicePort,
            PortProtocolsUtils.PORT_PROTOCOL,
          );

          items[intl('Port.Port')].push(portProto);
        }
      });
    }

    this.setState({items});
  },

  setRuleItems(rule = {}) {
    const itemsSingleValues = {};
    const itemsServices = {
      [intl('Port.PortRange')]: [],
      [intl('Port.Port')]: [],
      [intl('Services.PolicyServices')]: [],
    };

    if (rule.sec_connect) {
      itemsSingleValues[intl('Common.SecureConnect')] = {
        sec_connect: true,
        name: intl('Common.SecureConnect'),
      };
    }

    if (rule.machine_auth) {
      itemsSingleValues[intl('Common.MachineAuthentication')] = {
        machine_auth: true,
        name: intl('Common.MachineAuthentication'),
      };
    }

    if (rule.stateless) {
      itemsSingleValues[intl('Common.Stateless')] = {
        stateless: true,
        name: intl('Common.Stateless'),
      };
    }

    if (rule.network_type === 'non_brn') {
      itemsSingleValues[intl('Rulesets.Rules.NonCorporateNetworks')] = {
        network_type: 'non_brn',
        name: intl('Rulesets.Rules.NonCorporateNetworks'),
      };
    }

    if (rule.network_type === 'all') {
      itemsSingleValues[intl('Rulesets.Rules.AllNetworks')] = {
        network_type: 'all',
        name: intl('Rulesets.Rules.AllNetworks'),
      };
    }

    if (rule.ingress_services) {
      rule.ingress_services.forEach(service => {
        // rule.ingress_services is either service or port/protocol and port/range
        if (service.href) {
          itemsServices[intl('Services.PolicyServices')].push(service);
        } else if (service.to_port) {
          const toPort = PortProtocolsUtils.setProperPortProtocolFacetItems(service, PortProtocolsUtils.PORT_RANGE);

          itemsServices[intl('Port.PortRange')].push(toPort);
        } else {
          const portProto = PortProtocolsUtils.setProperPortProtocolFacetItems(
            service,
            PortProtocolsUtils.PORT_PROTOCOL,
          );

          itemsServices[intl('Port.Port')].push(portProto);
        }
      });
    }

    this.setState({items: {...itemsSingleValues, ...itemsServices}});
  },

  addItem(item, value) {
    let items = {...this.state.items};
    const key = item === intl('Common.AllServices') ? intl('Services.PolicyServices') : item;
    const serviceKeys = [intl('Services.PolicyServices'), intl('Port.Port'), intl('Port.PortRange')];

    if (this.props.isVirtualService) {
      if (key === intl('Services.PolicyServices')) {
        delete items[intl('Services.PolicyServices')];
        delete items[intl('Port.Port')];
        delete items[intl('Port.PortRange')];
      } else {
        delete items[intl('Services.PolicyServices')];
      }
    }

    if (serviceKeys.includes(key)) {
      items[key] ||= [];

      if (value !== '') {
        items[key].push(value);
      }
    } else {
      items = {[key]: value, ..._.omitBy(items, _.isEmpty)};
    }

    if (key === intl('Rulesets.Rules.AllNetworks')) {
      delete items[intl('Rulesets.Rules.NonCorporateNetworks')];
    }

    if (key === intl('Rulesets.Rules.NonCorporateNetworks')) {
      delete items[intl('Rulesets.Rules.AllNetworks')];
    }

    this.setState({items}, () => this.props.onChange(items));
  },

  // Logic for adding multiple Policy Services, Port Range, Port Protocols
  handleCreate(value) {
    this.props.onNewService(
      this.state.items[intl('Services.PolicyServices')],
      value,
      this.props.selected,
      this.setRuleItems,
    );
  },

  createService() {
    let value;

    if (this.input) {
      const domNode = findDOMNode(this.input);

      value = domNode.querySelector('.ObjectSelector-input-elem').value;
    } else {
      value = '';
    }

    actionCreators.openDialog(<ServiceCreateDialog providedName={value} onCreate={this.handleCreate} />);
  },

  customListItem({text, props, item}) {
    let className = `${props.className || ''} OSServiceSelectResultItem`;
    let secondary;

    if (item && item.href) {
      const service = servicesMap[item.href] || {};
      const connections = service.service_ports || service.windows_services;

      if (connections) {
        secondary = connections.map(value => PortUtils.stringifyPortObjectReadonly(value)).join(', ');
      }
    }

    if (text === intl('Services.Create')) {
      className += ' OSServiceSelectResultItem--CreateService';
    }

    if (text === intl('Rulesets.Rules.NonCorporateNetworks') || text === intl('Rulesets.Rules.AllNetworks')) {
      className += ' OSServiceSelectResultItem--Networks';
    }

    return (
      <li key={text} {...props} className={className}>
        <span className="OSServiceSelectResultItem-Name">{text}</span>
        {secondary ? <span className="OSServiceSelectResultItem-PortProtocol">{secondary}</span> : null}
      </li>
    );
  },

  removeItem(item) {
    const items = _.omit(this.state.items, item);

    this.setState({items}, () => this.props.onChange(items));
  },

  removeMulti(filter, value) {
    const items = {...this.state.items};

    if (value) {
      items[filter] = items[filter].filter(item => item !== value);
    } else {
      items[filter] = items[filter].slice(0, -1);
    }

    this.setState({items}, () => this.props.onChange(items));
  },

  render() {
    const {addItem, getFacetValues, removeItem, customListItem, removeMulti} = this;
    const {allServices, items, dropdownValues} = this.state;

    const props = {
      items,
      dropdownValues,
      initialValues: [intl('Services.PolicyServices'), intl('Port.Port'), intl('Port.PortRange')],
      returnValue: item => (Array.isArray(item) ? item[0] : item).name,
      facetMap: {
        [intl('Services.PolicyServices')]: 'services',
        [intl('Port.Port')]: PortProtocolsUtils.PORT_PROTOCOL,
        [intl('Port.PortRange')]: PortProtocolsUtils.PORT_RANGE,
      },
      addItem,
      getFacetValues,
      removeItem,
      placeholder: this.props.placeholder,
      defaultSelected: intl('Services.PolicyServices'),
      singleValues: {[intl('Common.AllServices')]: allServices},
      customListItem,
      autoFocus: this.props.autoFocus,
      multiItems: [intl('Services.PolicyServices'), intl('Port.Port'), intl('Port.PortRange')],
      removeMulti,
      showAllSingleValues: true,
      showNameOnly: [intl('Port.Port'), intl('Port.PortRange')],
    };

    if (Object.values(props.items).every(_.isEmpty)) {
      props.forceNotEmptyPlaceholder = this.props.placeholder;
    }

    let tidString = 'comp-serviceselect';

    if (this.props.tid) {
      tidString += ` ${this.props.tid}`;
    }

    if (!this.isUserReadOnly() && this.props.allowCreate) {
      props.footerValues = [
        {
          noSelect: true,
          text: intl('Services.Create'),
          onClick: this.createService,
        },
      ];
      props.ref = node => (this.input = node);
    }

    if (this.props.allowNull) {
      Object.assign(props.singleValues, {
        [intl('Common.AllServices')]: allServices,
      });
    }

    if (this.props.allowRuleAttrs) {
      const {isDenyRule} = this.props;

      Object.assign(props.singleValues, {
        ...(__ANTMAN__ && isDenyRule
          ? {}
          : {
              [intl('Common.SecureConnect')]: {
                sec_connect: true,
                name: intl('Common.SecureConnect'),
              },
              [intl('Common.MachineAuthentication')]: {
                machine_auth: true,
                name: intl('Common.MachineAuthentication'),
              },
              [intl('Common.Stateless')]: {
                stateless: true,
                name: intl('Common.Stateless'),
              },
            }),
        [intl('Rulesets.Rules.NonCorporateNetworks')]: {
          network_type: 'non_brn',
          name: intl('Rulesets.Rules.NonCorporateNetworks'),
        },
        [intl('Rulesets.Rules.AllNetworks')]: {
          network_type: 'all',
          name: intl('Rulesets.Rules.AllNetworks'),
        },
      });
    }

    const classNames = cx('OSServiceSelect', {
      'OSServiceSelect--Loading': this.state.status === Constants.STATUS_BUSY,
    });

    return (
      <div data-tid={tidString}>
        {this.props.disableSpinner ? (
          <div className={classNames}>
            {this.state.status === Constants.STATUS_BUSY ? (
              <div className="OSServiceSelect-Spinner">
                <Spinner size="twenty" />
              </div>
            ) : null}
          </div>
        ) : null}
        <ObjectSelector {...props} />
      </div>
    );
  },
});
