/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import {createStore} from '../lib/store';
import dispatcher from '../actions/dispatcher';
import Constants from '../constants';
import WorkloadStore from './WorkloadStore';
import ContainerWorkloadStore from './ContainerWorkloadStore';
import VirtualServerStore from './VirtualServerStore';
import VirtualServiceStore from './VirtualServiceStore';
import DvsStore from './DvsStore';
import LoadBalancerStore from './LoadBalancerStore';
import TrafficStore from './TrafficStore';
import SessionStore from './SessionStore';
import LabelStore from './LabelStore';
import FilterStore from './FilterStore';
import IpListStore from './IpListStore';
import RulesetStore from './RulesetStore';
import ServiceStore from './ServiceStore';
import TrafficFilterStore from './TrafficFilterStore';
import GraphStore from './GraphStore';
import InfoPanelUtils from '../utils/InfoPanelUtils';
import ActionBarUtils from '../utils/ActionBarUtils';
import MapPageStore from './MapPageStore';
import MapSpinnerStore from './MapSpinnerStore';
import UserStore from './UserStore';

const UPDATE_EVENT = 'update';

let commandPanel = {};
let selections = [];
let hoverComponents = [];
const trafficSelections = {
  loc: {},
  app: {},
};
const actionPanel = {
  loc: {},
  app: {},
};
let userDefinedService;
let connectedSearchType;

function setServiceSelection(data) {
  trafficSelections[MapPageStore.getMapType()] = {
    service: data,
  };
}

function setConnectedSearchType(type) {
  if (type === 'consuming' || type === 'providing') {
    connectedSearchType = type;
  }

  if (!type || type === 'focused') {
    connectedSearchType = false;
  }
}

function setPbUbSelection(data) {
  trafficSelections[MapPageStore.getMapType()][data.pbUbType] = {...data};
}

function removeTrafficSelections() {
  trafficSelections[MapPageStore.getMapType()] = {};
}

function setAction(data) {
  actionPanel[MapPageStore.getMapType()] = data;
}

function removeAction() {
  actionPanel[MapPageStore.getMapType()] = {};
}

function setUserDefinedService(data) {
  userDefinedService = data;
}

function clearUserDefinedService() {
  userDefinedService = null;
}

function storeUpdated() {
  if (!GraphStore.getSelections().length) {
    removeTrafficSelections();
    removeAction();
  }
}

function calculateCommandPanel() {
  // Selection is the workload/traffic/app container/internet that is selected.
  // TrafficSelection is IF traffic is selected, it would contain the selected services, pb, and ub.
  selections = GraphStore.getSelections();

  let node;
  let cluster;
  let link;
  let location;
  const mapLevel = MapPageStore.getMapLevel();
  const mapRoute = MapPageStore.getMapRoute();
  const mapType = MapPageStore.getMapType();

  commandPanel = {};

  if (!mapLevel || (mapLevel === 'full' && !selections.length)) {
    return;
  }

  commandPanel.type = selections.length && selections[0].type;

  const type = commandPanel.type;

  // ignore multi-select if selections are all internet link
  if (selections.length > 1 && selections[0].identifier !== selections[1].identifier) {
    commandPanel.type = 'multiSelect';

    const info = [];

    selections.forEach(s => {
      const link = GraphStore.getLink(s.href, s.identifier);

      if (link && !link.isHidden) {
        info.push({
          info: InfoPanelUtils.calculateTrafficPanel(link, trafficSelections[mapType]),
          type: 'traffic',
        });
      }
    });

    if (info.length !== 0) {
      commandPanel.info = info;
    }
  } else if (type === 'workload') {
    // Taking first selection's type (assumes all the types are the same in the selection array)
    node = GraphStore.getNode(selections[0].href);

    if (node) {
      commandPanel.info = InfoPanelUtils.calculateWorkloadPanel(node);
      commandPanel.actions = ActionBarUtils.calculateWorkloadActions(node);
    }
  } else if (type === 'virtualService') {
    node = GraphStore.getNode(selections[0].href);

    if (node) {
      if (node.subType === 'virtual_server') {
        commandPanel.subType = 'virtualServer';
        commandPanel.info = InfoPanelUtils.calculateVirtualServerPanel(node);
      } else {
        commandPanel.info = InfoPanelUtils.calculateVirtualServicePanel(node);
      }

      commandPanel.actions = ActionBarUtils.calculateWorkloadActions(node);
    }
  } else if (type === 'role') {
    node = GraphStore.getNode(selections[0].href);

    if (node) {
      commandPanel.info = InfoPanelUtils.calculateRolePanel(node);
      commandPanel.actions = ActionBarUtils.calculateRoleActions(node);
    }
  } else if (type === 'traffic') {
    link = GraphStore.getLink(selections[0].href, selections[0].identifier);

    if (link && !link.isHidden) {
      commandPanel.info = InfoPanelUtils.calculateTrafficPanel(link, trafficSelections[mapType]);
      commandPanel.actions = ActionBarUtils.calculateTrafficActions(
        link,
        trafficSelections[mapType],
        commandPanel.info,
        mapType,
      );
    }
  } else if (type === 'internet' || type === 'ipList' || type === 'fqdn') {
    cluster = GraphStore.getCluster(selections[0].href || selections[0].clusterId);
    commandPanel.info = InfoPanelUtils.calculateInternetPanel(selections);
  } else if (type === 'group') {
    // The selected app group info
    cluster = GraphStore.getCluster(selections[0].href || selections[0].clusterId);

    if (cluster) {
      commandPanel.info = InfoPanelUtils.calculateGroupPanel(cluster, selections[0]);

      const appGroupForPolicyGenerator = TrafficStore.getNodeForPolicyGenerator(cluster.appGroupParent);

      commandPanel.actions = ActionBarUtils.calculateGroupActions(
        cluster,
        appGroupForPolicyGenerator,
        commandPanel.info,
      );

      if (selections[0].href !== mapRoute.id) {
        commandPanel.connectedGroup = true;
      }

      commandPanel.info = Object.assign(commandPanel.info || {}, InfoPanelUtils.calculateLocationSearch());
    }
  } else if (type === 'appGroup') {
    cluster = GraphStore.getCluster(selections[0].href);

    if (cluster) {
      if (cluster.connectionType === 'focused') {
        commandPanel.info = InfoPanelUtils.calculateAppGroupPanel(cluster, selections[0]);
      } else {
        const links = GraphStore.getLinks();

        if (links && links.length) {
          commandPanel.type = 'appGroupTraffic';
          commandPanel.info = InfoPanelUtils.calculateAppGroupTrafficPanel(links, selections[0]);
        } else {
          commandPanel.type = 'appGroupSearch';
        }
      }

      // Always get focused app group info
      const focusedAppGroup = cluster.focused ? cluster : GraphStore.getCluster(mapRoute.previd || mapRoute.id);

      if (focusedAppGroup) {
        const connectedAppGroup = mapRoute.previd && GraphStore.getCluster(mapRoute.id);
        const appGroupForPolicyGenerator = TrafficStore.getNodeForPolicyGenerator(focusedAppGroup.href);
        let explorerFilters;

        if (SessionStore.isFlowAnalyticsEnabled() && focusedAppGroup) {
          explorerFilters = {time: 'Anytime'};

          // Intra-scope traffic - Both the consumers and providers are in the focused app group
          if (mapRoute.type === 'focused') {
            explorerFilters.providerInclude = [
              {key: 'appgroups', value: focusedAppGroup.name, href: focusedAppGroup.href},
            ];
          } else if (mapRoute.type === 'consuming' && connectedAppGroup) {
            // For consuming route: consumers are the connected app group, providers are the focused app group
            explorerFilters.consumerInclude = [
              {key: 'appgroups', value: connectedAppGroup.name, href: connectedAppGroup.href},
            ];
            explorerFilters.providerInclude = [
              {key: 'appgroups', value: focusedAppGroup.name, href: focusedAppGroup.href},
            ];
          } else if (mapRoute.type === 'providing' && connectedAppGroup) {
            // For providing route: consumers are the focused app gropu, providers are the connected app group
            explorerFilters.consumerInclude = [
              {key: 'appgroups', value: focusedAppGroup.name, href: focusedAppGroup.href},
            ];
            explorerFilters.providerInclude = [
              {key: 'appgroups', value: connectedAppGroup.name, href: connectedAppGroup.href},
            ];
          }
        }

        const illuminationClassicSettings = UserStore.getDefaultIllumination();
        const illuminationMapIsEnabled = SessionStore.isIlluminationMapEnabled();
        const illuminationClassicEnabled =
          illuminationMapIsEnabled &&
          (illuminationClassicSettings
            ? illuminationClassicSettings === 'enabled'
            : SessionStore.isIlluminationClassicEnabled());
        // Only link back to the location map if they are both are using 3 label groups and the illumination map is enabled
        const locMap =
          !SessionStore.isNewUI() && illuminationClassicEnabled && TrafficStore.getAppGroupsType().length === 3;

        commandPanel.actions = ActionBarUtils.calculateAppGroupActions(
          focusedAppGroup,
          cluster.connectionType !== 'focused' && connectedAppGroup,
          appGroupForPolicyGenerator,
          mapRoute,
          locMap,
          explorerFilters,
          commandPanel.type,
        );

        if (selections[0].href !== mapRoute.id) {
          commandPanel.connectedGroup = true;
        }
      }
    } else {
      // If app group is not available in the graph store do not show the command panel
      commandPanel = {};

      return;
    }

    commandPanel.info = Object.assign(
      commandPanel.info || {},
      InfoPanelUtils.calculateSearch(mapRoute, connectedSearchType),
    );
  } else if (type === 'location') {
    location = GraphStore.getLocation(selections[0].href);

    if (location) {
      commandPanel.info = InfoPanelUtils.calculateLocationPanel(location);
      commandPanel.actions = ActionBarUtils.calculateLocationActions(location);
    }
  }

  if (!commandPanel.info || (mapType === 'app' && TrafficStore.isAppGroupDisabled())) {
    commandPanel = {};

    return;
  }

  let form;
  const curAction = actionPanel[mapType || 'loc'];

  // if the selected element exists, and then there's a chosen action, evaluate.
  if (curAction.type === 'assignRoleAction') {
    form = ActionBarUtils.assignRoleAction(node, curAction);
    form.fullMap = mapLevel === 'full';
  } else if (curAction.type === 'setPolicyStateAction') {
    cluster = GraphStore.getCluster(selections[0].href || selections[0].clusterId);
    form = ActionBarUtils.setPolicyStateAction(commandPanel.info, cluster);
  } else if (curAction.type === 'addRuleAction') {
    const groupTypes = TrafficStore.getAppGroupsType();

    if (curAction.entityType === 'traffic') {
      form = ActionBarUtils.addRuleAction(link, trafficSelections[mapType], groupTypes);
    } else if (curAction.entityType === 'location') {
      form = ActionBarUtils.addRingFencingRuleAction(location, commandPanel.actions, curAction, groupTypes);
    } else if (curAction.entityType === 'group' || curAction.entityType === 'appGroup') {
      form = ActionBarUtils.addRingFencingRuleAction(cluster, groupTypes);
    }
  } else if (
    curAction.type === 'viewRuleAction' ||
    curAction.type === 'viewRulesAction' ||
    curAction.type === 'viewEnforcementBoundaryAction' ||
    curAction.type === 'viewEnforcementBoundariesAction'
  ) {
    if (curAction.entityType === 'traffic') {
      form = ActionBarUtils.viewRuleAction(
        link,
        trafficSelections[mapType],
        ActionBarUtils.getRuleActionType(curAction.type),
      );
    } else if (curAction.entityType === 'location') {
      form = ActionBarUtils.viewRingFencingRuleAction(
        location,
        commandPanel.actions,
        ActionBarUtils.getRingFencingRuleActionType(curAction.type),
      );
    } else if (curAction.entityType === 'group' || curAction.entityType === 'appGroup') {
      form = ActionBarUtils.viewRingFencingRuleAction(
        cluster,
        commandPanel.actions,
        ActionBarUtils.getRingFencingRuleActionType(curAction.type),
      );
    }
  } else if (curAction.type === 'findGroupAction') {
    form = ActionBarUtils.findGroupAction(commandPanel.info);
  } else if (curAction.type === 'findConnectedGroupAction') {
    form = ActionBarUtils.findConnectedGroupAction(commandPanel.info);
  } else if (
    curAction.type === 'detailAction' ||
    curAction.type === 'pbUbAction' ||
    curAction.type === 'internetAction'
  ) {
    form = ActionBarUtils.detailAction(commandPanel.info, curAction.type);
    commandPanel.formData = curAction.formData;
  } else if (curAction.type === 'findConsumingGroupAction') {
    form = ActionBarUtils.findConnectedAppGroupAction(mapRoute, 'consuming');
  } else if (curAction.type === 'findProvidingGroupAction') {
    form = ActionBarUtils.findConnectedAppGroupAction(mapRoute, 'providing');
  } else if (curAction.type === 'vulnerability') {
    form = curAction;
    commandPanel.formData = curAction.formData;
  }

  commandPanel.form = form;
}

export default createStore({
  dispatchHandler(action) {
    switch (action.type) {
      case Constants.SET_CONNECTED_SEARCH:
        connectedSearchType = action.data;
        break;

      case Constants.LOCATION_SUMMARY_GET_SUCCESS:
      case Constants.APP_GROUP_SUMMARY_GET_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken]);
        break;

      // Workload
      case Constants.WORKLOADS_GET_COLLECTION_SUCCESS:
      case Constants.WORKLOADS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([WorkloadStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Container Workload
      case Constants.CONTAINER_WORKLOADS_GET_COLLECTION_SUCCESS:
      case Constants.CONTAINER_WORKLOADS_GET_INSTANCE_SUCCESS:
      case Constants.CONTAINER_WORKLOADS_GET_RUNNING_CONTAINERS_SUCCESS:
        dispatcher.waitFor([ContainerWorkloadStore.dispatchToken]);
        break;

      // Virtual Server
      case Constants.VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
      case Constants.VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([VirtualServerStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_COLLECTION_SUCCESS:
      case Constants.DISCOVERED_VIRTUAL_SERVERS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([DvsStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      case Constants.SLBS_GET_COLLECTION_SUCCESS:
      case Constants.SLBS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([LoadBalancerStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      //Virtual Service
      case Constants.VIRTUAL_SERVICES_GET_COLLECTION_SUCCESS:
      case Constants.VIRTUAL_SERVICES_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([VirtualServiceStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Label
      case Constants.LABELS_GET_COLLECTION_SUCCESS:
      case Constants.LABELS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([LabelStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Traffic
      case Constants.NETWORK_TRAFFIC_GET_SUCCESS:
      case Constants.NETWORK_TRAFFIC_ITERATE_COMPLETE:
      case Constants.NETWORK_TRAFFIC_REBUILD_COMPLETE:
        dispatcher.waitFor([MapSpinnerStore.dispatchToken, TrafficStore.dispatchToken, GraphStore.dispatchToken]);

        if (MapSpinnerStore.getTrafficSpinner()) {
          return;
        }

        break;

      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_NODES:
      case Constants.NETWORK_TRAFFIC_UPDATE_FOR_SCOPES:
      case Constants.SEC_POLICY_RULE_COVERAGE_CREATE_SUCCESS:
      case Constants.AGGREGATED_DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
      case Constants.DETECTED_VULNERABILITIES_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      case Constants.WORKLOADS_VERIFY_CAPS_SUCCESS:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Iplists
      case Constants.IP_LISTS_GET_COLLECTION_SUCCESS:
        dispatcher.waitFor([IpListStore.dispatchToken]);
        break;

      // Rulesets
      case Constants.RULE_SETS_GET_COLLECTION_SUCCESS:
      case Constants.RULE_SETS_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([RulesetStore.dispatchToken]);
        break;

      // Services
      case Constants.SERVICES_GET_COLLECTION_SUCCESS:
      case Constants.SERVICES_GET_INSTANCE_SUCCESS:
        dispatcher.waitFor([ServiceStore.dispatchToken]);
        break;

      // Map route
      case Constants.UPDATE_MAP_ROUTE:
        setConnectedSearchType(action.data.type);
        dispatcher.waitFor([MapPageStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Filters
      case Constants.ADD_FILTERS:
      case Constants.REMOVE_FILTER:
        dispatcher.waitFor([FilterStore.dispatchToken, GraphStore.dispatchToken]);
        break;

      // Selections
      case Constants.SELECT_COMPONENT:
      case Constants.UNSELECT_COMPONENT:
      case Constants.UPDATE_COMPONENT_SELECTION:
        dispatcher.waitFor([GraphStore.dispatchToken]);

        if (!action.data || !action.data.superAppGroup) {
          removeAction();
        }

        removeTrafficSelections();
        break;

      case Constants.UPDATE_MULTIPLE_SELECTION:
        dispatcher.waitFor([GraphStore.dispatchToken]);

        const curActionPanel = actionPanel[MapPageStore.getMapType()];

        if (curActionPanel.type !== 'findProvidingGroupAction' && curActionPanel.type !== 'findConsumingGroupAction') {
          removeAction();
        }

        removeTrafficSelections();
        break;

      case Constants.SELECT_SERVICE:
        setServiceSelection(action.data);
        break;

      case Constants.SELECT_PBUB:
        setPbUbSelection(action.data);
        break;

      case Constants.SELECT_VULNERABILITY:
        setAction(action.data);
        break;

      // hover on command panel
      case Constants.HOVER_COMPONENT:
        hoverComponents = action.data;
        this.emit(UPDATE_EVENT);
        break;

      case Constants.UNHOVER_COMPONENT:
        hoverComponents = null;
        this.emit(UPDATE_EVENT);
        break;

      case Constants.CLEAR_TRAFFIC_SELECTION:
        removeTrafficSelections();
        break;

      case Constants.CLICK_ACTION_ITEM:
        setAction(action.data);
        break;

      case Constants.CLOSE_ACTION_ITEM:
        removeAction();
        break;

      case Constants.RESET_DEFAULT_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken, TrafficFilterStore.dispatchToken, GraphStore.dispatchToken]);
        removeTrafficSelections();
        break;

      case Constants.SELECT_TRAFFIC_FILTERS:
        dispatcher.waitFor([TrafficFilterStore.dispatchToken, GraphStore.dispatchToken]);
        removeTrafficSelections();
        break;

      case Constants.SELECT_TRAFFIC_CONNECTION_FILTERS:
      case Constants.SELECT_TRAFFIC_TIME_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        removeTrafficSelections();
        removeAction();
        break;

      case Constants.SELECT_SERVICE_FILTERS:
        dispatcher.waitFor([TrafficStore.dispatchToken, GraphStore.dispatchToken]);
        removeAction();
        break;

      case Constants.EXPAND_ROLE:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        break;

      case Constants.COLLAPSE_ROLE:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        break;

      case Constants.SET_USER_DEFINED_SERVICE:
        setUserDefinedService(action.data);
        break;

      case Constants.CLEAR_USER_DEFINED_SERVICE:
        clearUserDefinedService(action.data);
        break;

      case Constants.SEC_POLICY_DEPENDENCIES_SUCCESS:
      case Constants.SEC_POLICIES_DEPENDENCIES_SUCCESS:
        break;

      case Constants.SET_MAP_POLICY_VERSION:
      case Constants.SET_APP_MAP_VERSION:
        dispatcher.waitFor([GraphStore.dispatchToken]);
        break;

      case Constants.KVPAIRS_GET_INSTANCE_SUCCESS:
        break;

      case Constants.EXPAND_COLLAPSE_CONNECTED_GROUP:
        break;

      default:
        return true;
    }

    calculateCommandPanel();
    this.emitChange();

    return true;
  },

  addUpdateListener(callback) {
    this.on(UPDATE_EVENT, callback);
  },

  removeUpdateListener(callback) {
    this.removeListener(UPDATE_EVENT, callback);
  },

  getCommandPanel() {
    return commandPanel;
  },

  getTrafficSelections() {
    return trafficSelections[MapPageStore.getMapType()];
  },

  getAction() {
    return actionPanel;
  },

  getUserDefinedService() {
    return userDefinedService;
  },

  getConnectedSearchType() {
    return connectedSearchType;
  },

  getHoverComponents() {
    return hoverComponents;
  },

  storeUpdated,
});
