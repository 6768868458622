/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import intl from 'intl';
import Alert from './Alert.jsx';
import * as webStorageUtils from 'utils/webStorage';

const storageCloseKey = `${browser.code}WarningClosed`;

export function resetBrowserWarning() {
  webStorageUtils.removeItem(storageCloseKey);
}

export default React.createClass({
  getInitialState() {
    // Show alert on 'gray' browsers, that are not bad, not supported and this alert was not closed before
    return {
      show: !browser.isSupported && !webStorageUtils.getItem(storageCloseKey),
    };
  },

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.show !== this.state.show;
  },

  handleClose() {
    webStorageUtils.setItem(storageCloseKey, true);

    this.setState({show: false});
  },

  render() {
    if (!this.state.show) {
      return null;
    }

    return (
      <Alert
        detached
        type="warning"
        onClose={this.handleClose}
        title={intl('Common.Warning')}
        tid="gray-browser-warning"
      >
        <a href="static/browsersupport.html" target="_blank">
          {intl('Common.BrowserGrayWarning')}
        </a>
      </Alert>
    );
  },
});
