/**
 * Copyright 2015 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import {getSessionUri, getInstanceUri} from '../lib/api';
import {OrgStore, LabelStore} from '../stores';
import {RestApiUtils, GridDataUtils} from '.';

// This is a list of all possible roles that a user can have.
const ROLES = [
  'owner',
  'admin',
  'read_only',
  'ruleset_manager',
  'limited_ruleset_manager',
  'ruleset_provisioner',
  'global_object_provisioner',
];

// This is a list of the possible global roles. global roles are roles which must have all all all scope.
const GLOBAL_ROLES = ['owner', 'admin', 'read_only', 'global_object_provisioner'];

// This is a list of all scoped roles which allow all all all for their scope.
const SCOPED_ALL_ROLES = ['ruleset_manager', 'ruleset_provisioner'];

const FORMATTED_ROLE_MAP = {
  read_only: intl('Role.GlobalReadOnly'),
  admin: intl('Role.GlobalAdmin'),
  owner: intl('Role.GlobalOrgOwner'),
  ruleset_manager: intl('Role.RulesetManager'),
  limited_ruleset_manager: intl('Role.LimitedRulesetManager'),
  ruleset_provisioner: intl('Role.RulesetProvisioner'),
  global_object_provisioner: intl('Role.GlobalPolicyObjectProvisioner'),
};

const ROLE_SORT_VALUES = ROLES.reduce((roleSortValues, role) => {
  if (!(role in roleSortValues)) {
    roleSortValues[role] = Object.values(FORMATTED_ROLE_MAP).sort().indexOf(FORMATTED_ROLE_MAP[role]);
  }

  return roleSortValues;
}, {});

/* The methods below are added back as they were removed from 17.1 and are needed
 for some modals to function. */

const getRole = role => {
  switch (role) {
    case 1:
      return intl('Common.ReadOnly');
    case 2:
      return intl('Role.Admin');
    case 3:
      return intl('Role.Owner');
    default:
      return;
  }
};

const getRoles = () => [
  {value: 1, label: intl('Common.ReadOnly')},
  {value: 2, label: intl('Role.Admin')},
  {value: 3, label: intl('Role.Owner')},
];

const getRoleFromHref = roleHref => _.last(roleHref.split('/'));

const getFormattedRoleFromHref = href => {
  const role = href && getRoleFromHref(href);

  return FORMATTED_ROLE_MAP[role];
};

const getRoleHref = roleName => getSessionUri(getInstanceUri('roles'), {role_name: roleName});

const getLocalUsers = users => users.find(user => user.type === 'local');

const getExtUsers = users => users.find(user => user.type === 'external');

// Returns whether a role is allowed to have all all all scope
const isAllScopeAllowed = role => GLOBAL_ROLES.concat(SCOPED_ALL_ROLES).includes(role);

const isGlobalRoleHref = href => {
  const role = href && getRoleFromHref(href);

  return GLOBAL_ROLES.includes(role);
};

const getPermissionLabels = () => {
  OrgStore.getPermissions().forEach(perm => {
    perm.scope.forEach(label => {
      const specifiedLabel = LabelStore.getSpecified(label.href);

      if (!specifiedLabel) {
        RestApiUtils.labels.getInstance(_.last(label.href.split('/')), true);
      }
    });
  });
};

//This function is used to format scopeRoles on the RBACScopesRolesList page.
const formatScopeRoles = scopeRoles => {
  const expandedScopeRoles = [];

  //Add an id and type for each scopeRole with a specific scope
  scopeRoles.forEach(scopeRole => {
    const newScopeRole = _.cloneDeep(scopeRole);

    newScopeRole.type = 'scoped';

    if (!scopeRole.scope.length) {
      scopeRole.roles = scopeRole.roles.filter(role => !isGlobalRoleHref(role.href));
    }

    if (scopeRole.roles.length > 0) {
      //id is all the scope label hrefs and all the roleHrefs joined together
      const labelId = scopeRole.scope.length ? scopeRole.scope.map(label => label.href).join('-') : 'all';
      const roleHrefs = scopeRole.roles.map(role => role.href);

      newScopeRole.id = `${labelId}|${roleHrefs.join('-')}`;

      expandedScopeRoles.push(newScopeRole);
    }
  });

  return expandedScopeRoles;
};

const groupPermissionsByPrincipal = permissions =>
  permissions.reduce((groupedPermissions, permission) => {
    //Check if there is already a permission in groupedPermission with the current Principal
    const existingPermission = groupedPermissions.find(
      groupedPerm => groupedPerm.auth_security_principal.href === permission.auth_security_principal.href,
    );

    if (existingPermission) {
      //If permission already exists 1) add new role to list of roles and 2) concat role to the id
      existingPermission.roles.push({href: permission.role.href});
      existingPermission.id = `${existingPermission.id}-${permission.role.href}`;
    } else {
      //If permission doesn't exist yet 1) convert role to list of roles 2) create an id for the permission
      const newPermission = _.cloneDeep(permission);

      newPermission.roles = [{href: permission.role.href}];
      newPermission.id = `${permission.auth_security_principal.href}|${permission.role.href}`;
      newPermission.auth_security_principal =
        permission.auth_security_principal &&
        OrgStore.getAuthSecPrincipalFromHref(permission.auth_security_principal.href);
      delete newPermission.role;
      groupedPermissions.push(newPermission);
    }

    return groupedPermissions;
  }, []);

const groupPermissionsByScope = permissions =>
  permissions.reduce((groupedPermissions, permission) => {
    //Check if there is already a permission in groupedPermission with the current Scope
    const scopeHrefs = permission.scope.map(label => label.href);
    const currPermIsGlobal = isGlobalRoleHref(permission.role.href);

    const existingPermission = groupedPermissions.find(groupedPerm => {
      const groupedPermIsGlobal = isGlobalRoleHref(groupedPerm.roles[0].href);

      return (
        currPermIsGlobal === groupedPermIsGlobal &&
        groupedPerm.scope.length === permission.scope.length &&
        groupedPerm.scope.every(label => scopeHrefs.includes(label.href))
      );
    });

    const currPrincipalHref = permission.auth_security_principal && permission.auth_security_principal.href;
    const defaultReadOnlyPrincipal = OrgStore.getDefaultReadOnlyAuthSecPrincipal();
    const defaultReadOnlyPrincipalHref = defaultReadOnlyPrincipal && defaultReadOnlyPrincipal.href;
    const currRoleIsDefaultReadOnly = currPrincipalHref === defaultReadOnlyPrincipalHref;

    if (existingPermission) {
      //If permission already exists 1) add new Role to list of roles and 2) concat role to the id
      existingPermission.roles.push(
        currRoleIsDefaultReadOnly ? {href: permission.role.href, defaultReadOnly: true} : {href: permission.role.href},
      );
      existingPermission.id = `${existingPermission.id}-${permission.role.href}`;
    } else {
      //If permission doesn't exist yet 1) convert role to list of roles 2) create an id for the permission
      const newPermission = _.cloneDeep(permission);
      const newPermIsGlobal = isGlobalRoleHref(permission.role.href);

      newPermission.type = newPermIsGlobal ? 'global' : 'scoped';

      newPermission.roles = currRoleIsDefaultReadOnly
        ? [{href: permission.role.href, defaultReadOnly: true}]
        : [{href: permission.role.href}];

      if (permission.scope.length) {
        const scopeHrefs = permission.scope.map(label => label.href).sort();

        newPermission.id = `${scopeHrefs.join('-')}|${permission.role.href}`;
      } else {
        newPermission.id = `all|${permission.role.href}`;
      }

      delete newPermission.role;
      groupedPermissions.push(newPermission);
    }

    return groupedPermissions;
  }, []);

//All the global roles are displayed on RBACScopesRolesList even if no permissions exists for them yet.
//This part of the function adds in those always present global scope roles
const getGlobalListPageRows = () =>
  GLOBAL_ROLES.map(globalRole => {
    const roleHref = getRoleHref(globalRole);

    return {
      id: `all|${roleHref}`,
      scope: [],
      roles: [{href: roleHref}],
      type: 'global',
    };
  });

const getUserStatus = user => {
  if (!user) {
    return null;
  }

  const invited = user.local_profile && user.local_profile.pending_invitation;
  const locked = user.locked;

  if (user.presence_status === 'online') {
    return intl('Common.Online');
  }

  if (locked) {
    return intl('Common.Locked');
  }

  if (invited) {
    return intl('Common.Invited');
  }

  return intl('Workloads.Status.Offline');
};

const formatUserActivityUsers = users => {
  const newUsers = users.slice();

  newUsers.forEach(user => {
    user.status = getUserStatus(user);
    user.duration = user.last_login_on;
  });

  return newUsers;
};

const formatPermissionsByGlobalRole = permissions => {
  if (!OrgStore.areAuthSecPrincipalsLoaded()) {
    return [];
  }

  //Filter to remove any non global roles (scope array is not empty)
  //Then map so that each permission has an expanded authSecPrincipal instead of just an authSecPrincipal href
  const res = permissions
    .filter(permission => !permission.scope.length)
    .map(permission => {
      const expandedAuthSecPrincipal =
        permission.auth_security_principal &&
        OrgStore.getAuthSecPrincipalFromHref(permission.auth_security_principal.href);

      permission.auth_security_principal = expandedAuthSecPrincipal;

      return permission;
    });

  return res;
};

const getScopeId = scope => {
  if (!scope.length) {
    return 'all-all-all';
  }

  const scopeIds = [];
  const types = ['app', 'env', 'loc'];
  const labels = scope.map(label => LabelStore.getSpecified(label.href));

  types.forEach(type => {
    const currLabel = labels.find(label => label.key === type);

    if (currLabel) {
      scopeIds.push(GridDataUtils.getIdFromHref(currLabel.href));
    } else {
      scopeIds.push('all');
    }
  });

  return scopeIds.join('-');
};

const getScopeLabelText = (label, key) => {
  if (label) {
    return label.value;
  }

  if (key === 'app') {
    return intl('Common.AllApplications');
  }

  if (key === 'env') {
    return intl('Common.AllEnvironments');
  }

  if (key === 'loc') {
    return intl('Common.AllLocations');
  }
};

export default {
  ROLES,
  GLOBAL_ROLES,
  SCOPED_ALL_ROLES,
  FORMATTED_ROLE_MAP,
  ROLE_SORT_VALUES,
  getFormattedRoleFromHref,
  getGlobalListPageRows,
  getRole,
  getRoles,
  getRoleFromHref,
  getLocalUsers,
  getExtUsers,
  getRoleHref,
  getPermissionLabels,
  formatScopeRoles,
  groupPermissionsByPrincipal,
  groupPermissionsByScope,
  formatUserActivityUsers,
  formatPermissionsByGlobalRole,
  getUserStatus,
  getScopeId,
  isAllScopeAllowed,
  isGlobalRoleHref,
  getScopeLabelText,
};
